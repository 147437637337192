import React, { useState } from 'react';
import axios from 'axios'
import AuthService from '../UserAdmin/services/AuthService'
import FormField from '../atoms/FormField'
import Button from '../atoms/Button'
import throbber from '../../images/throbber.gif'
import ComponentDisplayMessage from '../ComponentDisplayMessage'
const Auth = new AuthService();
const api = Auth.api

const PolicyLog = props => {
    const [policyNumber, setPolicyNumber] = useState("");
    const [loading, setLoading] = useState(false)
    const [error, isError] = useState(false)
    const [message, setMessage] = useState("")
    let errorMessage, buttonText, buttonClass
    if (policyNumber.length === 7){
        buttonText = "Get Policy Activity"
        buttonClass = "button"
    }
    if (policyNumber.length === 0){
        buttonText = "Retrieve All Policy Activity"
        buttonClass = "button"
    }
    if (policyNumber.length === 1 || policyNumber.length === 2 || policyNumber.length === 3 || policyNumber.length === 4 || policyNumber.length === 5 || policyNumber.length === 6 ){
        buttonClass = "button Disable"
    }
    const searchPolicy = () => {
        setLoading(true)
        axios.get(`${api}/activityReport?policy=`+policyNumber, {
            headers: { Authorization: Auth.getTokenForFrontEnd()}
        })
        .then(res => {
            props.setLogData(res.data)
            isError(false)
            setLoading(false)
        },) 
        .catch(error => {
            errorMessage = error.response.data
            setLoading(false)
            isError(true)
            setMessage(errorMessage)
        })
    }
    
    return (
        <>
        {props.currentPage === 'PolicyAdmin' ?
            <>
            <div className="panel">
                <form>
                    <h1 className="header">Policy Activity</h1>
                    <FormField
                        type="text"
                        id="policyNumber"
                        className="policyNumber-field"
                        value={policyNumber}
                        onChange={e => setPolicyNumber(e.target.value)}
                        label="Policy Number"
                        maxLength="7"
                    /> 
                </form>
                <Button className={buttonClass} onClick={searchPolicy}>{buttonText}</Button>
                {loading ?
                <img src={throbber} alt="throbberspi"/>
                :null}
                <ComponentDisplayMessage
                    error={error}
                    errorMessage={message}
                />
               
            </div>

            </>
        :null}
        </>
    )
}


export default PolicyLog