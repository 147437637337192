import decode from 'jwt-decode';
export default class AuthService {
    // Initializing important variables
    constructor() {
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.login = this.login.bind(this)
        this.loginForCustomer = this.loginForCustomer.bind(this)
        this.getProfile = this.getProfile.bind(this)
        this.generatePIN = this.generatePIN.bind(this)
        this.attachPinToPolicyNumber = this.attachPinToPolicyNumber.bind(this)
        this.createUser = this.createUser.bind(this)
        this.getCustomerType = this.getCustomerType.bind(this)
        this.logout = this.logout.bind(this)
        this.api = ''
        if (process.env.NODE_ENV === 'development'){
            this.api = process.env.REACT_APP_SECRET_KEY
        }
        if (process.env.NODE_ENV === 'production'){
            this.api = process.env.REACT_APP_SECRET_KEY
        }
    }

    getCustomerType(userId){
        return this.fetch(`${this.api}/user/`+userId, {
            method: 'GET',
        }).then(res => {
            let customerType
            let altProfile1 = res.altProfile1
            let altProfile2 = res.altProfile2
            if (altProfile1){
                customerType = 'ROLE_ICHIBAN'
            }
            if (altProfile2){
                customerType = 'ROLE_TRAILER'
            }
            return customerType
        })
    }

    getListOfPolicies(policyNumber){
        return this.fetch(`${this.api}/user/`+policyNumber, {
            method: 'GET',
        }).then(res => {
            return res
        })
    }

    login(email, password) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.api}/admin/signin`, {
            method: 'POST',
            body: JSON.stringify({
                email,
                password
            })
        }).then(res => {
            this.setToken(res.jwttoken, res.role)
        })
    }

    loginForCustomer(policyNumber, pinNumber) {
        // Get a token from api server using the fetch api
        policyNumber.toUpperCase()
        return this.fetch(`${this.api}/customer/signin`, {
            method: 'POST',
            body: JSON.stringify({
                policyNumber,
                pinNumber
            })
        }).then(res => {
            this.setToken(res.jwttoken, res.role, res.altProfile1, res.altProfile2)
            if(res.role === 'ROLE_ADMIN_CUSTOMER'){
                console.log(res.policies)
                localStorage.setItem('policies', JSON.stringify(res.policies))
                localStorage.setItem('name', res.name)
            }
        })
    }

    // generate a unique pin for the customer
    generatePIN() {
        return this.fetch(`${this.api}/pinnumber`, {
            method: 'GET'
        }).then(res => {
            return res
        })
    }

    attachPinToPolicyNumber(policyNumber, pinNumber) {
        policyNumber.toUpperCase();
        return this.fetch(`${this.api}/customer/signup`, {
            method: 'POST',
            body: JSON.stringify({
                policyNumber,
                pinNumber
            })
        }).then(res => {
            
        })
    }

    createUser(email, password, underwriter) {
        return this.fetch(`${this.api}/admin/signup`, {
            method: 'POST',
            body: JSON.stringify({
                email,
                password,
                underwriter
            })
        }).then(res => {
            
        })
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken, value, altProfile1, altProfile2) {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken)
        localStorage.setItem('role', value)
        localStorage.setItem('ichiban', altProfile1)
        localStorage.setItem('trailer', altProfile2)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token')
    }

    getTokenForFrontEnd() {
        return 'Bearer '+this.getToken()
    }

    dateConvertor = (value, method) => {
        let formattedDate
        // methods = add, amend, remove
        // dates for adding vehicle must be YYYY-MM-DD
        // date for amends must be dd/mm/yyyy
        if (method === 'add'){
            let year = value.substring(6,11)
            let month = value.substring(3,5)
            let day = value.substring(0,2)
            formattedDate = year + '-' + month + '-' + day
        }
        if (method === 'amend'){
            let year = value.substring(0,4)
            let month = value.substring(5,7)
            let day = value.substring(8,10)
            formattedDate = day + '/' + month + '/' + year
        }
        if (method === 'remove'){
            var day = value.substring(0, 2);
            var month = value.substring(3,5);
            var year = value.substring(6,10);
            formattedDate = day+month+year
        }

        return formattedDate
    }

    passwordValidatior = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    }

    regValidator = (reg) => {
       // const regex = /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][1-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[A-Z]{4}[0-9]{1,3}[A-Z]$)|(^[1-9]{1}[0-9]{1,3}[A-Z]{1,2}$)|(^[1-9]{1}[0-9]{1,2}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[1-9]{1}[A-Z]{1}$)|(^[1-9]{1}[A-Z]{2}$)|(^[1-9]{1}[A-Z]{3}$)|(^[1-9]{1}[0-9]{2}[DX]{1}[0-9]{2}$)|^[A-Z]{2}[0-9]{2}[A-Z]{2}|[1-9]{1}[0-9]{1}[A-Z]{2}[0-9]{2}|^[A-Z]{1}[1-9]{1}[0-9]{1,2}[A-Z]{3}/;
       const regex = /(^[A-Z]{2}[1-9][0-9]\s?[A-Z]{3}$)|(^[A-Z][1-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[1-9][0-9]{0,2}[A-Z]$)|(^[A-Z]{4}[0-9]{1,3}[A-Z]$)|(^[1-9]{1}[0-9]{1,3}[A-Z]{1,2}$)|(^[1-9]{1}[0-9]{1,2}[A-Z]{1,3}$)|(^[A-Z]{1,3}[1-9][0-9]{0,2}$)|(^[A-Z]{1,3}[1-9][0-9]{1,3}$)|(^[1-9]{1}[A-Z]{1}$)|(^[1-9]{1}[A-Z]{2}$)|(^[1-9]{1}[A-Z]{3}$)|(^[1-9]{1}[0-9]{2}[DX]{1}[0-9]{2}$)|^[A-Z]{2}[0-9]{2}[A-Z]{2}|[1-9]{1}[0-9]{1}[A-Z]{2}[0-9]{2}|^[A-Z]{1}[1-9]{1}[0-9]{1,2}[A-Z]{3}/; 
       return regex.test(reg);
    }

    irishRegValidator = (reg) => {
        const ireRegeex = /(^[0-9]{3}[A-Z]{1}[0-9]{1,6}$)|(^[0-9]{2}[A-Z]{1}[0-9]{1,6}$)|^[0-9]{2,3}[A-Z]{2}[0-9]{1,6}/;
        return ireRegeex.test(reg)
    }

    basicValidTest = (reg) => {
        const basicRegEx = /^(?=.{1,16}$)([A-Za-z0-9]+[\s]?[A-Za-z0-9]*)/;
        return basicRegEx.test(reg)
        
    }

    containsSpecialCharacters = (reg) => {
        const allowedCharacters = /[A-Za-z0-9 ]+$/
        return allowedCharacters.test(reg)
    }

    scrollToVehicle = (vehicleRow) => {
        vehicleRow.scrollIntoView({behavior: 'smooth', block: 'center'})
        vehicleRow.setAttribute('class', 'new-record')
        setTimeout(function(){ vehicleRow.removeAttribute('class', 'new-record') }, 3000);
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('id_token');
        localStorage.removeItem('role');
        sessionStorage.removeItem('url');
        localStorage.removeItem('policyID');
        localStorage.removeItem('trailer');
        localStorage.removeItem('ichiban');
        localStorage.removeItem('policies');
        localStorage.removeItem('name');
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }


    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            var error = new Error(response.statusText)
            error.response = response
            console.log(error.response)
            if (error.response.status === 423){
                localStorage.setItem('error', 'locked out')
            }
            throw error
        }
    }
}