import React from 'react'

const Period = props => {
    const ACTIVE = "LIVE"
    const DEACTIVE = "EXPIRED"
    const RENEW = "RENEWAL"
    return (
        <>
            <div id={props.id} className={props.className} data-index={props.index} data-id={props.policyId} data-active={props.response.activeFlag} onClick={() => {props.function(props.response.activeFlag, props.response.policyId, props.response.cancellationLapseInd, props.response.recordType, props.response.status)}}>
                {props.response.policyCoverStartDate}
                <br/> - <br/>
                {props.response.policyCoverEndDate}
                {props.response.status === 'LIVE' ?
                    <span className="live">{ACTIVE}</span> 
                :null}
                {props.response.status === 'EXPIRED' ?
                    <span className="expired">{DEACTIVE}</span>
                :null}
                {props.response.status === 'RENEWAL' || props.response.status === 'PENDING' ?
                    <span className="renewal">{RENEW}</span>
                :null}
                {props.response.status === 'LAPSED' ?
                    <span className="expired">LAPSED</span>
                :null}
                {props.response.status === 'DELETED' ?
                    <span className="expired">DELETED</span>
                :null}
                {props.response.status === 'CANCELLED' ?
                    <span className="expired">CANCELLED</span>
                :null}
                {props.response.status === 'DEACTIVATED' ?
                    <span className="expired">DEACTIVATED</span>
                :null}
            </div>
        </>
    )
}

export default Period