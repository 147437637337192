import React from 'react'
import throbber from '../../images/throbber.gif'
import OTable from '../atoms/OTable'
import SummaryText from '../Vehicle/SummaryText'
import * as Table from '../../resources/tableFile'

const VehicleTableContainer = props => {
    return (
        <>
        {props.data && props.tableHeaders ?
            <>
            {props.currentPage !== 'MidTable' ? 
            <>
            {props.policyStatus !== 'ExpiredVehicles' ?
            <SummaryText
                userType={props.userType}
                policyChosen={props.policyChosen}
                content={props.content}
                rejectedContent={props.rejectedContent}
            />
            :null}

            <OTable 
                data={props.data}
                adminRole={props.adminRole}
                readOnlyUser={props.readOnlyUser}
                tableHeaders={props.tableHeaders}
                filter={props.filter}
                sort={props.sort}
                activeFunction={props.activeFunction}
                action={props.action}
                newVehicleId={props.newVehicleId}
                vehicleAdded={props.vehicleAdded}
                resetVehicleAdded={props.resetVehicleAdded}
                currentPage={props.currentPage}
                searchReg={props.searchReg}
                searchLocation={props.searchLocation}
                policyStatus={props.policyStatus}
            />
            </>
            :null}
            {props.currentPage === 'MidTable' ?
             <OTable 
                data={props.data}
                tableHeaders={Table.MID}
                filter={true}
                sort={false}
                currentPage={props.currentPage}
                action={false}
            />           
            :null}
            </>
            
        :<img alt="loading" src={throbber}/>}
        </>
    )
}

export default VehicleTableContainer