import * as field from './fieldProperties'
export const UNDERWRITER = 'ROLE_UNDERWRITER'
export const ICHIBAN_USER = 'ROLE_ICHIBAN'
export const TRAILER_USER = 'ROLE_TRAILER'

export const DEFAULT_ROLE = 
    [
        field.POLICY_NUMBER,
        field.REGISTRATION_NUMER,
        field.FOREIGN_REG_INDICATIOR,
        field.TRADE_PLATE,
        field.TRADE_PLATE_READ_ONLY,
        field.MAKE,
        field.MODEL,
        field.COVER_START_DATE,
        field.COVER_END_DATE
    ]
export const DEFAULT_POLICY_CHOSEN = 
    [
        field.REGISTRATION_NUMER,
        field.FOREIGN_REG_INDICATIOR,
        field.TRADE_PLATE,
        field.TRADE_PLATE_READ_ONLY,
        field.MAKE,
        field.MODEL,
        field.COVER_START_DATE,
        field.COVER_END_DATE
    ]
export const TRAILER = 
    [
        field.REGISTRATION_NUMER,
        field.FOREIGN_REG_INDICATIOR,
        field.TRAILER_CHECK,
        field.TRADE_PLATE,
        field.TRADE_PLATE_READ_ONLY,
        field.MAKE_TRAILER,
        field.MODEL_TRAILER,
        field.COVER_START_DATE,
        field.COVER_END_DATE
    ]

export const ICHIBAN = 
    [
        field.REGISTRATION_NUMER,
        field.FOREIGN_REG_INDICATIOR,
        field.TRADE_PLATE,
        field.TRADE_PLATE_READ_ONLY,
        field.MAKE,
        field.MODEL,
        field.COVER_START_DATE,
        field.COVER_END_DATE,
        field.NAMED_DRIVERS,
        field.VEHICLE_DERIVATIVE
    ]

export const SUPER_USER = 
    [
        field.POLICY_SELECT
    ]
