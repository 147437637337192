import React, { Component } from 'react';
import AuthService from '../services/AuthService'
import Header from '../../Header'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import Button from '../../atoms/Button'
import InputField from '../../atoms/InputField'
import Label from '../../atoms/Label'
import '../../../../src/App.css'
import '../../../../src/Button.css'
import { Link } from 'react-router-dom';
import Util from '../../../Util'

class CustomerLogin extends Component {
    constructor(props){
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.Auth = new AuthService();
        this.U = new Util();
        this.state = {
            loginUI: true,
            email: '',
            password: '',
            shutPortal: false
        }
    }

    componentDidMount(){
        if (!this.U.checkOpeningTimes()){
            this.setState({shutPortal: false})
        } else {
            this.setState({shutPortal: false})
        }
    }

    showForgotPasswordUI = () => {
        this.setState({loginUI: !this.state.loginUI})
    }
        
    showLoginUI = () => {
        this.setState({loginUI: true})
    }

    render(){
        return (
            <>
                <Header/>
                <div className="LoginWrapper">
                    {this.state.loginUI ?
                    <>
                        <form id="admin-form" autoComplete="off">
                        <div className="FormField">
                            <Label for="email">Email:</Label>
                            <InputField
                                name="email"
                                type="text"
                                id="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="FormField">
                            <Label for="password">Password:</Label>
                            <InputField
                                    name="password"
                                    type="password"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                />
                        </div>
                        {this.state.showErrors ?
                            <p className="errorText">Please check the details entered and try again.</p>
                        :null}
                        {this.state.lockedOutLogin ?
                            <p className="errorText">Too many attempted logins, your account has been temporarily locked, please try again later</p>
                        :null}
                        {!this.state.shutPortal ?
                        <>
                            <Button className="button" onClick={this.handleFormSubmit}>Log In</Button>
                            <Link to="/login" className="button">Login as customer</Link>
                            <p onClick={this.showForgotPasswordUI}>Forgotten Password?</p>
                        </>
                        :null}
                        </form>
                        </>
                    : 
                    <>
                    {!this.state.shutPortal ?
                        <ForgotPassword hidePasswordUI={this.showLoginUI}/>
                    :null}
                    </>
                    }                
                </div>
            </>
        )
    }
    handleChange(e){
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )

    }

    handleFormSubmit(e){
        e.preventDefault();
        this.Auth.login(this.state.email,this.state.password)
            .then(res =>{
               this.props.history.replace('/');
            })
            .catch(err =>{
                let errorToken = localStorage.getItem("error")
                if (errorToken === 'locked out') {
                    this.setState({lockedOutLogin: true, showErrors: false})
                    localStorage.removeItem("error")
                } else {
                    this.setState({showErrors: true, lockedOutLogin: false})
                }
            })
    }

}

export default CustomerLogin