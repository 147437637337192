import React, { Component } from 'react';
import Period from '../atoms/Period';
import ToggleHeader from '../atoms/ToggleHeader'
import VehicleService from '../Vehicle/services/VehicleService'
import PolicyDetails from './PeriodDetails'
import PolicyService from './services/PolicyService'
const Vehicle = new VehicleService()
var expiredCars = [];
let selectedPolicyDetails = [];


class PolicySidebar extends Component {
    constructor(props){
        super(props)
        this.Policy = new PolicyService();
        this.state = {
            toggle: false,
            changedPeriod: false,
            showOtherPeriods: true,
            policyState: this.props.policyState,
            expiredCars: '',
            policyNumber: this.props.policyResponse[0].policyNumber
        }
    }


    componentDidMount(){
        if (this.props.userType){
            this.setState({policyStatus: this.props.policyStatus})
        }
        if (!this.props.userType && this.props.policyStatus === undefined){
            this.setState({policyStatusNew: this.Policy.getPolicyStatus(this.state.policyNumber)})
            this.Policy.getPolicyDetails(this.state.policyNumber)
            .then(res => {
                const compareData = res.sort((a,b) => (a.activeFlag > b.activeFlag) ? 1 : ((b.activeFlag > a.activeFlag) ? -1 : 0));
                this.setState({policyStatus: compareData[0].status})
            })
        }
    }

    showOtherPeriodsFunction = () => {
        this.setState({toggle: false, showOtherPeriods: true})
    }

    nonExpiredFunction = (id, cancellationLapseInd, status) => {
        Vehicle.policyVehicleSearchInactive(id)
        .then((result) => {
            this.props.setData(status, id, result, result.length, '', 'Table', expiredCars)
            this.setState({numberOfExpiredVehicles: result.length})
        })  
    }
    
    periodDetailsFunction = (status, id) => {
        if (selectedPolicyDetails.length !== 0){
            selectedPolicyDetails = []
        }
        for (var i = 0; i < this.props.policyDetails[status.toLowerCase()+'Details'].length; i++) {
            if (this.props.policyDetails[status.toLowerCase()+'Details'][i].policyId === id){
                selectedPolicyDetails.push(this.props.policyDetails[status.toLowerCase()+'Details'][i])
                return selectedPolicyDetails
            }
        }
    }
   
    periodFunction = (activeFlag, id, cancellationLapseInd, recordType, status) => {
        this.props.clearExport(status)
        this.props.setPolicyStatus(status)
        if (!this.props.disableSwitchPeriods || this.props.disableSwitchPeriods === undefined){
            this.setState({policyState: activeFlag, currentPolicyId: id})
            this.props.setPolicyState(activeFlag)

            if (status === 'LAPSED'){
                this.setState({changedPeriod: true, selectedNonActivePeriod: this.periodDetailsFunction(status, id)})
                this.nonExpiredFunction(id, cancellationLapseInd, status)
            }
            if (status === 'DEACTIVATED'){
                this.setState({changedPeriod: true, selectedNonActivePeriod: this.periodDetailsFunction(status, id)})
                this.nonExpiredFunction(id, cancellationLapseInd, status)
            }
            if (status === 'CANCELLED'){
                this.setState({changedPeriod: true, selectedNonActivePeriod: this.periodDetailsFunction(status, id)})
                this.nonExpiredFunction(id, cancellationLapseInd, status)
            }
            if (status === 'EXPIRED'){
                this.setState({changedPeriod: true, selectedNonActivePeriod: this.periodDetailsFunction(status, id) })
                // create new array which sorts out the multiple epired details to show the correct selected expired details
                
                Vehicle.policyVehicleSearchExpired(id)
                .then((result) => {
                    this.props.setData(status, id, result, result.length, '', 'ExpiredVehicles', result)
                    this.setState({expiredCars: result, numberOfExpiredVehicles: result.length})
                })
            }
            if (status === 'DELETED'){
                this.setState({changedPeriod: true, selectedNonActivePeriod: this.periodDetailsFunction(status, id)})
                this.nonExpiredFunction(id, cancellationLapseInd, status)
            }
            if (status === 'RENEWAL' || status === 'PENDING'){
                this.setState({changedPeriod: false})
                Vehicle.policyVehicleSearch(id)
                .then((result) => {
                    this.props.setData(status, id, result, result.length, this.props.renewalPolicyDetails[0], 'Table', expiredCars)
                    this.setState({numberOfRenewalVehicles: result.length})
                })
            }
            if (status === 'LIVE') {
                this.setState({changedPeriod: false})
                Vehicle.policyVehicleSearch(id)
                .then((result) => {
                    this.props.setData(status, id, result, result.length, this.props.policyDetails.activeDetails, 'Table', expiredCars)
                    this.setState({numberOfActiveVehicles: result.length})
                })
    
            }
        } else {
            this.props.closeSideBar()
        }
    }

    render(){
        return (
            <div className="policyDetail">
                <PolicyDetails
                    userType={this.props.userType} 
                    policyState={this.state.policyState}
                    policyStatus={this.props.policyStatus}
                    policyId={this.props.policyId}
                    policyResponse={this.props.policyResponse[0]}
                    policyDetails={this.props.policyDetails}
                    selectedNonActivePeriod={this.state.selectedNonActivePeriod}
                    numberOfVehiclesOnCover={this.props.numberOfVehiclesOnCover}
                    numberOfActiveVehicles={this.state.numberOfActiveVehicles}
                    numberOfRenewalVehicles={this.state.numberOfRenewalVehicles}
                    numberOfExpiredVehicles={this.state.numberOfExpiredVehicles}
                    midRecordType={this.props.midRecordType}
                    midPolicyData={this.props.midPolicyData}
                    showComponent={this.props.showComponent}
                    changedPeriod={this.state.changedPeriod}
                    policyNumber={this.state.policyNumber}
                    customerType={this.props.customerType}
                />
                
                <div id="period-selector">
                    <div className="period">
                        {this.props.policyResponse.length > 1 ?
                        <>
                        {this.state.toggle ?
                        <>
                        <ToggleHeader header="See other periods:" type="periods" function={this.showOtherPeriodsFunction} variable={this.state.showOtherPeriods}/>
                        </>
                        :null}
                        {this.props.policyStatus === 'RENEWAL' ? <p className="renewal">To add vehicle's to future cover select your renewal period</p>:null}
                        {this.state.showOtherPeriods ? 
                            <>
                            {this.props.policyResponse.map((response, index) =>
                            <>
                                <Period 
                                    response={response}
                                    id={response.policyId + 'box'}
                                    className={'periodBox' + ' ' + response.status}
                                    index={index}
                                    key={index}
                                    data-id={response.policyId}
                                    function={this.periodFunction}
                                />
                            </>
                            )}
                            </>
                        :null}
                            
                        </>
                        :null}
                    </div>
                </div>
            </div>
        )
    }
}


export default PolicySidebar