import React, {Component} from 'react'
import Table from '../atoms/Table'

class ExpiredVehicles extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: this.props.data,
        }

    }

    render(){
        return (
            <>
                {this.props.data.length > 0 ?
                <>
                    <Table data={this.props.data} policyState={this.props.policyState} />
                </>
                :<p>No vehicles</p>}
            </>
        )
    }
}

export default ExpiredVehicles