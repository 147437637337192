import React from 'react';
import Para from '../atoms/Para';
import PolicyService from './services/PolicyService'
const Policy = new PolicyService()

const PeriodDetailsBlock = props => {
    let status
    if (props.policyDetails !== undefined){
        if (props.customerType !== 'SUPER'){
            status = Policy.getPolicyStatusForClassName(props.policyDetails.status)
        } else {

        }
       
    }
    return (
        <>
        {props.policyDetails !== undefined && props.customerType === undefined ?
        <>
            <p id="details-PolicyNumber"><b>Policy Number:</b></p><p>{props.policyDetails.policyNumber}</p>
            <p><b>Policy holder:</b></p><p>{props.policyDetails.policyHolderName}</p>
            <p><b>Current Policy Period: <span className={status}>{props.policyDetails.status}</span></b></p>
            <p><b>Effective Start:</b> {props.policyDetails.policyCoverStartDate}</p>
            <p><b>Expiry:</b> {props.policyDetails.policyCoverEndDate}</p>
        </>
        :
        <>
        {props.currentPage === 'PolicySelect' ?
        <>
            <Para bold={true} value="Policy Number:"/><Para bold={false} caps={true} value={props.authDetails.user.sub}/>
            <p><b>Policy holder:</b><p>{props.authDetails.name}</p></p>
        </>
        :null}
        
        </>}


        
        </>
    )
}

export default PeriodDetailsBlock