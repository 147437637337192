import React, {Component} from 'react'
import axios from 'axios'
import * as Key from './resources/translations';
import * as Labels from '../../resources/labels'
import AuthService from './services/AuthService'
import Util from '../../Util'
import FormField from '../atoms/FormField'
import Button from '../atoms/Button'
import ComponentDisplayMessage from '../ComponentDisplayMessage'
import { FaWindowClose, FaCheckCircle } from 'react-icons/fa';
const Auth = new AuthService();
const api = Auth.api

class CreateAdmin extends Component {
    constructor(props){
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.createUser = this.createUser.bind(this);
        this.Util = new Util();
        this.state = {
            email: '',
            password: '',
            type: ''
        }
    }

    clearFields = () => {
        this.setState({email: '', password: '', type: ''})
    }

    handleChange(event){
        event.preventDefault();
        const { name, value } = event.target;
        if (name === 'password'){
            let passwordTest = this.Util.passwordValidatior(value)
            if (passwordTest){
                this.setState({validPassword: true})
            } else {
                this.setState({validPassword: false})
            }
        }
        let errors = this.state.errors;
        this.setState({errors, [name]: value}, () => {})
    }
    
    onAdminTypeChange = (e) => {
        this.setState({type: e.target.value}, () => {
            if (this.state.type === Labels.ADMIN_UNDERWRITER){
                this.setState({adminType: true}, () => {
                })
            } else {
                this.setState({adminType: false}, () => {
                })
            }
        })
    }
    createUser(e) {
        e.preventDefault();
        if (!this.state.email || !this.state.password || !this.state.type){
            window.alert('Please fill in required fields')
            if (!this.state.email){
                document.getElementById("email").classList.add("missing")
            }
            if (!this.state.password){
                document.getElementById("password").classList.add("missing")
            }
            if (!this.state.type){
                document.getElementById("adminType").classList.add("missing")
            }
        } else {
            if (this.state.validPassword){
                axios.post(`${api}/admin/signup/`, {
                    email: this.state.email,
                    password: this.state.password,
                    underwriter: this.state.adminType       
                },
                { headers: {Authorization: Auth.getTokenForFrontEnd()} }
                ).then(resp => {
                    this.setState({success: true, errors: false})
                    window.scrollTo(0, 0)
                })
                .catch(err => {
                    this.setState({errors: true, success: false})
                    window.scrollTo(0, 0)
                })
            
            } else { window.alert('Invalid Password')}
        }

    }

    render(){
        let regIcon
        if (this.state.validPassword){
            regIcon += ' valid-reg'
        }
        if (this.state.validPassword !== undefined && this.state.validPassword !== true){
            regIcon += ' invalid-reg'
        }

        if (this.state.validPassword === undefined){
            regIcon += ' empty-reg'
        }
        return (
            <>
                <div className="section" id="create-admin">
                    <h1 className="header">{Key.TITLE_CREATE_ADMIN}</h1>
                    <form>
                        <FormField
                            type="email"
                            id="email"
                            label={Key.EMAIL}
                            onChange={this.handleChange}
                            value={this.state.email}
                        />

                        <FormField
                            type="password"
                            id="password"
                            label={Key.PASSWORD}
                            hint={Key.PASSWORD_HINT}
                            onChange={this.handleChange}
                            value={this.state.password}
                            iconClass={regIcon}
                            icon={<FaWindowClose/>}
                            validIcon={<FaCheckCircle/>}
                            validReg={this.state.validPassword}
                        />

                        <FormField
                            type="select"
                            id="adminType"
                            value={this.state.type}
                            label={Labels.ADMIN_TYPE}
                            defaultSelect={Labels.CHOOSE_OPTION}
                            option1={Labels.FULL_ADMIN}
                            option2={Labels.ADMIN_UNDERWRITER}
                            onChange={this.onAdminTypeChange} 
                            onClick={this.handleClick}
                        />

                        <Button className="button actionBtn" onClick={this.createUser}>{Key.CREATE}</Button>
                    </form>
                        <ComponentDisplayMessage
                            error={this.state.errors}
                            defaultErrorMessage={Key.DEFAULT_ERROR}
                            success={this.state.success}
                            successMessage={Key.ADMIN_CREATED}
                        />
                </div>
            </>
        )
    }
}

export default CreateAdmin