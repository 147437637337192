import React, { useState } from 'react';
import PolicyImport from './PolicyImport';
import PolicyLog from './PolicyLog';
import PolicyLogTable from './PolicyLogTable';

const PolicyAdmin = props => {
    const [logData, setLogData] = useState("");
    const logDataNumber = logData.length
    const [showLog, closeLogTable] = useState("")
    return (
        <>
        <PolicyImport 
            currentPage={props.currentPage} 
            policyChosen={props.policyChosen}
            closeImportUis={props.closeImportUis}
            displayMessages={props.displayMessages}
            toggleMessages={props.toggleMessages} 
            closeLog={closeLogTable}
        />
        <PolicyLog
            currentPage={props.currentPage}
            setLogData={setLogData}
        />
        {!showLog || showLog === undefined ?
            <PolicyLogTable
                logData={logData}
                logDataNumber={logDataNumber}
            />
        :null}



        </>
    )
}


export default PolicyAdmin