import React, { Component } from 'react'

class ComponentDisplayMessage extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    reload = () => {
        window.location.reload()
    }

    render(){
        return (
            <>
                {this.props.success ?
                    <div className="componentDisplayMessage success">
                        <p>{this.props.successMessage}</p>
                    </div>
                :null}
                {this.props.error ?
                    <div className="componentDisplayMessage error">
                        {this.props.errorMessage ?
                            <p>{this.props.errorMessage}</p>
                        :null}
                        {this.props.dateError ?
                            <p>{this.props.dateErrorMessage}</p>
                        :null}
                        {this.props.duplicateVehicle ?
                            <p>{this.props.duplicateVehicleMessage}</p>
                        :null}
                        {this.props.alreadyRegistered ?
                            <p>{this.props.error}</p>
                        :null}
                        {this.props.doesNotExist && !this.props.errorMessage ?
                            <p>Does not exist</p>
                        :null}
                        
                        {this.props.noUpdate ?
                            <p>{this.props.noUpdateMessage}</p>
                        :null}
                        {this.props.doNotMatch ?
                            <p>{this.props.doNotMatchMessage}</p>
                        :null}
                        {this.props.incorrectCode ?
                            <p>Invalid Code, please check your email, or <span className="errorClick" onClick={() => {this.reload()}}>start the process again</span></p>
                        :null}
                        {!this.props.dateError && !this.props.duplicateVehicle && !this.props.noUpdate && !this.props.incorrectCode ?
                            <p>{this.props.defaultErrorMessage}</p>
                        :null}
                    </div>
                :null}

                {this.props.maintenanceWarning ?
                    <div className="warning">
                        <p>Application will be unavailable between 5pm and 6pm today due to essential system maintenance.</p>
                    </div>
                :null}

                {this.props.maintenanceDown ?
                    <div className="componentDisplayMessage error">
                        <p>Application is currently unavilable due to essential system maintenance, please try again later.</p>
                    </div>
                :null}
            </>
        )
    }
}

export default ComponentDisplayMessage