import React, { Component } from 'react'
import ToggleHeader from '../atoms/ToggleHeader'
import ActiveToggle from '../atoms/ActiveToggle'
import PeriodDetailsBlock from './PeriodDetailsBlock'
import PolicySidebar from './PolicySidebar'
import PolicyService from './services/PolicyService'

class PeriodDetails extends Component {
    constructor(props){
        super(props)
        this.Policy = new PolicyService();
        this.state = {
            midDetails: false,
            midToggle: false,
           
        }
    }

    showMID = () => {
        this.setState({midDetails: !this.state.midDetails})
    }

    showComponent = () => {
        this.setState({midToggle: !this.state.midToggle}, () => {
            if (this.state.midToggle){
                this.props.showComponent(this.props.userType, "MidTable", false, false)
            } else {
                this.props.showComponent(this.props.userType, "Table", false, false)
            }
        })
    }

    componentWillReceiveProps(){
        if (this.props.policyStatus !== undefined){
            if ( this.props.policyStatus !== 'LIVE' && this.props.policyStatus !== 'RENEWAL' && this.props.policyStatus !== 'PENDING'){
                let data = this.props.policyDetails[this.props.policyStatus.toLowerCase()+'Details']
                let policyId = this.props.policyId
                this.setState({nonActivePeriodDetails: data.filter(function (el) {
                    return el.policyId === policyId
                }), nonActivePolicyDetails: true})
            } else {
                this.setState({nonActivePolicyDetails: false})
            }
        }
    }
    render(){
        return (
            <>
            {this.props.policyStatus !== undefined ?
            <>
            <h2>Policy Details</h2>

            {this.props.policyStatus === 'LIVE' ?
                <>
                {this.props.policyDetails.activeDetails === undefined ? <></> 
                :<>
                    <PeriodDetailsBlock policyDetails={this.props.policyDetails.activeDetails} test3={'dddd'} />
                    
                    {this.props.numberOfActiveVehicles ?
                    <p><b>Active Vehicles:</b> {this.props.numberOfActiveVehicles}</p>
                    :<p><b>Active Vehicles:</b> {this.props.numberOfVehiclesOnCover}</p>}
                </> 
                }  
                </>
            :null}


            {this.props.policyStatus !== 'LIVE' && this.props.policyStatus !== 'RENEWAL' ?
            <>
                {this.props.changedPeriod ?
                <>
                    
                    <PeriodDetailsBlock policyDetails={this.props.selectedNonActivePeriod[0]} test2={'ddd'} />
                </>
                :
                <>
                {this.state.nonActivePeriodDetails !== undefined ?
                    <>
                    {this.props.policyStatus === 'PENDING' ?
                    <>
                     <PeriodDetailsBlock policyDetails={this.props.policyDetails.renewalPolicyDetails} test5={'dddddd'} />
                    </>
                    : <PeriodDetailsBlock policyDetails={this.state.nonActivePeriodDetails[0]} test4={'dddddd'} />}
                   
                    </>
                    :
                    <>
                        {this.props.policyStatus === 'PENDING' ?
                        <PeriodDetailsBlock policyDetails={this.props.policyDetails.renewalPolicyDetails} test={'dd'} />
                        :null}
                    </>
                }
                </>}
            </>
            :null}
            
            {this.props.userType ?
                <>
                <ToggleHeader header="MID Policy Details" type="periods" function={this.showMID} variable={this.state.midDetails}/>
                </>
            :null}

            {this.state.midDetails ?
            <>
            <div className="midDetailsWrapper">
                <p><b>Update Type:</b> {this.props.midPolicyData.recordType}</p>
                <p><b>PPCC:</b> {this.props.midPolicyData.partyPolicyControlCount}</p>
                <p><b>FSN:</b> {this.props.midPolicyData.midFileSeqNo}</p>
                <p><b>MID Status:</b> {this.props.midPolicyData.midStatus}</p>
            </div>
            </>
            :null}
            {this.props.policyState === 'A' && this.props.userType ?
                <ActiveToggle label="Mid Table" value={this.state.midToggle} action={this.showComponent} name="midToggle" id="midToggle" />
            :null}
            </>
            :null}
            </>   
        )
    }
}

export default PeriodDetails