import React from 'react'
import * as Labels from '../../resources/labels'

const Table = props => {


    return (
        <>
            <table id="vehicleTable">
                <thead>
                        <tr>
                        <th>{Labels.REGISTRATION_NUMER}</th>
                        <th>{Labels.TRADE_PLATE}</th>
                        <th>{Labels.MAKE}</th>
                        <th>{Labels.MODEL}</th>
                        <th>{Labels.ON_DATE}</th>
                        <th>{Labels.OFF_DATE}</th>
                        <th>{Labels.LOCATION}</th>
                        <th>{Labels.LAST_UPDATED}</th>
                        <th>{Labels.MID_STATUS}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map((item,index) => 
                         <tr id={item.vehicleId} key={index}>
                            <td>{item.registration}</td>
                            <td>{item.tradePlate}</td>
                            <td>{item.vehicleMake}</td>
                            <td>{item.vehicleModel}</td>
                            <td>{item.vehicleCoverStartDate}</td>
                            <td>{item.vehicleCoverEndDate}</td>
                            <td>{item.location}</td>
                            <td>{item.lastUpdated}</td>
                            <td>{item.midStatus}</td>
                     </tr>
                    )}
                </tbody>
            </table>
        </>
    )
}

export default Table