import React from 'react'
import PeriodDetailsBlock from './PeriodDetailsBlock'

const AdminCustomer = props => {
    return (
        <>
            <div className="policyDetail">
            <h2>Policy Details</h2>
            <PeriodDetailsBlock authDetails={props.authDetails} currentPage={props.currentPage} />
            </div>
            
          
        </>
    )
}

export default AdminCustomer