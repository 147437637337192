import React from 'react'

const Para = props => {
    let styles = ''
    if (props.bold){
        styles += ' bold'
    }
    if (props.caps){
        styles += ' caps'
    }
    return (
        <>
        <p className={styles}>{props.value}</p>
        </>
    )
}

export default Para