export const POLICY_NUMBER = 'Policy Number'
export const POLICY_HOLDER_NAME = 'Policyholder'
export const REGISTRATION_NUMER = 'Vehicle Registration'
export const FOREIGN_REG_INDICATIOR = 'Is this a foreign registration plate?'
export const TRADE_PLATE = 'Trade Plate'
export const MAKE = 'Make'
export const MODEL = 'Model'
export const LOCATION = 'Location'
export const ON_DATE = 'On Date'
export const OFF_DATE = 'Off Date'
export const LAST_UPDATED = 'Last Updated'
export const AMENDED = 'Amended'
export const MID_STATUS = 'MID Status'
export const ADMIN_TYPE = 'Admin Type'
export const ADMIN_UNDERWRITER = 'Underwriter'
export const FULL_ADMIN = 'Full Admin'
export const CHOOSE_OPTION = 'Select an option'
export const TRADE_PLATE_U = 'U'
export const TRADE_PLATE_T = 'T'
export const EFFECTIVE_START = 'Effective Start'
export const EXPIRY_DATE = 'Expiry Date'
export const EFFECTIVE_DATE = 'Confirm Effective Date'
export const COVER_START_DATE = 'Cover Start Date'
export const COVER_END_DATE = 'Cover End Date'
export const UPDATE_TYPE = 'Update Type'
export const PPCC = 'PPCC'
export const FSN = 'FSN'

// NAVIGATION BUTTONS
export const POLICY_SEARCH = "Policy Search"
export const RETURN_TO_SEARCH = "Return to Search"
export const VEHICLE_ADMIN = "Vehicle Administration"
export const USER_ADMIN = "User Management"
export const HELP = "Need Help?"
export const ADD_VEHICLE = ""
export const REJECTED_VEHICLES = "Rejected Vehicles"
export const IMPORT_VEHICLES_CUSTOMER = ""
export const IMPORT_VEHICLES_BULK = ""
