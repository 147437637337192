import React, { Component } from 'react';
import axios from 'axios'
import FormField from '../atoms/FormField'
import * as Key from './resources/translations';
import * as Labels from '../../resources/labels'
import AuthService from '../UserAdmin/services/AuthService'
import PolicyService from '../Policy/services/PolicyService'
import Button from '../atoms/Button'
import ComponentDisplayMessage from '../ComponentDisplayMessage'
import OTable from '../atoms/OTable';
import * as Table from '../../resources/tableFile'
import { FaTimesCircle } from 'react-icons/fa';
import Para from '../atoms/Para';
import Header from '../atoms/Header';
const Auth = new AuthService();
const api = Auth.api
const Policy = new PolicyService();
let array = []
let modPol = []
let newPolArray = []

class UpdateCustomerAdmin extends Component {
    constructor(props){
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            success: false
        }
    }

    handleChange(event){
        event.preventDefault();
        const { name, value } = event.target;
        this.setState({[name]: value}, () => {})
    }

    getPolicies = () => {
        if (this.state.policyNumber.startsWith('S')){
            Auth.getListOfPolicies(this.state.policyNumber)
            .then(res => {
               this.setState({currentPolicies: res.policies.map(function(item) { return item.policyNumber;}), policyFound: true})
            })
        } else {
            window.alert("Not a valid Admin Customer")
        }
    }

    addPolToArray = () => {
        if (this.state.policy.length === 7){
            Policy.policySearch(this.state.policy, '')
            .then(res => {
                if(this.state.currentPolicies.indexOf(this.state.policy) !== -1 ) {
                    alert("Policy has already been added")
                } else{
                    array.push(this.state.policy)
                    this.setState({policies: array, policy: '', notAPolicy: false})
                }
            })
            .catch(error => {
                this.setState({notAPolicy: true})
            })
        } else {
            this.setState({notAPolicy: true})
        }
    }

    removePolicy = (policyNumber) => {
        const index = this.state.currentPolicies.indexOf(policyNumber);
        if (index > -1) {
        this.state.currentPolicies.splice(index, 1);
        }
        let currentPols = this.state.currentPolicies;
        this.setState({currentPolicies: currentPols})
    }

    newAddedRemovePolicy = (policyNumber) => {
        const index = this.state.policies.indexOf(policyNumber);
        if (index > -1) {
        this.state.policies.splice(index, 1);
        }
        let newPols = this.state.policies;
        this.setState({policies: newPols})
    }

    updatePolicy = () => {
      const current = this.state.currentPolicies
      const newPol = this.state.policies
      const combinePol = current.concat(newPol)
      this.setState({combinePol: combinePol}, () => {
        this.updateAdminPolicy()
      })
    }

    updateAdminPolicy = () => {
        var filtered = this.state.combinePol.filter(function (el) {
        return el != null;
        });
        axios.put(`${api}/update/adminCustomer`,{
            loginId: this.state.policyNumber.toUpperCase(),
            policies: filtered,
            pin: ''
        },
        {
            headers: {
                Authorization: Auth.getTokenForFrontEnd()
            }
        }
        )
        .then(resp => {
            this.setState({success: true, errors: false, policyNumber: '', policyFound: false, alreadyRegistered: false, doesNotExist: false})
        })
        .catch(err => {
            this.setState({success: false, errors: true, errorCode: err.response.status, errorMessage: err.response.data})
            if (this.state.errorCode === 409 && this.state.errorMessage.includes('already registered')){
                this.setState({alreadyRegistered: true})
            }
            if (this.state.errorCode === 404 && this.state.errorMessage.includes('does not exist')){
                this.setState({doesNotExist: true, errorMessage: this.state.errorMessage})
                
            }
        })
    }

    reset = () => {
        this.setState({success: false, policyNumber: '', policies: '', combinePol: '', currentPolicies: '', notAPolicy: '', policyFound:false})
    }

    render() {
        return (
            <>
            <Header value="Update Admin Customer"/>
            <FormField
                type="text"
                id="policyNumber"
                label={Key.POLICY_NUMBER}
                onChange={this.handleChange}
                value={this.state.policyNumber}
                hint={"Policy Number must start with a 'S'"}
            />
            <Button onClick={() => this.getPolicies()} className="button" id="getPolicies" name="getPolicies">Retrieve Current Policies</Button>
            {this.state.currentPolicies ? 
                <>
                    <Para value="Current Policies added:" bold={true} />
                   <table className="listOfPolTable">
                    {this.state.currentPolicies.map((field, index) => 
                    <>
                    <tr>
                        <td>{field}</td>
                        <td onClick={() => this.removePolicy(field)}><span><FaTimesCircle/></span></td>
                    </tr>
                    </>
                    )}
                    </table>
                    <FormField
                        type="text"
                        id="policy"
                        label="Add a policy..."
                        onChange={this.handleChange}
                        value={this.state.policy}
                    />
                    {this.state.notAPolicy ?<Para value="This is not a valid policy" />:null}
                    <Button className="button" id="addPoltoArray" name="addPoltoArray" onClick={() => this.addPolToArray()}>Add</Button>
                    {this.state.policies ?
                    <>
                    <Para value="New policies to be added:" bold={true} />
                    <table className="listOfPolTable">
                    {this.state.policies.map((field, index) => 
                    <>
                    <tr>
                        <td>{field}</td>
                        <td onClick={() => this.newAddedRemovePolicy(field)}><span><FaTimesCircle/></span></td>
                    </tr>
                    </>
                    )}
                    </table> 
                    </>                   
                    :null}

                </>
            :null}
            
            {!this.state.success && this.state.policyFound ?
                <Button onClick={() => this.updatePolicy()} className="button" id="update" name="update">Update Policy</Button>
            :null}
            {this.state.success ?
                <Button onClick={() => this.reset()} className="button" id="reset" name="reset">Start Over</Button>
            :null}

            <ComponentDisplayMessage
                error={this.state.errorMessage}
                alreadyRegistered={this.state.alreadyRegistered}
                doesNotExist={this.state.doesNotExist}
                defaultErrorMessage={Key.DEFAULT_ERROR}
                success={this.state.success}
                successMessage="Policy Updated"
                errorMessage={this.state.errorMessage}
            />
            
            </>
        )
    }
}

export default UpdateCustomerAdmin