export const TITLE_CREATE_ADMIN = "Admin Creation"
export const EMAIL = "Email"
export const PASSWORD = "Password"
export const ADMIN_TYPE = "Admin Type"
export const PASSWORD_HINT = "Password must be at least 8 characters and contain 1 uppercase letter and 1 special character"
export const CREATE = "Create"
export const DEFAULT_ERROR = "There has been an error, please try again or contact support."
export const ADMIN_CREATED = "Admin Created Successfully"
export const PIN_TITLE = "PIN Generator"
export const POLICY_NUMBER = "Policy Number"
export const POLICY_NUMBER_HINT = "Policy must start with 'A' and be 7 characters long"
export const CUSTOMER_ADMIN_POLICY_NUMBER_HINT = "Policy must start with 'S' and be 7 characters long"
export const PIN_LABEL = "PIN Number"
export const SHOW_PIN = "Show PIN"
export const ATTACH_PIN = "Attach PIN to Policy"
export const PIN_ATTACHED = "PIN Attached Successfully"
export const PIN_RESET = "PIN Reset Successfully"
export const CUSTOMER_TYPE = "Customer Type"
export const CHOOSE_OPTION = "Choose option"
export const NORMAL = "Normal"
export const READ_ONLY = "Read Only"
export const ICHIBAN = "Ichiban"
export const TRAILER = "Trailer"
export const POLICY_HOLDER_NAME = "Policyholder Name"
// BUTTONS //
export const CREATE_ADMIN_USER = "Create Admin User"
export const CREATE_PIN = "Create new PIN for Customer"