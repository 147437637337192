import React, { Component } from 'react'
import { FaWindowClose } from 'react-icons/fa';

class DisplayBlock extends Component {
    constructor(props){
        super(props)
        this.state = {
            defaultError: 'There has been an error, please try again or contact support.',
            duplicateVehicle: 'Duplicate vehicle on this policy',
            vehicleStartDateError: 'Vehicle Start Date must be after policy effective stat date!',
            defaultSuccess: 'Success!',
            vehicleRefresh: 'Vehicles updated!',
            importFileContentError: 'File Content error',
            importFileFormatError: 'File Format error',
            importFileDuplicateError: 'Duplicate Vehicle already on cover',
            noVehicleOnPolicy: 'No vehicles found on this policy',
            nothingToUpdate: 'Nothing to update',
            vehicleEndDateError: 'Unable to create vehicle as cover start date is after cover end date'
        }
    }
    render(){
        return (
            <div>
                {this.props.errors ?
                     <div className="Error">
                         {this.props.duplicateVehicle ?
                            <p>{this.state.duplicateVehicle}</p>
                         :null}
                        {this.props.vehicleStartDateError ?
                            <p>{this.state.vehicleStartDateError}</p>
                        :null}
                        {this.props.importFileContentError ?
                            <p>{this.state.importFileContentError}</p>
                        :null}
                        {this.props.importFileFormatError ?
                            <p>{this.state.importFileFormatError}</p>
                        :null}
                        {this.props.importFileDuplicateError ?
                            <p>{this.state.importFileDuplicateError}</p>
                        :null}
                        {this.props.importDefaultError ?
                            <p>{this.state.defaultError}</p>
                        :null}
                        {this.props.nothingToUpdate ?
                            <p>{this.state.nothingToUpdate}</p>
                        :null}
                        {this.props.noVehicleOnPolicy ?
                            <p>{this.state.noVehicleOnPolicy}</p>
                        :null}
                        {this.props.endDateError ? 
                            <p>{this.state.vehicleEndDateError}</p>
                        :null}
                        {!this.props.endDateError && !this.props.duplicateVehicle && !this.props.vehicleStartDateError && !this.props.importFileContentError && !this.props.importFileFormatError && !this.props.importDefaultError && !this.props.importFileDuplicateError && !this.props.nothingToUpdate && !this.props.noVehicleOnPolicy ?
                        <>
                        <p>{this.state.defaultError}</p>
                            <span id="close_amend" className="close-icon error" onClick={this.props.closeDisplayMessage}><FaWindowClose /></span>
                        </>
                        :null}
                        <span id="close_amend" className="close-icon error" onClick={this.props.closeDisplayMessage}><FaWindowClose /></span>
                    </div>
                :null}
                {this.props.success ?
                    <div className="Success">
                        {this.props.importRefresh ?
                        <p>{this.state.vehicleRefresh}</p>
                    : <p>{this.state.defaultSuccess}</p>}
                    <span id="close_success" className="close-icon" onClick={this.props.closeDisplayMessage}><FaWindowClose /></span>
                    </div>               
                :null}
               
            </div>
        )
    }
}

export default DisplayBlock