import React from 'react'
import Button from '../components/atoms/Button'

const PortalShutDown = props => {
    return (
        <>
        <div id="portalShutDownMessage">
            <span dangerouslySetInnerHTML={ {__html: props.content} }></span>
            <Button id="logout" className="button" onClick={props.handleLogout}>Logout</Button>
        </div>
        </>
    )
}

export default PortalShutDown