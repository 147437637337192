export const SEARCHED_RETURN = 'Your search returned'
export const RESULTS = 'results'
export const CHOSEN_POLICY = 'Chosen Policy:'
export const PROCEED = 'Proceed'
export const CLICK_TO_PROCEED = 'Click on a policy to proceed'
export const SEARCH = 'Search'
export const SEARCH_RETURNED = 'Your search returned'
export const ADD_VEHICLE = "Add vehicle"
export const EXPORT_VEHICLES = "Export Vehicle List (CSV)"
export const SEARCH_VEHICLES = "Vehicle Search"
export const IMPORT_VEHICLES = "Import Vehicle(s)"