import React, {Component} from 'react'
import * as Table from '../../resources/tableFile'
import VehicleTableContainer from './VehicleTableContainer'

class ExpiredVehicles extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: this.props.data,
            fileName: '_vhcl_file_'+new Date().toISOString().split('T')[0],
            tableHeaders: this.getTableHeaders()
        }
    }
    getTableHeaders = () => {
        if (this.props.policyChosen && this.props.userType || !this.props.userType) {
            return Table.CUSTOMER_STANDARD
        }
    }
    render(){
        return (
            <>
                {this.props.data.length > 0 ?
                <>
                    <VehicleTableContainer 
                        data={this.props.data}
                        currentPage={this.props.currentPage}
                        policyChosen={this.props.policyChosen}
                        tableHeaders={this.state.tableHeaders}
                        filter={false}
                        sort={false}
                        policyStatus={this.props.policyStatus}
                    />
                </>
                :<p>No vehicles</p>}
            </>
        )
    }
}

export default ExpiredVehicles