import React, { Component } from 'react';
import Button from '../atoms/Button'

class PolicySwitcher extends Component {
    constructor(props){
        super(props)
        this.state = {
            policies: this.props.policies.sort((a,b) => (a.policyNumber > b.policyNumber) ? 1 : ((b.policyNumber > a.policyNumber) ? -1 : 0)),
            policyId: this.props.policyId
        }
    }

    switch = (e) => {
      this.props.switchPolicy(e.target.value)
    }

    render(){
        let mainNavigationButton = 'button dashButton'
        return (
            <>
            {this.state.policies ?
                <>
                <div id="policySwitch" className="sidebarSection">
                <h2>Policy Switcher</h2>
                <select onChange={this.switch}> 
                <option value=""> -- Switch Policy -- </option>
                {this.state.policies.map((item) => <option value={item.policyNumber}>{item.policyNumber} - {item.policyHolder}</option>)}
                </select>
                <Button id="returnToTable" className={mainNavigationButton} onClick={() => {this.props.returnToTable()}}>Return to Table</Button>
                </div>
                </>
            :null}
            </>
        )
    }
}

export default PolicySwitcher