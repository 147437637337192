import React, { Component } from 'react'
import AuthService from './services/AuthService'
import CreateAdmin from './CreateAdmin'
import ResetPIN from './ResetPIN'
import AttachPIN from './AttachPIN'
import CreateSuperCustomer from './CreateSuperCustomer'

class UserAdmin extends Component {
    constructor(props){
        super(props)
        this.Auth = new AuthService();
        this.state = {
            createAdminUserUI: false,
            pinUI: false,
            showErrors: false,
            showSuccess: false,
            errors: {
                email: '',
                password: '',
                pinNumber: '',
                policyNumber: ''
            }
        }
    }

    clearFields = () => {
        this.setState({email: '', password: '', pinNumber: ''})
    }

    showCreateAdminUserUi = () => {
        this.setState({createAdminUserUI: !this.state.createAdminUserUI, pinUI: false, resetPin: false})
        this.clearFields()
    }

    showPINGeneratorUI = () => {
        this.setState({pinUI: !this.state.pinUI, createAdminUserUI: false, resetPin: false})
        this.clearFields()
    }

    showResetPinUI = () => {
        this.setState({resetPin: !this.state.resetPin, createAdminUserUI: false, pinUI: false})
        this.clearFields()
    }

    render(){

        return(
            <>                
                {this.props.currentPage === 'CreateAdmin' ?
                    <CreateAdmin /> 
                : null}
                {this.props.currentPage === 'CreatePIN' ?
                    <AttachPIN />
                :null}
                {this.props.currentPage === 'ResetPIN' ?
                    <ResetPIN
                        policyNumber={this.props.policyNumber}
                    />
                :null}
                {this.props.currentPage === 'CreateSuperCustomer' ?
                    <CreateSuperCustomer />
                :null}
            </>
        )
    }
}

export default UserAdmin