import * as TABLE_DATA from './tableData'


export const ADMIN_STANDARD = [
    {
        Header: TABLE_DATA.POLICY_NUMBER.title,
        accessor: TABLE_DATA.POLICY_NUMBER.dataKey, // accessor is the "key" in the data
      },
      {
        Header: TABLE_DATA.REGISTRATION_NUMER.title,
        accessor: TABLE_DATA.REGISTRATION_NUMER.dataKey,
      },
      {
        Header: TABLE_DATA.TRADE_PLATE.title,
        accessor: TABLE_DATA.TRADE_PLATE.dataKey
      },
      {
          Header: TABLE_DATA.MAKE.title,
          accessor: TABLE_DATA.MAKE.dataKey
      },
      {
          Header: TABLE_DATA.MODEL.title,
          accessor: TABLE_DATA.MODEL.dataKey
      },
      {
          Header: TABLE_DATA.ON_DATE.title,
          accessor: TABLE_DATA.ON_DATE.dataKey
      },
      {
          Header: TABLE_DATA.OFF_DATE.title,
          accessor: TABLE_DATA.OFF_DATE.dataKey
      },
      {
          Header: TABLE_DATA.LOCATION.title,
          accessor: TABLE_DATA.LOCATION.dataKey
      },
      {
          Header: TABLE_DATA.LAST_UPDATED.title,
          accessor: TABLE_DATA.LAST_UPDATED.dataKey
      },
      {
          Header: TABLE_DATA.MID_STATUS.title,
          accessor: TABLE_DATA.MID_STATUS.dataKey
      }
]

export const ADMIN_REJECTED = [
    {
        Header: TABLE_DATA.POLICY_NUMBER.title,
        accessor: TABLE_DATA.POLICY_NUMBER.dataKey, // accessor is the "key" in the data
      },
      {
        Header: TABLE_DATA.REGISTRATION_NUMER.title,
        accessor: TABLE_DATA.REGISTRATION_NUMER.dataKey,
      },
      {
        Header: TABLE_DATA.TRADE_PLATE.title,
        accessor: TABLE_DATA.TRADE_PLATE.dataKey
      },
      {
          Header: TABLE_DATA.MAKE.title,
          accessor: TABLE_DATA.MAKE.dataKey
      },
      {
          Header: TABLE_DATA.MODEL.title,
          accessor: TABLE_DATA.MODEL.dataKey
      },
      {
          Header: TABLE_DATA.ON_DATE.title,
          accessor: TABLE_DATA.ON_DATE.dataKey
      },
      {
          Header: TABLE_DATA.OFF_DATE.title,
          accessor: TABLE_DATA.OFF_DATE.dataKey
      },
      {
          Header: TABLE_DATA.LOCATION.title,
          accessor: TABLE_DATA.LOCATION.dataKey
      },
      {
          Header: TABLE_DATA.LAST_UPDATED.title,
          accessor: TABLE_DATA.LAST_UPDATED.dataKey
      },
      {
          Header: TABLE_DATA.MID_STATUS.title,
          accessor: TABLE_DATA.MID_STATUS.dataKey
      }
]

export const POLICY_SEARCH = [
    {
        Header: TABLE_DATA.POLICY_NUMBER.title,
        accessor: TABLE_DATA.POLICY_NUMBER.dataKey
    },
    {
        Header: TABLE_DATA.POLICY_HOLDER_NAME.title,
        accessor: TABLE_DATA.POLICY_HOLDER_NAME.dataKey
    },
    {
        Header: TABLE_DATA.POLICY_START_DATE.title,
        accessor: TABLE_DATA.POLICY_START_DATE.dataKey
    },
    {
        Header: TABLE_DATA.POLICY_END_DATE.title,
        accessor: TABLE_DATA.POLICY_END_DATE.dataKey
    }
]

export const POLICY_SELECT = [
    {
        Header: TABLE_DATA.POLICY_NUMBER.title,
        accessor: TABLE_DATA.POLICY_NUMBER.dataKey
    },
    {
        Header: TABLE_DATA.POLICY_HOLDER_NAME_SELECT.title,
        accessor: TABLE_DATA.POLICY_HOLDER_NAME_SELECT.dataKey
    },
    {
        Header: TABLE_DATA.EFFECTIVE_DATE.title,
        accessor: TABLE_DATA.EFFECTIVE_DATE.dataKey
    },
    {
        Header: TABLE_DATA.EXPIRY_DATE.title,
        accessor: TABLE_DATA.EXPIRY_DATE.dataKey
    }
]

export const CURRENT_POLICIES = [
    {
        Header: TABLE_DATA.POLICY_NUMBER.title,
        accessor: TABLE_DATA.POLICY_NUMBER.dataKey
    },
    {
        Header: TABLE_DATA.EFFECTIVE_DATE.title,
        accessor: TABLE_DATA.EFFECTIVE_DATE.dataKey
    },
    {
        Header: TABLE_DATA.EXPIRY_DATE.title,
        accessor: TABLE_DATA.EXPIRY_DATE.dataKey
    }
]

export const CUSTOMER_STANDARD = [
      {
        Header: TABLE_DATA.REGISTRATION_NUMER.title,
        accessor: TABLE_DATA.REGISTRATION_NUMER.dataKey,
      },
      {
        Header: TABLE_DATA.TRADE_PLATE.title,
        accessor: TABLE_DATA.TRADE_PLATE.dataKey
      },
      {
          Header: TABLE_DATA.MAKE.title,
          accessor: TABLE_DATA.MAKE.dataKey
      },
      {
          Header: TABLE_DATA.MODEL.title,
          accessor: TABLE_DATA.MODEL.dataKey
      },
      {
          Header: TABLE_DATA.ON_DATE.title,
          accessor: TABLE_DATA.ON_DATE.dataKey
      },
      {
          Header: TABLE_DATA.OFF_DATE.title,
          accessor: TABLE_DATA.OFF_DATE.dataKey
      },
      {
          Header: TABLE_DATA.LOCATION.title,
          accessor: TABLE_DATA.LOCATION.dataKey
      },
      {
          Header: TABLE_DATA.LAST_UPDATED.title,
          accessor: TABLE_DATA.LAST_UPDATED.dataKey
      },
      {
          Header: TABLE_DATA.MID_STATUS.title,
          accessor: TABLE_DATA.MID_STATUS.dataKey
      }
]

export const ADMIN_POLICY_REJECTED = [
    {
        Header: TABLE_DATA.POLICY_NUMBER.title,
        accessor: TABLE_DATA.POLICY_NUMBER.dataKey
    },
    {
        Header: TABLE_DATA.TERM.title,
        accessor: TABLE_DATA.TERM.dataKey
    },
    {
        Header: TABLE_DATA.PPCC.title,
        accessor: TABLE_DATA.PPCC.dataKey
    },
    {
        Header: TABLE_DATA.REASON_CODE.title,
        accessor: TABLE_DATA.REASON_CODE.dataKey
    }
]

export const MID = [
    {
        Header: TABLE_DATA.POLICY_NUMBER.title,
        accessor: TABLE_DATA.POLICY_NUMBER.dataKey
    },
    {
        Header: TABLE_DATA.UPDATE_TYPE.title,
        accessor: TABLE_DATA.UPDATE_TYPE.dataKey
    },
    {
        Header: TABLE_DATA.PPCC.title,
        accessor: TABLE_DATA.PPCC.dataKey
    },
    {
        Header: TABLE_DATA.REGISTRATION_NUMER.title,
        accessor: TABLE_DATA.REGISTRATION_NUMER.dataKey
    },
    {
        Header: TABLE_DATA.FILE_SEQUENCE_NUMBER.title,
        accessor: TABLE_DATA.FILE_SEQUENCE_NUMBER.dataKey
    },
    {
        Header: TABLE_DATA.MID_STATUS.title,
        accessor: TABLE_DATA.MID_STATUS.dataKey
    }
]

export const POLICY_LOG = [
    {
        Header: TABLE_DATA.POLICY_NUMBER.title,
        accessor: TABLE_DATA.POLICY_NUMBER.dataKey
    },
    {
        Header: TABLE_DATA.POLICY_LOG_COVER_START.title,
        accessor: TABLE_DATA.POLICY_LOG_COVER_START.dataKey
    },
    {
        Header: TABLE_DATA.POLICY_LOG_COVER_END.title,
        accessor: TABLE_DATA.POLICY_LOG_COVER_END.dataKey
    },
    {
        Header: TABLE_DATA.ACTIVE_VEHICLES.title,
        accessor: TABLE_DATA.ACTIVE_VEHICLES.dataKey
    },
    {
        Header: TABLE_DATA.NUMBER_OF_LOGINS.title,
        accessor: TABLE_DATA.NUMBER_OF_LOGINS.dataKey
    },
    {
        Header: TABLE_DATA.LAST_ACTIVITY.title,
        accessor: TABLE_DATA.LAST_ACTIVITY.dataKey
    }
]