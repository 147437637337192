import React, { Component } from 'react'
import ContactUs from './ContactUs'
import NavigationBar from './NavigationBar'
import PolicySidebar from './Policy/PolicySidebar'
import ExportVehicle from './Vehicle/export/ExportVehicle'
import { FaPlus } from 'react-icons/fa'
import Button from '../components/atoms/Button'
import VehicleService from './Vehicle/services/VehicleService'
import PolicySwitcher from './Policy/PolicySwitcher'
import AdminCustomer from './Policy/AdminCustomer'
import PolicyService from './Policy/services/PolicyService'

class Sidebar extends Component {
    constructor(props){
        super(props)
        this.Vehicle = new VehicleService();
        this.Policy = new PolicyService();
        this.state = {
            exportObject: false
        }
    }

    closeSideBar = () => {
        document.getElementById("sideNav").style.width = "0px";
        document.getElementById('main-view').classList.remove("untouchable")
        this.setState({exportObject: ''})
    }

    returnToSearch = () => {
        this.props.showComponent(this.props.userType, "Search", false, true)
        this.setState({exportData: ''})
        if (this.props.policyStatus !== 'EXPIRED' && this.props.currentPage === 'Table'){
            document.querySelector("#registration > div > input").value = ''
            document.querySelector("#location > div > input").value = ''
        }    
    }

    returnToTable = () => {
        this.props.showComponent(this.props.userType, "PolicySelect", false, true)
    }

    clearExport = (status) => {
        if (status !== 'EXPIRED'){
            var active = "true"
            this.Vehicle.searchVehiclesForExport(this.props.policyId, '', '', active)
            .then((result) => {
                this.props.setExport(this.Vehicle.getExportData(result, this.props.userType, this.props.customerType))
            })
        }
        if (status === 'EXPIRED'){
            this.Vehicle.policyVehicleFullHistory(this.props.policyId)
                .then((result) => {
                    this.props.setExport(this.Vehicle.getExportData(result, this.props.userType, this.props.customerType))
                })
        }
    }

    clearExportForPolicySwitcher = (status, id) => {
        if (status !== 'EXPIRED'){
            var active = "true"
            this.Vehicle.searchVehiclesForExport(id, '', '', active)
            .then((result) => {
                this.props.setExport(this.Vehicle.getExportData(result, this.props.userType, this.props.customerType))
            })
        }
        if (status === 'EXPIRED'){
            this.Vehicle.policyVehicleFullHistory(id)
                .then((result) => {
                    this.props.setExport(this.Vehicle.getExportData(result, this.props.userType, this.props.customerType))
                })
        }
    }

    showExport = () => {
        this.setState({exportObject: true})        
    }

    switchPolicy = (e) => {
        this.Policy.getPolicyDetails(e)
        .then(res => {
            this.props.switchPolicy(e)
            this.clearExportForPolicySwitcher(res[0].status, res[0].policyId)
        })
        this.setState({exportObject: false})
    }

    render(){
        let subNavigationButton = 'button dashButton actionBtn'
        let mainNavigationButton = 'button dashButton'
        return (
            <>
            <div id="sideNav">
            <span className="closebtn" onClick={this.closeSideBar}>&times;</span>
                {this.props.policyResponse ?
                    <>
                    {this.props.policyChosen ?
                    <Button id="return" className={mainNavigationButton} onClick={() => {this.returnToSearch()}}>Return to Search</Button>
                    :null}
                    {this.props.showPolicySwitcher ?
                    <>
                        <PolicySwitcher returnToTable= {this.returnToTable} switchPolicy={this.switchPolicy} chosenPolicy={this.props.chosenPolicy} policyId={this.props.policyId} policies={this.props.policies}/>            
                    </>
                    :null}

                    {this.props.policyChosen || !this.props.userType ?
                    <PolicySidebar
                        userType={this.props.userType}
                        customerType={this.props.customerType}
                        policyResponse={this.props.policyResponse}
                        policyDetails={this.props.policyDetails}
                        policyStatus={this.props.policyStatus}
                        policyId={this.props.policyId}
                        numberOfVehiclesOnCover={this.props.numberOfVehiclesOnCover}
                        showComponent={this.props.showComponent}
                        currentPage={this.props.currentPage}
                        setPolicyState={this.props.setPolicyState}
                        activePolicyDetails={this.props.activePolicyDetails}
                        expiredPolicyDetails={this.props.expiredPolicyDetails}
                        renewalPolicyDetails={this.props.renewalPolicyDetails}
                        cancelledPolicyDetails={this.props.cancelledPolicyDetails}
                        deletedPolicyDetails={this.props.deletedPolicyDetails}
                        lapsePolicyDetails={this.props.lapsePolicyDetails}
                        policyState={this.props.policyState}
                        disableSwitchPeriods={this.props.disableSwitchPeriods}
                        closeSideBar={this.closeSideBar}
                        midRecordType={this.props.midRecordType}
                        setPolicyStatus={this.props.setPolicyStatus}
                        deactivePolicyDetails={this.props.deactivePolicyDetails}
                        midPolicyData={this.props.midPolicyData}
                        setData={this.props.setData}
                        clearExport={this.clearExport}
                    />:null}
                    </>
                :
                <>
                {this.props.customerType === 'SUPER' ?
                    <AdminCustomer authDetails={this.props.authDetails} currentPage={this.props.currentPage}/>
                :null}
                </>}

                {this.props.policyStatus === 'LIVE' || this.props.policyStatus === 'RENEWAL' || this.props.policyStatus === 'PENDING' || this.props.policyStatus === undefined  || this.props.policyStatus === '' ?
                <>
                <NavigationBar
                    userType={this.props.userType}
                    adminRole={this.props.adminRole}
                    readOnlyUser={this.props.readOnlyUser}
                    policyChosen={this.props.policyChosen}
                    showComponent={this.props.showComponent}
                    currentPage={this.props.currentPage}
                    navLinks={this.props.navLinks}
                    subNavLinks={this.props.subNavLinks}
                    handleLogout={this.props.handleLogout}
                    policyState={this.props.policyState}
                    vehicleBtn="Vehicle Admin"
                    policyAdminBtn="Policy Admin"
                    addVehicleBtn="Add vehicle"
                    returnToSearchBtn="Return to Search"
                    userAdminBtn="User Admin"
                    helpBtn="Need Help?"
                />
                </>
                :null}

                {this.props.currentPage !== 'PolicySelect' ?
                <>
                {this.props.currentPage === 'Table' || (!this.state.exportObject) ?
                    <div id="open-export" className="toggle-container">
                        <div className="toggle-header" onClick={this.showExport}>
                            <h2>Export Vehicles</h2>
                            {!this.state.exportObject ?
                            <span className="toggle-icon"><FaPlus /></span>
                            :null}
                        </div>
                    </div>
                :null}
                {this.state.exportObject ?
                <ExportVehicle
                    closeExport={this.props.showExportUi}
                    closeExportNotChosen={this.closeExport}
                    userType={this.props.userType}
                    policyChosen={this.props.policyChosen}
                    policyNumber={this.props.policyNumber}
                    chosenPolicy={this.props.chosenPolicy}
                    adminPolicyNumber={this.props.adminPolicyNumber}
                    exportData={this.props.exportData}
                />
                :null}
                </>
                :null}

                <ContactUs
                    title="Contact us:"
                    emailMethod="Email:"
                    email="fleetmid@aioinissaydowa.eu"
                    contactMethod="Telephone:"
                    telephone="0330 022 6513"
                    linesOpen="Lines are open for help between 08:30 to 17:30 - Monday to Friday"
                />
            <>
                        
            <Button id="logout" className={subNavigationButton} onClick={this.props.handleLogout}>Logout</Button>
                       
            </>
            </div>
            </>
        )
    }
}

export default Sidebar