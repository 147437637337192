import React from 'react'
import { FaPlus, FaMinus } from 'react-icons/fa'

const ToggleHeader = props => {

    return (
        <>
            <div id={'open'+props.type} className="toggle-container">
                <div className="toggle-header" onClick={() => {props.function()}}>
                    <h2>{props.header}</h2>
                    {props.variable ?
                    <span className="toggle-icon"><FaMinus/></span>
                    :<span className="toggle-icon"><FaPlus /></span>}
                </div>
            </div>
        </>
    )
}

export default ToggleHeader