import React, { Component } from 'react'
import axios from 'axios'
import AuthService from '../UserAdmin/services/AuthService'
import Util from '../../Util'
import * as Role from '../../resources/roles'
import * as Constants from '../../resources/index'
import * as Key from './resources/translations';
import * as Labels from '../../resources/labels'
import FieldSet from '../atoms/FieldSet';
import FormField from '../atoms/FormField'
import Button from '../atoms/Button'
import { FaWindowClose } from 'react-icons/fa';
import ComponentDisplayMessage from '../ComponentDisplayMessage'
const Auth = new AuthService();
const api = Auth.api
const UtilF = new Util();

class AddNewVehicle extends Component {
    constructor(props){
        super(props)
        this.state = {
            addVehicle: true,
            policyNumber: '',
            registrationNumber: '',
            foreignRegIndicator: false,
            foreignRegIndicatorBooleanValue: false,
            tradePlate: 'U',
            make: '',
            model: '',
            onCover: '',
            namedDriver: '',
            vehicleDerivative: '',
            offCover: this.props.policyExpiryDate,
            location: '',
            excludeFromMid: false,
            invalidReg: '',
            locations: this.props.locations,
            trailer: false
        }
    }

    componentDidMount(){
        if (!this.props.policyChosen && this.props.userType){
            this.setState({offCover: ''})
        } else {
            document.getElementById("offCover").value = this.state.offCover;
        }
        document.getElementById('tradePlate').value = "U"

        if (this.props.customerType !== Role.ICHIBAN_USER && this.props.customerType !== Role.TRAILER_USER){
            this.setState({fieldSet: Role.DEFAULT_POLICY_CHOSEN})
        }

        if (this.props.customerType === Role.ICHIBAN_USER){
            this.setState({fieldSet: Role.ICHIBAN})
        }

        if (this.props.customerType === Role.TRAILER_USER){
            this.setState({fieldSet: Role.TRAILER})
        }
    }

    showComponent = (component, sub) => {
        this.props.showComponent(this.props.userType, component, sub)
    }

    update = () => {
        if (this.props.policyChosen) {
            this.props.policyChosenUpdateMethod(this.state.newVehicleId, 'add')
         } else {
            this.props.updateMethod(this.state.newVehicleId, 'add')
         }
         
        this.showComponent('Table', false)
    }

    handleChange = (event) => {
        event.preventDefault();
        this.setState({formIssues: []})
        const { name, value } = event.target;
        if (name === 'foreignRegIndicator'){
            if (value === 'Yes'){
                document.getElementById("tradePlateReadOnly-read-only").innerHTML="U";
                document.getElementById("tradePlate-row").style.display = 'none'
                document.getElementById("tradePlateReadOnly-row").style.display = 'block'
                this.setState({foreignRegIndicatorBooleanValue: true, tradePlate: 'U'}, () => {
                    let basicValidTest = Auth.basicValidTest(this.state.registrationNumber.toUpperCase())
                    if (basicValidTest){
                        this.setState({validReg: true})
                    } else {
                        this.setState({validReg: false})
                    }
                })
            } else {
                this.setState({foreignRegIndicatorBooleanValue: false})
                document.getElementById("tradePlateReadOnly-row").style.display = 'none'
                document.getElementById("tradePlate-row").style.display = 'block'
            }
        }
        if (name === 'trailer'){
            if (value === 'Yes'){
                this.setState({trailer: true, excludeFromMid: true})
                let basicValidTest = Auth.basicValidTest(this.state.registrationNumber.toUpperCase())
                    if (basicValidTest){
                        this.setState({validReg: true})
                    } else {
                        this.setState({validReg: false})
                    }
            } else {
                this.setState({trailer: false})
            }
        }
        if (name === 'registrationNumber'){
            let regTest = Auth.regValidator(value.toUpperCase())
            let irelandTest = Auth.irishRegValidator(value.toUpperCase())
            let specialCheck = Auth.containsSpecialCharacters(value.toUpperCase())
            if (specialCheck){
                this.setState({validReg: true})
             if (regTest || irelandTest){
                    this.setState({validReg: true})
                } else {
                    this.setState({validReg: false})
                }
                if (irelandTest){
                    this.setState({irishReg: true, excludeFromMid: true})
                }
                if (!irelandTest){
                    this.setState({irishReg: false, excludeFromMid: false})
                }
                if (!regTest){
                    this.setState({irishReg: false})
                }
            }
            if (!specialCheck){
                this.setState({validReg: false})
            }

        }
        if (name === 'tradePlate'){
            if (value === 'T'){
                let basicValidTest = Auth.basicValidTest(this.state.registrationNumber.toUpperCase())
                if (basicValidTest){
                    this.setState({validReg: true})
                } else {
                    this.setState({validReg: false})
                }
            }
        }
        this.setState({[name]: value}, () => {})
    }

    checkForEmptyFields = (registrationNumber, startDate, endDate, namedDriver, vehicleDerivative) => {
        var fields = {registrationNumber:registrationNumber, tradePlate:this.state.tradePlate, onCover: startDate, offCover: endDate, namedDriver: namedDriver, vehicleDerivative: vehicleDerivative};
        this.setState({formIssues: UtilF.checkEmptyFields(fields, this.state.fieldSet)}, () => {
            for (var i = 0; i < this.state.formIssues.length; i++) {
                document.getElementById(this.state.formIssues[i]).classList.add('missing')
            }
            if(this.state.formIssues.length === 0){
                this.submit()
            }
        })
    }

    submitData = (event) => {
        event.preventDefault();
        let policyNumber
      
        if (!this.props.userType){
            policyNumber = this.props.newPolicyNumber
        }
        if (this.props.policyChosen){
            policyNumber = this.props.newPolicyNumber
            this.setState({policyNumber: policyNumber})
        }
        if (!this.props.userType && this.state.policyNumber === ''){
            this.setState({policyNumber: policyNumber})
        }
        this.setState({onCover:Auth.dateConvertor(this.state.onCover, 'add'), offCover: Auth.dateConvertor(this.state.offCover, 'add')})
        this.checkForEmptyFields(this.state.registrationNumber, this.state.onCover, this.state.offCover, this.state.namedDriver, this.state.vehicleDerivative)
    }

    submit = () => {
        let trailer
        if (this.state.trailer === 'Yes'){
            trailer = true
        } else if (this.state.trailer === 'No' || !this.state.trailer)  {
            trailer = false
        } 
        if (this.state.validReg){
            axios.post(`${api}/vehicle/`+this.props.policyId, {
                policyNumber: this.state.policyNumber,
                registration: this.state.registrationNumber.toUpperCase(),
                tradePlate: this.state.tradePlate,
                vehicleMake: this.state.make,
                vehicleModel: this.state.model,
                vehicleCoverStartDate: this.state.onCover,
                vehicleCoverEndDate: this.state.offCover,
                location: this.state.location,
                excludeFromMid: this.state.excludeFromMid,
                foreignRegistration: this.state.foreignRegIndicatorBooleanValue,
                namedDriver: this.state.namedDriver,
                vehicleDerivative: this.state.vehicleDerivative,
                trailer: trailer
            },
            {headers: { Authorization: Auth.getTokenForFrontEnd()}})
            .then(resp => {
                this.setState({addVehicle: false, success: true, errors: false, newVehicleId: resp.data.vehicleId, policyNumber: '', registrationNumber: '', tradePlate: '', onCover: '', offCover: '', make: '', model: '', location:''})
            })
            .catch(err => {
                this.setState({success: false,errors: true, addVehicleErrorCode: err.response.status, addNewVehicleMsg: err.response.data})
                if (this.state.addVehicleErrorCode !== 200 || this.state.addVehicleErrorCode !== 500){
                    this.setState({addVehicleError: err.response.data})
                }
                if (this.state.addVehicleErrorCode === 500){
                    this.setState({addVehicleError: Constants.DEFAULT_ERROR})
                }
            })
        } else { window.alert('Invalid Registration Number')}
    }

    render(){
        let locationOptions = ''
        if (this.props.locations){
            let locations = this.props.locations;
            locationOptions = locations.map((location) =>
                    <option key={location}>{location}</option>
                );
        }
        let regIcon
        if (this.state.validReg){
            regIcon += ' valid-reg'
        }
        if (this.state.validReg !== undefined && this.state.validReg !== true){
            regIcon += ' invalid-reg'
        }

        if (this.state.validReg === undefined){
            regIcon += ' empty-reg'
        }
        return (
            <div>
            {this.state.addVehicle || this.props.addVehicle ?
                <>
                <h1 className="header">{Key.VEH_ADD_VEHICLE_TITLE}</h1>
                <span id="close_amend" className="close-icon" onClick={() => {this.showComponent('Table', false)}}><FaWindowClose />CLOSE</span>
                
                <form>

                    {(!this.props.userType && this.props.customerType !== Role.ICHIBAN_USER && this.props.customerType !== Role.TRAILER_USER) || (this.props.policyChosen && this.props.customerType !== Role.ICHIBAN_USER && this.props.customerType !== Role.TRAILER_USER) ?
                        <FieldSet
                            data={Role.DEFAULT_POLICY_CHOSEN}
                            onChange={this.handleChange}
                            validReg={this.state.validReg}
                            iconClass={regIcon}
                        />
                    :null}

                    {this.props.customerType === Role.ICHIBAN_USER ?
                        <FieldSet
                            data={Role.ICHIBAN}
                            onChange={this.handleChange}
                            validReg={this.state.validReg}
                            iconClass={regIcon}
                        />                        
                    :null}

                    {this.props.customerType === Role.TRAILER_USER ?
                       <FieldSet
                            data={Role.TRAILER}
                            onChange={this.handleChange}
                            validReg={this.state.validReg}
                            iconClass={regIcon}
                        />                         
                    :null}

                    {(this.props.policyChosen || !this.props.userType) && this.props.customerType === Role.ICHIBAN_USER}

                        {/* refine below code */}
                        {!this.props.userType ?
                        <div className="row">
                            <div className="col-50">
                                <label>{Labels.LOCATION}</label>
                            </div>
                            <div className="col-75">
                            <input list="locations" name="location" id="location-list" value={this.state.location} onChange={this.handleChange}/>
                                <datalist id="locations">
                                    {locationOptions}
                                </datalist>
                            </div>

                        </div> 
                        :
                        <FormField
                        type="text"
                        id="location"
                        value={this.state.location}
                        onChange={this.handleChange}
                        label={Labels.LOCATION}
                        />
                        }
                        <Button className="button" id="submitNewVehicle" onClick={this.submitData}>{Key.VEH_NEW_VEHICLE_BUTTON}</Button>
                        </form>
                        </>
            :null}

            <ComponentDisplayMessage
                error={this.state.errors}
                success={this.state.success}
                successMessage="Vehicle Added Successfully"
                errorMessage={this.state.addVehicleError}
            />

            {this.state.success ?
            <>
                <Button className="button" onClick={this.update}>Return to Vehicle List</Button>
                </>
            :null}

            </div>
        )
    }
}

export default AddNewVehicle