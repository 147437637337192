import React ,{ Component } from 'react'
import * as Table from '../../resources/tableFile'
import OTable from '../atoms/OTable'

class PolicySelect extends Component {
    constructor(props){
        super(props)
        this.selectPolicy = this.selectPolicy.bind(this);
        this.state = {
            policies: this.props.policies.sort((a,b) => (a.policyNumber > b.policyNumber) ? 1 : ((b.policyNumber > a.policyNumber) ? -1 : 0))
        }
    }

    selectPolicy(data) {
        this.props.showPolicy(data.policyNumber, data.policyId)
    }
      
    render(){
        return (
            <>
                <p>Below is a list of policies that you are setup to manage, to update this list, please get in touch with an administrator.</p>
                <div className="section">
                    <OTable data={this.state.policies} tableHeaders={Table.POLICY_SELECT} action={this.selectPolicy.bind(this)} />
                </div>
            </>
        )
    }
}

export default PolicySelect
