import React from 'react';

const Label = (props) => {
    return (
        <label htmlFor={props.for}>
            {props.children}
        </label>
    )
}

export default Label