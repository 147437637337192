import React, { Component } from 'react'
import Button from './atoms/Button'
import * as Role from '../resources/roles'

class NavigationBar extends Component {

    showComponent = (component, sub, openPage) => {
        if (component !== 'Search' && component !== 'MidTable') {
            if (document.getElementById(this.props.currentPage) !== null){
                document.getElementById(this.props.currentPage).classList.remove('nav-selected')
            }
            document.getElementById(component).classList.add('nav-selected')
        }
        if (component === 'CreateSuperCustomer' || component === 'CreateAdmin' || component === 'ResetPIN' || component === 'CreatePIN'){
            document.getElementById('UserAdminNav').classList.add('nav-selected')
        }
        if (component === 'Search'){
            document.getElementsByClassName('nav-selected')[0].classList.remove('nav-selected')
        }
        this.props.showComponent(this.props.userType, component, sub, openPage)
    }

    render(){
        let navigationBar = 'navigation-container'
        let actionButtonContainer = 'action-navigation-container'
        let mainNavigationButton = 'button dashButton'
        let subNavigationButton = 'button actionBtn'
        if (this.props.policyChosen) {
            navigationBar += ' policy-chosen'
        }
        if (!this.props.policyChosen && this.props.userType){
            navigationBar += ' not-chosen-nav'
        }
        let mainNavigationButtonUW = 'button dashButton actionBtn UW'
        return (
            <>
            <div className={navigationBar}>
                {/* ADMIN WITHOUT POLICY CHOSEN */}
                {this.props.userType && !this.props.policyChosen ?
                    <>
                    {this.props.currentPage !== 'Search' && this.props.currentPage !== 'UserAdminNav' ?
                        <Button className={ this.props.adminRole === Role.UNDERWRITER ? mainNavigationButtonUW : mainNavigationButton} onClick={() => {this.showComponent("Search", false, true)}}>Return to Search</Button>
                    :null}
                    {this.props.navLinks ?
                        <>
                        {this.props.navLinks.map(link => <Button id={link.id} className={this.props.adminRole === Role.UNDERWRITER ? mainNavigationButtonUW : mainNavigationButton} onClick={() => {this.showComponent(link.id, link.open, link.sub)}}>{link.name}</Button>)}
                        </>
                    :null}
                    </>
                :null}
                <div className={actionButtonContainer}>
                        {this.props.subNavLinks ?
                            <>
                                {this.props.subNavLinks.map(link => <Button id={link.id} className={subNavigationButton} onClick={() => {this.showComponent(link.id, link.open, link.sub)}}>{link.name}</Button>)}                            
                            </>
                        :null}
                </div>

                {/* ADMIN POLICY SELECTED && CUSTOMER */}
                {this.props.navLinks ?
                <>
                <div className={actionButtonContainer}>
                    {this.props.adminRole !== Role.UNDERWRITER ?
                    <>
                    {(this.props.userType && this.props.policyChosen && this.props.policyStatus !== 'EXPIRED' || this.props.userType && this.props.policyChosen && this.props.policyStatus !== 'DELETED' || this.props.userType && this.props.policyChosen && this.props.policyStatus !== 'LAPSED' || this.props.userType && this.props.policyChosen && this.props.policyStatus !== 'CANCELLED') || !this.props.userType && !this.props.readOnlyUser && this.props.currentPage !== 'PolicySelect' || !this.props.userType && this.props.readOnlyUser === undefined && this.props.currentPage !== 'PolicySelect' ? 
                    <>
                        {this.props.navLinks.map(link => <Button id={link.id} className={subNavigationButton} onClick={() => {this.showComponent(link.id, link.open, link.sub)}}>{link.name}</Button>)}
                    </>
                    :null}
                    </>
                    :null}
                </div>
                </>
                :null}

            </div>
            </>
        )
    }
}

export default NavigationBar