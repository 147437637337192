import React from 'react'
import { ExportReactCSV } from '../Vehicle/export/ExportReactCSV'

const ExportPolicyLog = props => {
    return (
        <>
        {props.logData ?
            <ExportReactCSV csvData={props.logData} fileName="Policy-Report" /> 
        :null}
        </>
    )
}

export default ExportPolicyLog