import React, {Component} from 'react'
import { FaPlus } from 'react-icons/fa'
import { FaMinus } from 'react-icons/fa'

class HelpItem extends Component {
    constructor(props){
        super(props)
        this.state = {
            toggleOpen: false
        }
    }

    toggleItem = () => {
        this.setState({toggleOpen: !this.state.toggleOpen})
    }

    render(){
        return (
            <>
                <div className="toggle-container">
                    <div className="toggle-header" onClick={this.toggleItem}>
                        <h2>{this.props.title}</h2>
                        {this.state.toggleOpen ?
                        <span className="toggle-icon"><FaMinus/></span>
                        :<span className="toggle-icon"><FaPlus /></span>}
                    </div>
                    {this.state.toggleOpen ?
                    <>
                        <p>{this.props.content1}</p>
                        {this.props.subtitle1 ?
                            <p><b>{this.props.subtitle1}</b></p>
                        :null}
                        <p>{this.props.content2}</p>
                        {this.props.image1 ?
                            <img src={this.props.image1} alt="one"/>
                        :null}
                        {this.props.subtitle2 ?
                            <p><b>{this.props.subtitle2}</b></p>
                        :null}
                        <p>{this.props.content3}</p>
                        {this.props.image2 ?
                            <img src={this.props.image2} alt="two"/>
                        :null}
                    </>
                    :null}
                </div>
            </>
        )
    }
}

export default HelpItem