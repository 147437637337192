export const POLICY_NUMBER = {
    "key": 0,
    "title": "Policy Number",
    "dataKey": "policyNumber"
}

export const POLICY_HOLDER_NAME = {
    "title": "Policyholder",
    "dataKey": "policyHolderName"
}

export const POLICY_HOLDER_NAME_SELECT = {
    "title": "PolicyHolder",
    "dataKey": "policyHolder"
}

export const REGISTRATION_NUMER = {
    "key": 1,
    "title": "Registration",
    "dataKey": "registration"
}

export const TRADE_PLATE = {
    "key": 2,
    "title": "Trade Plate",
    "dataKey": "tradePlate"
}

export const MAKE = {
    "key": 3,
    "title": "Make",
    "dataKey": "vehicleMake"
}

export const MODEL = {
    "key": 4,
    "title": "Model",
    "dataKey": "vehicleModel"
}

export const ON_DATE = {
    "key": 5,
    "title": "On Date",
    "dataKey": "vehicleCoverStartDate"
}

export const OFF_DATE = {
    "key": 6,
    "title": "Off Date",
    "dataKey": "vehicleCoverEndDate"
}

export const LOCATION = {
    "key": 7,
    "title": "Location",
    "dataKey": "location"
}

export const MID_STATUS = {
    "key": 8,
    "title": "MID Status",
    "dataKey": "midStatus"
}

export const LAST_UPDATED = {
    "key": 9,
    "title": "Last Updated",
    "dataKey": "lastUpdated"
}

export const ACTIVE = {
    "key": 10,
    "title": "Active",
    "dataKey": "active"
}

export const POLICY_START_DATE = {
    "title": "On Date",
    "dataKey": "policyCoverStartDate"
}

export const POLICY_END_DATE = {
    "title": "Off Date",
    "dataKey": "policyCoverEndDate"
}

export const TERM = {
    "title": "Term",
    "dataKey": "policyTerm"
}

export const PPCC = {
    "title": "PPCC",
    "dataKey": "partyPolicyControlCount"
}

export const REASON_CODE = {
    "title": "Reason",
    "dataKey": "reportIndicator"
}

export const UPDATE_TYPE = {
    "title": "Update Type",
    "dataKey": "recordType"
}

export const FILE_SEQUENCE_NUMBER = {
    "title": "FSN",
    "dataKey": "midFileSeqNo"
}

export const ACTIVE_VEHICLES = {
    "title": "Active Vehicles",
    "dataKey": "active"
}

export const LAST_ACTIVITY = {
    "title": "Last Activity",
    "dataKey": "latestLogin"
}

export const NUMBER_OF_LOGINS = {
    "title": "Number of times logged in",
    "dataKey": "activityCount"
}

export const POLICY_LOG_COVER_START = {
    "title": "Policy Cover Start",
    "dataKey": "coverStart"
}

export const POLICY_LOG_COVER_END = {
    "title": "Policy Cover End",
    "dataKey": "coverEnd"
}

export const EFFECTIVE_DATE = {
    "title": "Start Date",
    "dataKey": "effectiveDate"
}

export const EXPIRY_DATE = {
    "title": "End Date",
    "dataKey": "expiryDate"
}