import React, {Component} from 'react';
import './App.css';
import './Button.css'
import axios from 'axios'
import AuthService from './components/UserAdmin/services/AuthService'
import PolicyService from './components/Policy/services/PolicyService'
import VehicleService from './components/Vehicle/services/VehicleService'
import withAuth from './components/UserAdmin/withAuth'
import Util from './Util'
import Header from './components/Header'
import VehicleAdmin from './components/Vehicle/VehicleAdmin'
import UserAdmin from './components/UserAdmin/UserAdmin'
import DisplayBlock from './components/DisplayBlock';
import HelpSection from './components/Help/HelpSection';
import PolicySearch from './components/Policy/PolicySearch';
import Sidebar from './components/Sidebar';
import throbber from './images/throbber.gif'
import PolicyAdmin from './components/Policy/PolicyAdmin';
import * as Constants from './resources/index';
import PolicyEdit from './components/Policy/PolicyEdit';
import ExpiredVehicles from './components/Vehicle/ExpiredVehicles';
import RenewalVehicles from './components/Vehicle/RenewalVehicles'
import PortalShutDown from './components/PortalShutDown'
import PolicySelect from './components/Policy/PolicySelect';
const Auth = new AuthService();
const api = Auth.api

class App extends Component {
  constructor(props){
    super(props)
    this.getPolicyDetails = this.getPolicyDetails.bind(this);
    this.getRejectedVehicles = this.getRejectedVehicles.bind(this);
    this.getLocations = this.getLocations.bind(this);
    this.searchForPolicyVehicle = this.searchForPolicyVehicle.bind(this);
    this.policyVehicleUpdate = this.policyVehicleUpdate.bind(this);
    this.Policy = new PolicyService();
    this.Vehicle = new VehicleService();
    this.Util = new Util();
    this.state = {
      policyChosen: false,
      vehicleAdmin: false,
      userAdmin: false,
      helpSection: false,
      policyNumber: '',
      policyResponse: '',
      vehicleResults: '',
      vehicleResponse: '',
      searchPolicy: true,
      showErrors: false,
      showSuccess: false,
      amendUI: false,
      newVehicleId: '',
      addVehicle: false,
      vehicleAdded: false
    }
  }

  // functions called on load
  componentDidMount(){
    this.Util.logoutOnRefresh(process.env.NODE_ENV)
    if (process.env.NODE_ENV === 'production'){
      if (!this.Util.checkOpeningTimes()){
          this.setState({shutDownWarning: true})
      }
    }

    if (!this.Util.getUserType(this.props)){
      if (this.Policy.checkPolicyType(this.props) !== 'SUPER') {
        this.getPolicyDetails(this.props.user.sub)
        this.getLocations(this.props.user.sub)
        this.setState({customerType: this.Util.getCustomerType(localStorage), readOnlyUser: this.Util.isReadOnlyUser(localStorage), navLinks: this.Util.getNavigation(this.Util.getUserType(this.props), this.state.policyChosen, this.state.currentPage)})
      } else {
        this.setState({policies: this.props.pol, adminCustomerName: localStorage.getItem('name'), customerType: 'SUPER', navLinks: this.Util.getNavigation(this.Util.getUserType(this.props))})
        this.showComponent(this.props.userType, 'PolicySelect', false, false)
      }
        
    }

    if (this.Util.getUserType(this.props)){
      this.setState({navLinks: this.Util.getNavigation(this.Util.getUserType(this.props), this.state.policyChosen, this.state.currentPage) }, () => {
      })
      this.getRejectedVehicles()
      this.getRejectedPolicies()
      
      this.setState({adminRole: localStorage.getItem('role'), currentPage: 'Search', userType: true})
    }
  }

  showPolicy = (policyNumber, id) => {
    this.Policy.getPolicyDetails(policyNumber)
    .then(res => {
      const compareData = res.sort((a,b) => (a.activeFlag > b.activeFlag) ? 1 : ((b.activeFlag > a.activeFlag) ? -1 : 0));
      localStorage.setItem('policyID', compareData[0].policyId)
      this.searchForPolicyVehicle(policyNumber, localStorage.getItem('policyID'))
      this.setState({showPolicySwitcher: true, chosenPolicy: policyNumber, policyResponse: res, policyDetails: this.Policy.sortPolicyDetails(res), newMidPolicyData: this.Policy.getMidPolicyData(res), midPpcc: compareData[0].partyPolicyControlCount,
        midRecordType: compareData[0].recordType, midPolicyStatus: compareData[0].midStatus, midFSN: compareData[0].midFileSeqNo,
        policyExpiryDate: compareData[0].policyCoverEndDate, numberOfPeriods: res.length, cancelFlag: compareData[0].cancellationLapseInd}, () => {
        if (!this.Util.getUserType(this.props)){
          this.setState({policyState: compareData[0].activeFlag, policyId: compareData[0].policyId, policyStatus: compareData[0].status})
        }
      })

      if (compareData[0].status !== 'EXPIRED'){
        this.setState({currentPage: 'Table'})
      }
      if (compareData[0].status === 'EXPIRED'){
        this.setState({currentPage: 'ExpiredVehicles'})
      }
      
    })
  }

  // brings back policy details for policy number logged in with
  getPolicyDetails = (data) => {
    let policyNumber = ''
    if (this.Util.getUserType(this.props)){
      policyNumber = data
    } else {
      policyNumber = this.props.user.sub
    }
    this.Policy.getPolicyDetails(policyNumber)
    .then(res => {
      const compareData = res.sort((a,b) => (a.activeFlag > b.activeFlag) ? 1 : ((b.activeFlag > a.activeFlag) ? -1 : 0)); 
      localStorage.setItem('policyID', compareData[0].policyId)
      if (!this.Util.getUserType(this.props)){
        this.searchForPolicyVehicle(this.props.user.sub, localStorage.getItem('policyID'))
      }
      this.setState({policyResponse: res, policyDetails: this.Policy.sortPolicyDetails(res), newMidPolicyData: this.Policy.getMidPolicyData(res), midPpcc: compareData[0].partyPolicyControlCount,
        midRecordType: compareData[0].recordType, midPolicyStatus: compareData[0].midStatus, midFSN: compareData[0].midFileSeqNo,
        policyExpiryDate: compareData[0].policyCoverEndDate, numberOfPeriods: res.length, cancelFlag: compareData[0].cancellationLapseInd}, () => {
        if (!this.Util.getUserType(this.props)){
          this.setState({policyState: compareData[0].activeFlag, policyId: compareData[0].policyId, policyStatus: compareData[0].status})
        }
      })

      this.setState({midPolicyData: {
        policyNumber: this.state.searchedPolicyNumber,
        midFileSeqNo: this.state.midFSN,
        partyPolicyControlCount: this.state.midPpcc,
        recordType: this.state.midRecordType,
        registration: '',
        midStatus: this.state.midPolicyStatus,
        }
      })
        if (res.status !== 'EXPIRED'){
          this.setState({currentPage: 'Table'})
        }
        if (res.status === 'EXPIRED'){
          this.setState({currentPage: 'ExpiredVehicles'})
        }
      })
    .catch(err => {
      this.setState({showErrors: true})
    })
  }

  resetVehicleAdded = () => {
    this.setState({newVehicleId: '', vehicleAdded: false})
  }

  // brings back vehciles links to the customers policy number
  searchForPolicyVehicle = (data, id) => {
    this.Vehicle.policyVehicleSearch(id)
    .then(res => {
      this.setState({vehicleResponse: res, numberOfVehiclesOnCover: res.length})
      this.setState({vehicleAdmin: true})
    })
    .catch(err => {
      if (err.response !== undefined){
        this.setState({vehiclePolicyError: true})
        if (this.state.vehiclePolicyError){
          if (err.response.data.includes("Couldn't find any vehicles")){
            this.setState({showErrors: true, noVehiclesOnPolicyError: true})
          } else {
            this.setState({showErrors: true})
          }
        }
      }
    })
  }

  // this is used after a policy has been selected and a vehicle gets added
  policyVehicleUpdate = (id, method) => {
    if (method === 'add'){
      this.setState({newVehicleId: id, vehicleAdded: true})
    }
  
    let active
    
    if (this.state.active === undefined){
      active = true
    } else {
      if (this.state.active){
        active = true
      }
      if (!this.state.active) {
        active = false
      }
    }

    this.Vehicle.policyVehicleUpdate(this.state.policyId, active)
    .then(res => {
      this.setState({vehicleResponse: res, numberOfVehiclesOnCover: res.length})
    })
    .catch(err => {
      this.setState({vehiclePolicyError: true})
      if (this.state.vehiclePolicyError){
        if (err.response.data.includes("Couldn't find any vehicles")){
          this.setState({showErrors: true, noVehiclesOnPolicyError: true})
        } else {
          this.setState({showErrors: true})
        }
      }
    })
  }
  
  getRejectedVehicles = () => {
    if (this.Util.getUserType(this.props)){
      this.Vehicle.getRejectedVehicles()
      .then(res => {
          this.setState({vehicleResponse: res, numberOfVehiclesOnCover: res.length, noRejectedVehicles: false})
      })
      .catch(err => {
        if (err.status === Constants.NOT_FOUND){
          this.setState({noRejectedVehicles: true})
        }
      })
    }
  }

  getRejectedPolicies(){
    if (this.Util.getUserType(this.props)){
      this.Policy.getRejectedPolices()
      .then(res => {
          this.setState({rejectedPolicies: res, noRejectedPolicies: false})
      },) 
      .catch(error => {
          this.setState({noRejectedPolicies: true})
      })
    }
  }

  getLocations = (policyNumber) => {
    this.Vehicle.getLocations(policyNumber)
    .then((result) => {
      this.setState({locations: result})
    })
  }

  showComponent = (userType, component, sub, openPage) => {
    this.setState({currentPage: component, subPage: sub}, () => {
      if (this.state.currentPage === 'Search' && !this.state.policyChosen){
            this.setState({searchedPolicyNumber: '', cancelFlag: '', policyStatus: '', exportObject: '', })
          }
      if (this.state.currentPage === 'Search' && this.state.policyChosen){
          this.setState({policyChosen: false, policyStatus: '', cancelFlag: '', searchVehicleData: '', refinedData: '', showErrors: false, showSuccess: false, vehicleResponse: ''}, () => {
            this.setState({ navLinks: this.Util.getNavigation(this.Util.getUserType(this.props), this.state.policyChosen, this.state.currentPage)})
          })
      }
      if (this.state.currentPage === 'Rejected' && !this.state.policyChosen){
        if (!this.state.policyChosen){
          this.getRejectedVehicles()
        } else {
        if (!this.state.showPolicy){
          this.setState({showPolicy: true})
        }
        }
      }
      if (this.state.currentPage === 'UserAdminNav'){
        this.setState({subNavLinks: this.Util.getNavigation(this.Util.getUserType(this.props), this.state.policyChosen, this.state.currentPage)})
      } else {
        this.setState({subNavLinks: ''})
      }
      if (this.state.currentPage === 'PolicySelect'){
        this.setState({chosenPolicy: '', exportData: '', vehicleResponse: '', policyResponse: ''})
      }
      if (openPage){
        document.getElementById("sideNav").style.width = "0px";
        document.getElementById('main-view').classList.remove("untouchable")
        
      }
    })
  }

  // customer add vehicle
  showAddVehicleUi = () => {
    this.setState({addVehicle: !this.state.addVehicle, refineVehicles: false, vehicleSearch: false})
  }

  updateTableMethod = (data, refinedData, active) => {
    this.setState({searchVehicleData: data, refinedData: refinedData, active: active, searched: true})
  }

  callbackFunction = (data, id, flag, status) => {
    this.setState({searchedPolicyNumber: data, policyChosen: true, showPolicy: true, vehicleResponse: '', chosenPolicyActiveFlag: flag, policyState: flag, policyId: id, policyStatus: status}, () => [
      this.setState({navLinks: this.Util.getNavigation(this.Util.getUserType(this.props), this.state.policyChosen, this.state.currentPage)}),
      this.revertSetCancelledTableView(),
      this.getPolicyDetails(data),
      this.decideOnView(status, id, data),
      Auth.getCustomerType(data)
      .then(res => {
        if (res !== undefined){
          this.setState({customerType: res})
        } else {
          this.setState({customerType: ''})
        }
      })
      .catch(error => {
        console.log(error)
      })
    ])
  }

  decideOnView = (status, id, data) => {
    if (status === 'EXPIRED'){
      this.Vehicle.policyVehicleSearchExpired(id)
        .then((result) => {
          this.setState({expiredCars: result})
          this.setExpiredVehicles(this.state.expiredCars)
          this.showComponent(this.props.userType, 'ExpiredVehicles', false, false) 
        })
    }
    if (status === 'CANCELLED' || status === 'LAPSED' || status === 'DELETED' || status === 'DEACTIVATED'){
      this.searchForNonActiveVehicles(id, status)
    }
    else {
      this.searchForPolicyVehicle(data, id)
    }
  }

  searchForNonActiveVehicles = (id, status) => {
    this.Vehicle.policyVehicleSearchInactive(id)
    .then((result) => {
      this.setData(status, id, result, result.length, '', 'Table', [])
    }) 
  }
   
  activeFunction = (location, registration, active) => {
    if (active){
      this.Vehicle.searchVehiclesFalse(this.state.policyId, location, registration)
      .then((result) => {
        this.setVehicles(result, location, registration)
      })
    } else {
      this.Vehicle.searchVehiclesTrue(this.state.policyId, location, registration)
        .then((result) => {
        this.setVehicles(result, location, registration)
        })
    }
    
  }

  closeMessage = () => {
    this.setState({showErrors: false, showSuccess: false})
  }

  // customer import
  customerImportUi = () => {
    this.setState({customerImport: !this.state.customerImport, removeVehicles: false})
  }
  // customer remove
  showRemoveVehicles = () => {
    this.setState({removeVehicles: !this.state.removeVehicles, customerImport: false})
  }

  closeCustomerImport = () => {
    this.setState({customerImport: false})
  }

  closeCustomerRemoveImport = () => {
    this.setState({removeVehicles: false})
  }

  closeAdminImports = () => {
    this.setState({currentPage: 'Table'})
  }

  // customer amend
  amendUiState = () => {
    this.setState({amendUI: true})
    this.resetVehicleAdded()
    if (this.state.amendUI){
      this.setState({amendUI: false})
    }
  }

  // policy import toggle messages
  toggleMessages = () => {
    this.setState({displayMessages: !this.state.displayMessages})
  }

  setExpiredVehicles = (data) => {
    this.setState({expiredVehicles: data, cancelledPolicy: false})
  }

  getRenewalVehicles = (data) => {
    this.setState({vehicleResponse: data})
  }

  setPolicyId = (id) => {
    this.setState({policyId: id})
  }

  setPolicyState = (state) => {
    this.setState({policyState: state})
  }

  setVehicles = (data, location, registration) => {
    this.setState({vehicleResponse: data, numberOfVehiclesOnCover: data.length, searchReg: registration, searchLocation: location})
  }

  disableSwitchPeriods = () => {
    this.setState({disableSwitchPeriods: true})
  }

  enableSwitchPeriods = () => {
    this.setState({disableSwitchPeriods: false})
  }

  updateRejectedPolicies = () => {
    axios.get(`${api}/policy/rejected`,{
      headers: { Authorization: Auth.getTokenForFrontEnd()}
    })
    .then(res => {
        this.setState({rejectedPolicies: res.data, noRejectedPolicies: false})
    },) 
    .catch(error => {
        this.setState({noRejectedPolicies: true})
    })
  }

  setCancelledTableView = () => {
    this.setState({cancelledView: true})
  }

  revertSetCancelledTableView = () => {
    this.setState({cancelledView: false})
  }

  setPolicyStatus = (status) => {
    this.setState({policyStatus: status})
  }

  exportFunction = (data) => {
    this.setState({exportData: data})
  }

  // called when changing policy period in the sidebar
  setData = (status, id, res, noOfVehicles, expiry, page, expiredCars) => {
    let cancelledView
    if (status === 'DELETED' || status === 'CANCELLED' || status === 'DEACTIVATED' || status === 'LAPSED'){
      cancelledView = true
    } else { cancelledView = false}
    this.setState({policyId: id, policyExpiryDate: expiry.policyCoverEndDate, vehicleResponse: res, numberOfVehiclesOnCover: noOfVehicles, cancelledView: cancelledView, expiredVehicles: expiredCars, expiredCars: expiredCars}, () => {
      this.showComponent(this.Util.getUserType(this.props), page, false, false)
    })
    
  }
  render(){
    return (
      <>
      <div id="portalApp">
          <Sidebar
            showComponent={this.showComponent}
            userType={this.Util.getUserType(this.props)}
            currentPage={this.state.currentPage}
            adminRole={this.state.adminRole}
            readOnlyUser={this.state.readOnlyUser}
            customerType={this.state.customerType}
            navLinks={this.state.navLinks}
            subNavLinks={this.state.subNavLinks}
            policyResponse={this.state.policyResponse}
            policyDetails={this.state.policyDetails}
            policyStatus={this.state.policyStatus}
            policyId={this.state.policyId}
            numberOfVehiclesOnCover={this.state.numberOfVehiclesOnCover}
            policyChosen={this.state.policyChosen}
            handleLogout={this.handleLogout.bind(this)}
            policyNumber={this.props.user.sub}
            adminPolicyNumber={this.state.searchedPolicyNumber}
            policyId={this.state.policyId}
            vehicleResults={this.state.vehicleResponse}
            activePolicyDetails={this.state.activePolicyDetails}
            expiredPolicyDetails={this.state.expiredPolicyDetails}
            renewalPolicyDetails={this.state.renewalPolicyDetails}
            cancelledPolicyDetails={this.state.cancelledPolicyDetails}
            deletedPolicyDetails={this.state.deletedPolicyDetails}
            lapsePolicyDetails={this.state.lapsePolicyDetails}
            deactivePolicyDetails={this.state.deactivePolicyDetails}
            policyState={this.state.policyState}
            midRecordType={this.state.midRecordType}
            midPolicyData={this.state.midPolicyData}
            setPolicyState={this.setPolicyState}
            disableSwitchPeriods={this.state.disableSwitchPeriods}
            setPolicyStatus={this.setPolicyStatus}
            exportData={this.state.exportData}
            setData={this.setData}
            setExport={this.exportFunction}
            showPolicySwitcher={this.state.showPolicySwitcher}
            switchPolicy={this.showPolicy}
            policies={this.state.policies}
            chosenPolicy={this.state.chosenPolicy}
            adminCustomerName={this.state.adminCustomerName}
            authDetails={this.props}
          />

          <Header setExport={this.exportFunction}
                  customerType={this.state.customerType}
                  expiredVehicles={this.state.expiredVehicles}
                  currentPage={this.state.currentPage}
                  policyId={this.state.policyId}
                  showNav={true} userType={this.Util.getUserType(this.props)}
                  policyChosen={this.state.policyChosen}
                  policyStatus={this.state.policyStatus} 
          />

        {this.Util.getUserType(this.props) ?
        // admin display
          <>
            {!this.state.policyChosen ?
              <>

              <div className="section" id="main-view">
                {this.state.currentPage === 'Search' || this.state.currentPage === 'UserAdminNav' ?
                <>
                  <PolicySearch confirmSearch={this.callbackFunction}/>
                  </>
                : null}
                {this.state.currentPage === 'Rejected' || this.state.subPage ?
                <VehicleAdmin
                  showComponent={this.showComponent} currentPage={this.state.currentPage} subPage={this.state.subPage} 
                  policyExpiryDate={this.state.policyExpiryDate} vehicleResults={this.state.vehicleResponse} rejectedPolicies={this.state.rejectedPolicies} userType={this.Util.getUserType(this.props)} 
                  numberOfVehiclesOnCover={this.state.numberOfVehiclesOnCover} updateMethod={this.getRejectedVehicles} 
                  policyChosenUpdateMethod={this.searchForPolicyVehicle} policyNumber={this.state.searchedPolicyNumber}
                  locations={this.state.locations} 
                  updateTableMethod={this.updateTableMethod} searchVehicleData={this.state.searchVehicleData} 
                  amendUiState={this.amendUiState} amendUI={this.state.amendUI} resetVehicleAdded={this.resetVehicleAdded}
                  closeAdminImports={this.closeAdminImports} noRejectedVehicles={this.state.noRejectedVehicles} noRejectedPolicies={this.state.noRejectedPolicies} policyId={this.state.policyId} numberOfPeriods={this.state.numberOfPeriods}
                  updateRejectedPolicies={this.updateRejectedPolicies} policyStatus={this.state.policyStatus}
                />
                : null}

                
                <UserAdmin currentPage={this.state.currentPage} />
                {this.state.currentPage === 'PolicyAdmin' ?
                  <PolicyAdmin
                      currentPage={this.state.currentPage} 
                      policyChosen={this.props.policyChosen}
                      closeImportUis={this.props.closeImportUis}
                      displayMessages={this.state.displayMessages}
                      toggleMessages={this.toggleMessages} 
                  />
                :null}

                
                {this.state.currentPage === 'Help' ?
                  <HelpSection />
                : null}
                </div>
                </>
            :null}
            
            {this.state.policyChosen ?
            <>
              <div className="section" id="main-view">
 
                <VehicleAdmin
                  currentPage={this.state.currentPage}
                  userType={this.Util.getUserType(this.props)}
                  adminRole={this.state.adminRole}
                  customerType={this.state.customerType}
                  policyExpiryDate={this.state.policyExpiryDate}
                  locations={this.state.locations}
                  amendUI={this.state.amendUI}
                  amendUiState={this.amendUiState}
                  vehicleResults={this.state.vehicleResponse}
                  searchVehicleData={this.state.searchVehicleData}
                  updateMethod={this.getRejectedVehicles}
                  numberOfVehiclesOnCover={this.state.numberOfVehiclesOnCover}
                  policyChosenUpdateMethod={this.policyVehicleUpdate}
                  policyChosen={this.state.policyChosen}
                  policyNumber={this.state.searchedPolicyNumber}
                  policyId={this.state.policyId}
                  policyState={this.state.policyState}
                  newVehicleId={this.state.newVehicleId}
                  vehicleAdded={this.state.vehicleAdded}
                  resetVehicleAdded={this.resetVehicleAdded}
                  updateTableMethod={this.updateTableMethod}
                  showComponent={this.showComponent}
                  changedPeriod={this.state.changedPeriod}
                  stopChangedPeriod={this.stopChangedPeriod}
                  numberOfPeriods={this.state.numberOfPeriods}
                  disableSwitchPeriods={this.disableSwitchPeriods}
                  enableSwitchPeriods={this.enableSwitchPeriods}
                  searched={this.state.searched}
                  cancelledView={this.state.cancelledView}
                  policyStatus={this.state.policyStatus}
                  activeFunction={this.activeFunction}
                  searchReg={this.state.searchReg}
                  searchLocation={this.state.searchLocation}
                  midPolicyData={this.state.midPolicyData}
                />

              {this.state.currentPage === 'PolicyEdit' ?
              <>
                <PolicyEdit 
                  policyNumber={this.state.policyNumber}
                  policyHolder={this.state.policyHolder}
                  policyExpiryDate={this.state.policyExpiryDate}
                  policyStartDate={this.state.policyStartDate}
                  midPpcc={this.state.midPpcc}
                />
              </>
              :null}

              {this.state.currentPage === 'ExpiredVehicles' ?
              <>
                <ExpiredVehicles
                  userType={this.Util.getUserType(this.props)}
                  data={this.state.expiredVehicles}
                  policyNumber={this.state.searchedPolicyNumber}
                  currentPage={this.state.currentPage}
                  policyChosen={this.state.policyChosen}
                />
                </>
              :null}

              {this.state.currentPage === 'RenewalVehicles' ?
              <>
                <RenewalVehicles 
                  data={this.state.renewalVehicles}
                  policyState={this.state.policyState}
                />
                </>
              :null}
              </div>
            </>
            :null}


              {this.state.showErrors || this.state.showSuccess ?
                <DisplayBlock
                  closeDisplayMessage={this.closeMessage}
                  errors={this.state.showErrors} 
                  noVehicleOnPolicy={this.state.noVehiclesOnPolicyError} 
                  success={this.state.showSuccess}
                  />
              :null}
          </>
        :
        // customer display
        <div id="main-view">
            {this.state.currentPage === 'PolicySelect' ?
            <>
            <PolicySelect 
              showPolicy={this.showPolicy} 
              policies={this.state.policies}
              />
            </>
            :null}
            {this.state.vehicleAdmin ?
              <>
                <VehicleAdmin
                  currentPage={this.state.currentPage}
                  customerType={this.state.customerType}
                  showComponent={this.showComponent}
                  policyExpiryDate={this.state.policyExpiryDate}
                  locations={this.state.locations}
                  policyChosen={false}
                  vehicleResults={this.state.vehicleResponse}
                  numberOfVehiclesOnCover={this.state.numberOfVehiclesOnCover}
                  userType={this.Util.getUserType(this.props)} 
                  policyNumber={this.props.user.sub}
                  updateMethod={this.policyVehicleUpdate}
                  vehicleSearch={this.state.vehicleSearch}
                  searchVehicleUi={this.showVehicleSearchUi}
                  addANewVehicleUi={this.showAddVehicleUi}
                  addANewVheicle={this.state.addVehicle}
                  amendUI={this.state.amendUI}
                  amendUiState={this.amendUiState}
                  newVehicleId={this.state.newVehicleId}
                  vehicleAdded={this.state.vehicleAdded}
                  resetVehicleAdded={this.resetVehicleAdded}
                  customerImport={this.state.customerImport}
                  closeCustomerImport={this.closeCustomerImport}
                  closeCustomerRemoveImport={this.closeCustomerRemoveImport}
                  removeVehicles={this.state.removeVehicles}
                  readOnlyUser={this.state.readOnlyUser}
                  updateTableMethod={this.updateTableMethod}
                  policyId={this.state.policyId}
                  policyState={this.state.policyState}
                  policyStatus={this.state.policyStatus}
                  numberOfPeriods={this.state.numberOfPeriods}
                  disableSwitchPeriods={this.disableSwitchPeriods}
                  enableSwitchPeriods={this.enableSwitchPeriods}
                  activeFunction={this.activeFunction}
                />
              </>
            :null}
            {this.state.currentPage === 'ExpiredVehicles' ?
              <>
                <ExpiredVehicles
                  data={this.state.vehicleResponse}
                  policyNumber={this.props.user.sub}
                  policyStatus={this.state.policyStatus}
                  tableHeaders={this.state.tableHeaders}
                />
                </>
              :null}
            {!this.state.vehicleAdmin && this.state.currentPage !== 'PolicySelect' ?
              <img src={throbber} alt="throbberspi"/>
            :null}
        </div>
        }
        
      </div>
      <PortalShutDown handleLogout={this.handleLogout.bind(this)} content={Constants.PORTAL_SHUTDOWN}/>
      </>
    );
  }

  handleLogout(){
    Auth.logout()
    if (this.Util.getUserType(this.props)){
      this.props.history.replace('/admin');
    } else {
      this.props.history.replace('/login');
    }
      
  }
}

export default withAuth(App);
