import React, { Component } from 'react'
import ContactUs from '../ContactUs'
import specific_import from '../../images/specific_import.png'
import bulk_import from '../../images/bulk_import.png'
import HelpItem from './HelpItem'

class HelpSection extends Component {
    constructor(props){
        super(props)
        this.state = {
            ImportHelp: false,
            ExtraHelp: false,
            TradePlateHelp: false
        }
    }

    showImportHelp = () => {
        this.setState({ImportHelp: !this.state.ImportHelp})
    }
    showExtraHelp = () => {
        this.setState({ExtraHelp: !this.state.ExtraHelp})
    }
    showTradePlateHelp = () => {
        this.setState({TradePlateHelp: !this.state.TradePlateHelp})
    }

    render(){
        return(
            <div className="help-section">
                <div className="section">
                    <>
                        <HelpItem
                            title="Which is the correct import method for me?"
                            content1="Within the admin section there are 2 different import methods for vehicles."
                            image1={bulk_import}
                            image2={specific_import}
                            subtitle1="Bulk Upload"
                            content2="Bulk Upload takes a CSV file but is NOT policy specific, the file uploaded can contain vehicles for multiple policies, below is an example of what this file may look like"
                            subtitle2="Policy Specific Import"
                            content3="Policy specific Import does what it says in the name, to import using this method, you must first specify a valid policy number, the file must be a CSV file and contain vehicle record for a specific policy only, below is an example of what this file may look like"
                        />
                    </>
                </div>
                <div className="section">
                    <>
                        <HelpItem
                            title="What Trade Plate values should I use when importing?"
                            content1="Below is the key for trade plate values when importing vehicles"
                            content2="Policyholder vehicle = U"
                            content3="Trade Plate = T" 
                        />
                    </>
                </div>
                <div className="section">
                    <>
                        <ContactUs 
                            title="Contact us at:"
                            emailMethod="Email:"
                            email="fleetmid@aioinissaydowa.eu"
                            contactMethod="Telephone:"
                            telephone="0330 022 6513"/>
                    </>
                </div>
            </div>
        )
    }
}

export default HelpSection