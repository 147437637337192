import React, { Component } from 'react';
import axios from 'axios'
import AuthService from '../services/AuthService'
import RequestEmail from './RequestEmail'
import Success from './Success'
import CreatePassword from './CreatePassword';
const Auth = new AuthService();
const api = Auth.api

class ForgotPassword extends Component {
    constructor(props){
        super(props)
        this.state = {
            forgotPassword: false,
            requestSent: '',
            showCodeToEdit: false
        }
    }

    handleChange(e){
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }

    showForgotPasswordUI = () => {
        this.setState({forgotPassword: !this.state.forgotPassword})
        this.props.hideLoginUI()
    }

    getUrl = (email) => {
        if (process.env.NODE_ENV === 'production'){
            return `${api}/admin/forgotpwd?user=`+email
        } else {
            return `http://ci002app070.aioi.de:8080/fleetapp/admin/forgotpwd?user=`+email
        }
    }

    sendRequest = (email) => {
        this.setState({waitingForResponse: true})
        axios.put(this.getUrl(email), {
        },)
        .then(response => {
            if (response.status === 200){
                this.setState({requestSent: true, email: email, waitingForResponse: false})

            }
        })
        .catch(error => {
          this.setState({requestError: true})
        })  
    }

    showCreatePasswordForm = () => {
        this.setState({showCreatePasswordForm: true, requestSent: false})
        
    }

    createPassword = (password, code) => {
        axios.put(`${api}/admin/newpwd`, {
            user: this.state.email,
            password: password,
            code: code
        })
        .then(response => {
            this.setState({passwordCreated: true, codeValidated: false, showCreatePasswordForm: false})
        })
        .catch(error => {
            if (error.response.status === 409){
                this.setState({errorMessage: error.response.data})
                if (this.state.errorMessage.includes("confirmation code is incorrect")){
                    this.setState({incorrectCode: true})
                }
            }
            if (error.response.status === 500){
                this.setState({errorMessage: "There has been a technical error"})
            }
        })
    }

    getAnotherCode = () => {
        this.setState({requestSent: false, showCodeForm: false, codeValidated: false, passwordCreated: false, waitingForResponse: false})
    }

    render(){
        return (
            <>
                <div className="forgot-password-container">
                    {/* Intial forgot password interface */}
                    {!this.state.requestSent && !this.state.showCreatePasswordForm && !this.state.codeValidated && !this.state.passwordCreated ?
                        <RequestEmail
                            hidePasswordUI={this.props.hidePasswordUI}
                            sendRequest={this.sendRequest}
                            requestSent={this.state.requestSent}
                            waitingForResponse={this.state.waitingForResponse}
                        />
                    :null}

                    
                    {/* Email sent screen */}
                    {this.state.requestSent ?
                        <Success
                            title="Forgotten your password?"
                            message="Email has been sent, check your inbox!"
                            actionBtn="I have a code"
                            extraAction="I need another code"
                            hidePasswordUI={this.props.hidePasswordUI}
                            action={this.showCreatePasswordForm}
                            getAnotherCode={this.getAnotherCode}
                        />
                    :null}
                    
                    {/* CREATE NEW PASSWORD FORM */}
                    {this.state.showCreatePasswordForm ?
                        <CreatePassword
                            incorrectCode={this.state.incorrectCode}
                            action={this.createPassword}
                        />
                    :null}

                    {/* SUCESS INTERFACE */}
                    {this.state.passwordCreated ?
                        <Success
                            title="Success"
                            message="Your password has been reset"
                            action={this.props.hidePasswordUI}
                            actionBtn="Return to Login"
                        />
                    :null}

                </div>

            </>
        )
    }
}

export default ForgotPassword