import React, { Component } from 'react';
import AuthService from './services/AuthService';

export default function withAuth(AuthComponent) {
    const Auth = new AuthService('http://CI002APP070.aioi.de:8080/fleet-1.0/');
        return class AuthWrapped extends Component {
            constructor() {
                super();
                this.state = {
                    user: null
                }
            }

            componentDidMount() {
                if (!Auth.loggedIn()) {
                    var storageValue = sessionStorage.getItem('url')
                    if (storageValue === 'admin'){
                        this.props.history.replace('/admin')
                        sessionStorage.removeItem('url')
                    } else {
                        this.props.history.replace('/login')
                    }
                }
                else {
                    try {
                        const profile = Auth.getProfile()
                        this.setState({
                            user: profile,
                            pol:  JSON.parse(localStorage.getItem('policies')),
                            name: localStorage.getItem('name')
                        })
                    }
                    catch(err){
                        Auth.logout()
                        this.props.history.replace('/login')
                    }
                }
            }

            render() {
                if (this.state.user) {
                    return (
                        <AuthComponent history={this.props.history} user={this.state.user} pol={this.state.pol} name={this.state.name} />
                    )
                }
                else {
                    return null
                }
            }
        }
}