import React, { Component } from 'react';
import axios from 'axios'
import AuthService from './services/AuthService'
import FormField from '../atoms/FormField';
import Button from '../atoms/Button'
import * as Key from './resources/translations'
import ComponentDisplayMessage from '../../components/ComponentDisplayMessage'
import Para from '../atoms/Para';
import Header from '../atoms/Header';
const Auth = new AuthService();
const api = Auth.api


class ResetAdminPIN extends Component {
    constructor(props){
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.generatePIN = this.generatePIN.bind(this);
        this.resetPIN = this.resetPIN.bind(this);
       
        this.state = {
            policyNumber: ''
        }
    }

    handleChange(e){
        this.setState(
            {[e.target.name]: e.target.value}
        )
    }


    generatePIN(e) {
        e.preventDefault();
        Auth.generatePIN()
        .then(resp => {
            this.setState({newPin: resp, pinGenerated: true})
            window.scrollTo(0, 0)
        })
        .catch(err => {
            this.setState({errors: true, message: err.data})
            window.scrollTo(0, 0)
        })
    }


    resetPIN(e) {
        e.preventDefault();
        axios.put(`${api}/adminCustomer/`, {
            loginId: this.state.policyNumber,
            pin: this.state.newPin
        },
        {headers: { Authorization: Auth.getTokenForFrontEnd() }})
        .then(resp => {
            this.setState({success: true, errors: false})
        })
        .catch(err => {
            this.setState({errors: true, success: false})
        })
    }

    render(){
        return (
            <>
            <Header value="Reset PIN"/>
            <FormField
                type="text"
                id="policyNumber"
                onChange={this.handleChange}
                value={this.state.policyNumber}
                className="policyNumber-field"
                label={Key.POLICY_NUMBER}
                hint={Key.CUSTOMER_ADMIN_POLICY_NUMBER_HINT}
            />
             {this.state.policyNumber ?
                <>
                  <Button className="button actionBtn" onClick={this.generatePIN}>Generate New PIN</Button>
                  {this.state.newPin ?
                    <>
                    <Para value="New PIN generated successfully"/>
                    <p>New PIN: <b>{this.state.newPin}</b></p>
                    <Para value="Click below to reset"/>
                    <Button className="button actionBtn" onClick={this.resetPIN}>Reset PIN</Button>
                    </>
                  :null}           
                </>
            :null} 
            <ComponentDisplayMessage
                errors={this.state.errors}
                defaultErrorMessage={Key.DEFAULT_ERROR}
                success={this.state.success}
                successMessage={Key.PIN_RESET}
            />  
            </>
        )
    }
}

export default ResetAdminPIN