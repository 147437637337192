import React, {Component} from 'react'
import AuthService from '../../UserAdmin/services/AuthService'
import * as Constants from '../../../resources/index'
import BulkImport from './BulkImport'
import RemoveVehicles from './RemoveVehicles'
import CustomerImport from './CustomerImport'
const Auth = new AuthService();
const api = Auth.api

class ImportVehicles extends Component {
    constructor(props){
        super(props)
        this.state = {
            defaultErrorMessage: 'There has been an error, please try again or contact support.',
            displayMessages: true
        }
    }

    handleImportData = (data) => {
        const newData = data.join("\n")
        this.setState({importData: newData, importFileIssue: false})
        this.testSend(this.state.importData)
    }


    testSend = (data) => {
        var xhr = new XMLHttpRequest();
        var self = this;
        xhr.open("POST", `${api}/import/`+this.props.policyNumber);
        xhr.setRequestHeader("authorization", Auth.getTokenForFrontEnd());
        xhr.send(data);
        document.getElementById("react-csv-reader-input").value = "";
        xhr.onload = function() {
            if (xhr.status !== 200) {
                self.setState({showSuccess: false, importErrorCode: xhr.status, importErrorLength: xhr.response.length, showErrors: true})
                if (this.response.includes(Constants.VEH_FILE_CONTENT_ERROR)) {
                    self.setState({importFileContentError: true})
                }
                if (this.response.includes(Constants.VEH_DUPLICATE)) {
                    self.setState({importFileDuplicateError: true})
                }
                if (this.response.includes(Constants.VEH_FILE_FORMAT_ERROR)) {
                    self.setState({importFileFormatError: true})
                } 
                if (xhr.status === 500) {
                    self.setState({importDefaultError: true})
                }
                window.scrollTo(0,document.body.scrollHeight);
                self.setState({showImportUi: false})
            } else {
              var myArr = JSON.parse(this.response);
              let today = new Date().toISOString().split('T')[0];
              self.setState({success: true, importResponses: myArr, errors: false, todaysDate: today,
                importDefaultError: false,importFileContentError: false, importFileFormatError: false, importFileDuplicateError: false})
            }
        };
    }

    refreshVehiclesAfterImport = (id) => {
        window.scrollTo(0, 0);
        if (this.props.policyChosen) {
            this.props.policyChosenUpdateMethod(id)
        } else {
            this.props.updateMethod(id)
        }
        this.setState({importRefresh: true, showImportUi: false, showErrors: false, importResponses: false, })
    }

    render(){
        return (
            <>
                {this.props.bulkImportUi || this.props.currentPage === 'BulkImport' ?
                    <BulkImport
                        policyChosen={this.props.policyChosen}
                        closeImportUis={this.props.closeImportUis}
                        displayMessages={this.state.displayMessages}
                        toggleMessages={this.props.toggleMessages}
                        refreshVehiclesAfterImport={this.refreshVehiclesAfterImport}
                    />
                :null}

                {(!this.props.userType && this.props.customerImport) || this.props.currentPage === 'CustomerImport' ?
                    <CustomerImport
                        closeImportUis={this.props.closeImportUis}
                        policyNumber={this.props.policyNumber}
                        displayMessages={this.state.displayMessages}
                        toggleMessages={this.props.toggleMessages}
                        showComponent={this.props.showComponent}
                        policyId={this.props.policyId}
                        policyState={this.props.policyState}
                        customerType={this.props.customerType}
                        refreshVehiclesAfterImport={this.refreshVehiclesAfterImport}
                    />
                :null}

                {this.props.removeVehicles || this.props.adminRemoveVehicles || this.props.currentPage === 'Remove' ?
                    <RemoveVehicles
                        userType={this.props.userType}
                        closeImportUis={this.props.closeImportUis}
                        policyNumber={this.props.policyNumber}
                        displayMessages={this.state.displayMessages}
                        toggleMessages={this.props.toggleMessages}
                        showComponent={this.props.showComponent}
                        policyId={this.props.policyId}
                        policyState={this.props.policyState}
                        refreshVehiclesAfterImport={this.refreshVehiclesAfterImport}
                    />
                :null}
            </>
        )
    }
}

export default ImportVehicles