import React, {useState, useEffect} from 'react'
import Util from '../../Util'
import { useTable, useSortBy, useFilters } from 'react-table'
import ActiveToggle from './ActiveToggle';
import AuthService from '../UserAdmin/services/AuthService'
import { FaSortAlphaDownAlt, FaSortAlphaDown, FaArrowCircleUp } from 'react-icons/fa'
const Auth = new AuthService();
const UtilF = new Util();

const OTable = props => {
  const [activeState, setActiveState] = useState(true);
  const [newVehicleId] = useState(props.newVehicleId)
  const [vehicleData, setData] = useState(props.data)

  useEffect(() => {
    let mybutton = document.getElementById("goToTop");
    window.onscroll = function() {scrollFunction()};
    function scrollFunction() {
      if (document.body.scrollTop > 350 || document.documentElement.scrollTop > 350) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    }
    setData(props.data)
    // make sure search fields do not reset when using the active toggle
    if (props.searchReg !== undefined){
      document.querySelector("#registration > div > input").value = props.searchReg
    }
    if (props.searchLocation !== undefined){
      document.querySelector("#location > div > input").value = props.searchLocation
    }
  });

    // Define a default UI for filtering
    function DefaultColumnFilter({
      column: { filterValue, setFilter },
    }) {
      return (
        <input
          value={filterValue || ''}
          onChange={e => {
            setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
          }}
        />
      )
    }

    const filterTypes = React.useMemo(
      () => ({
        text: (rows, id, filterValue) => {
          return rows.filter(row => {
          const rowValue = row.values[id]
            return rowValue !== undefined
              ? String(rowValue)
                  .toLowerCase()
                  .startsWith(String(filterValue).toLowerCase())
              : true
          })
        },
      }),
      []
    )
    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )
    const data = vehicleData
    const columns = React.useMemo(() => props.tableHeaders,[props.tableHeaders])
    const tableInstance = useTable({ columns, data, defaultColumn, // Be sure to pass the defaultColumn option
        filterTypes, },useFilters, useSortBy)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance

    const getRowDetails = (row) => {
        if (props.currentPage === 'MidTable' || props.currentPage === 'ExpiredVehicles' || props.policyStatus === 'EXPIRED' || props.adminRole === 'ROLE_UNDERWRITER' || props.readOnlyUser){
          
        } else {
          props.action(row.original)
        }
        
    }
    const activeFunction = () => {
      setActiveState(!activeState)
      document.querySelector("#active").setAttribute("interaction", !activeState)
      var location = document.querySelector("#location > div > input").value
      var registration = document.querySelector("#registration > div > input").value
      props.activeFunction(location, registration, activeState)
    }
   if (document.getElementById(newVehicleId) !== null && props.vehicleAdded){
      let element = document.getElementById(props.newVehicleId);
      Auth.scrollToVehicle(element)
      props.resetVehicleAdded()
    }
    return (
      
        <>
        {props.filter && props.policyStatus !== 'EXPIRED' ?
        <>
        {headerGroups.map(headerGroup => (
          <div className="toggle-container" id="topBar" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (             
            <div id={column.id} title="" className="FormField inline" {...column.getHeaderProps(column.getSortByToggleProps())}>
              <div><label htmlFor={column.id}>{column.Header}</label>{column.canFilter ? column.render('Filter') : null}</div>
            </div>
            ))}
            {props.currentPage !== 'MidTable' ?
              <ActiveToggle label="Active?" value={activeState} action={activeFunction} name="active" id="active" />
            :null}
          </div>
        ))}

        </>
        :null}
        
     <table {...getTableProps()}>
       <thead>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (             
             <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
               <span>
                 {column.isSorted ? 
                  (column.isSortedDesc ? 
                    <span><FaSortAlphaDown/></span> : <span><FaSortAlphaDownAlt/></span>
                  ) 
                  : ''
                  }
               </span>
            </th>
           
             ))}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()}>
         {rows.map(row => {
           prepareRow(row)
           return (
             <tr id={row.original.vehicleId} {...row.getRowProps()} onClick={() => {getRowDetails(row)}}>
               {row.cells.map(cell => {
                 return (
                   <td
                     {...cell.getCellProps()}
                    
                   >
                     {cell.render('Cell')}
                   </td>
                 )
               })}
             </tr>
           )
         })}
       </tbody>
     </table>
     <span id="goToTop" onClick={UtilF.goToTop}><FaArrowCircleUp/></span>
        </>
    )
}

export default OTable