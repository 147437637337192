import React, {Component} from 'react'
import * as Table from '../../resources/tableFile'
import * as Labels from '../../resources/labels'
import * as Key from './resources/translations'
import InputField from '../atoms/InputField'
import Label from '../atoms/Label'
import Button from '../atoms/Button'
import PolicyService from './services/PolicyService'
import OTable from '../atoms/OTable'

class PolicySearch extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckNew = this.handleCheckNew.bind(this);
        this.getPolicy = this.getPolicy.bind(this);
        this.Policy = new PolicyService();
        this.state = {
            policyNumber: '',
            policyHolderName: '',
            chosenPolicy: ''
        }
    }

    getPolicy = (e) => {
        e.preventDefault();
        if (this.state.policyNumber.toUpperCase().startsWith('S') && this.state.policyNumber.length < 7){
            window.alert('This is not a valid customer admin policy number')
        } else {
            this.Policy.policySearch(this.state.policyNumber, this.state.policyHolderName)
            .then(res => {
                this.setState({policySearchResponse: res, policiesFound: true, numberOfRecords: res.length})
                window.scrollTo(0, 0)
            })
            .catch(err => {
                this.setState({showErrors: true})
                window.scrollTo(0, 0)
            })
        }
        
    }

    handleChange(e){
        this.setState(
            {
                [e.target.name]: e.target.value, chosenPolicy: '', policiesFound: false
            }
        )
    }

    // OLD LOGIC
    handleCheck(e) {
        this.setState({chosenPolicy: e.currentTarget.dataset.id, chosenRecordType: e.currentTarget.dataset.record, chosenActiveFlag: e.currentTarget.dataset.active, chosenPolicyId: e.currentTarget.dataset.iden, chosenStautus: e.currentTarget.dataset.status }, () => {
            window.scrollTo(0,document.body.scrollHeight)
        })
        

    }

    handleCheckNew(row) {
        this.setState({chosenPolicy: row.policyNumber, chosenRecordType: row.recordType, chosenActiveFlag: row.activeFlag, chosenPolicyId: row.policyId, chosenStautus: row.status})
        window.scrollTo(0,document.body.scrollHeight)
    }

    sendDataToParent = () => {
        localStorage.setItem('policyID', this.state.chosenPolicyId)
        this.props.confirmSearch(this.state.chosenPolicy, this.state.chosenPolicyId, this.state.chosenActiveFlag, this.state.chosenStautus)
    }

    render(){
        return (
            <div>
                <div className="loginWrapper">
                    <form id="policySearch">
                        <div className="FormField">
                        <Label for="policyNumber">{Labels.POLICY_NUMBER}</Label>
                            <InputField
                                name="policyNumber"
                                className="policyNumber-field"
                                type="text"
                                value={this.state.policyNumber}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="FormField">
                        <Label for="policyHolderName">{Labels.POLICY_HOLDER_NAME}</Label>
                            <InputField
                                name="policyHolderName"
                                type="text"
                                value={this.state.policyHolderName}
                                onChange={this.handleChange}
                            />
                        </div>
                        <Button className="button" onClick={this.getPolicy}>{Key.SEARCH}</Button>
                    </form>
                </div>

                {this.state.policiesFound ?
                    <>
                    <p>{Key.SEARCH_RETURNED} <b>{this.state.numberOfRecords}</b> {Key.RESULTS}</p>
                    
                    <OTable 
                        data={this.state.policySearchResponse} 
                        tableHeaders={Table.POLICY_SEARCH} 
                        action={this.handleCheckNew.bind(this)}
                        filter={false}
                        sort={false}
                    />
                    
                    {this.state.chosenPolicy  ?
                    <>
                        <h3>{Key.CHOSEN_POLICY} {this.state.chosenPolicy}</h3>
                        <Button id="proceed" className="button" onClick={ this.sendDataToParent }>{Key.PROCEED}</Button>
                    </>
                    : null}
                    </>
                :null}

            </div>

        )
    }


}

export default PolicySearch