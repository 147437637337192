import React, { Component } from 'react'
import CSVReader from 'react-csv-reader'
import * as Key from '../resources/translations'
import { FaWindowClose } from 'react-icons/fa';
import AuthService from '../../UserAdmin/services/AuthService'
const Auth = new AuthService();
const api = Auth.api
let customerType = '';

class CustomerImport extends Component {
    constructor(props){
        super(props)
        this.state = {
            importPolicyNumber: '',
        }
    }

    componentDidMount(){
        customerType = this.props.customerType
    }

    toggleMessages = () => {
        this.props.toggleMessages()
    }

    showComponent = (component, sub) => {
        this.props.showComponent(this.props.userType, component, sub)
    }

    handleImportForCustomer = (data, policyId) => {
        let headers
        let result
        var checkWording = data.toString().toUpperCase()
        if (checkWording.includes('REGISTRATION')){
            headers = data.shift().join(',')
            result = data.map(function (row) {
                if (customerType === 'ROLE_ICHIBAN'){
                    if (row[9] !== undefined && row[8] !== undefined){
                        var valueSet1 = row[9]
                        var valueSet2 = row[8]
                        row.pop(0)
                        row.pop(0)
                        var removedCommas = valueSet1.replace(/,/g, "")
                        var removedCommas2 = valueSet2.replace(/,/g, "")
                        row.push(removedCommas2)
                        row.push(removedCommas);
                        return row.map(function(field){ return '"' + field + '"';})
                    }             
                    } else {
                        return row.map(function(field){ return '' + field + '';})
                    }
    
             })
        } else {
            result = data.map(function (row) {
                if (customerType === 'ROLE_ICHIBAN'){
                    if (row[9] !== undefined && row[8] !== undefined){
                        var valueSet1 = row[9]
                        var valueSet2 = row[8]
                        row.pop(0)
                        row.pop(0)
                        var removedCommas = valueSet1.replace(/,/g, "")
                        var removedCommas2 = valueSet2.replace(/,/g, "")
                        row.push(removedCommas2)
                        row.push(removedCommas);
                        return row.map(function(field){ return '"' + field + '"';})
                    }             
                    } else {
                        return row.map(function(field)
                        { 
                            return '' + field + '';}
                        )
                    }
    
             })
        }
        if (checkWording.includes('Registration')){
            this.setState({importData: headers+'\n'+result.join('\n'), importFileIssue: false})
        } else {
            this.setState({importData: result.join('\n'), importFileIssue: false})
        }
        if (policyId){
           this.sendImportData(this.state.importData, policyId)
        } else {
            document.getElementById("react-csv-reader-input").value = "";
        }
    }

    sendImportData = (data, policyId) => {
        var xhr = new XMLHttpRequest();
        var self = this;
        xhr.open("POST", `${api}/import/`+policyId);
        xhr.setRequestHeader("authorization", Auth.getTokenForFrontEnd());
        xhr.send(data);
        document.getElementById("react-csv-reader-input").value = "";
        xhr.onload = function() {
            if (xhr.status === 409) {
                self.toggleMessages()
                var errorResponse = JSON.parse(this.response);
                self.setState({showSuccess: false, importErrorCode: xhr.status, importErrors: errorResponse, importErrorLength: xhr.response.length, errors: true})
            } 
            if (xhr.status === 200) {
                self.toggleMessages()
                var myArr = JSON.parse(this.response);
                self.setState({success: true, importResponses: myArr, errors: false, importErrors: false,
                importDefaultError: false,importFileContentError: false, importFileFormatError: false, importFileDuplicateError: false})
                self.props.refreshVehiclesAfterImport(policyId)
            }
            if (xhr.status === 500){
                self.setState({success: false, importErrorCode: xhr.status, errors: true})
            }
        };
    }

    render(){
        return(
            <>
            <span id="close_amend" className="close-icon" onClick={() => {this.showComponent('Table', false)}}><FaWindowClose />CLOSE</span>
            <form>
                <h1 className="header">{Key.VEH_IMPORT_TITLE}</h1>
                {this.state.errors && this.props.displayMessages ?
                    <ul>
                    {this.state.importErrors.map((importError, index) =>
                        <li key={index}>{importError}</li>
                    )}
                    </ul>
                :null}
                {this.state.importResponses && !this.state.errors ?
                <div className="importSuccess">
                    <ul>
                    {this.state.importResponses.map((response, index) =>
                        <li key={index}>{response}</li>
                    )}
                    </ul>    
                </div>
                                   
                :null}
                <div className="FormField">
                <br/>
                <CSVReader onFileLoaded={(data) => this.handleImportForCustomer(data, this.props.policyId)} />
                <br/>
                </div>
            </form>

            </>
        )
    }
}

export default CustomerImport