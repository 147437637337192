import React, { Component } from 'react'
import axios from 'axios'
import AuthService from '../UserAdmin/services/AuthService'
import * as Role from '../../resources/roles'
import * as Constants from '../../resources/index'
import * as Key from './resources/translations';
import * as Labels from '../../resources/labels'
import Button from '../atoms/Button'
import { FaWindowClose, FaCheckCircle } from 'react-icons/fa';
import ComponentDisplayMessage from '../ComponentDisplayMessage'
import FormField from '../atoms/FormField';
const Auth = new AuthService();
const api = Auth.api

class AmendVehicle extends Component {
    constructor(props){
        super(props)
        this.state = {
            showEffectiveDate: false,
            excludeFromMid: this.props.currentMidSent,
            showAmendForm: false,
            showRemoveForm: false
        }
    }

    showAmendForm = () => {
        // CHECK THE REGISTRATION IS VALID
        let regValid = Auth.containsSpecialCharacters(this.props.currentVehicleReg.toUpperCase())
        let onLoadRegTest = Auth.regValidator(this.props.currentVehicleReg.toUpperCase())
        let onLoadIrelandTest = Auth.irishRegValidator(this.props.currentVehicleReg.toUpperCase())
        if (regValid){
            if (onLoadRegTest || onLoadIrelandTest){
                this.setState({validReg: true}) 
            }
            if (onLoadIrelandTest){
                this.setState({irishReg: true, excludeFromMid: true})
            }
            if (!onLoadIrelandTest){
                this.setState({excludeFromMid: false, irishReg: false})
            }
            if (!onLoadRegTest){
                this.setState({validReg: false})
            }
              
        } else {
            this.setState({validReg: false})
        }
        // POPULATE TRAILER FIELD
        if (this.props.currentTrailer){
            this.setState({trailer: 'Yes', excludeFromMid: true, validReg: true})
        } else if (!this.props.currentTrailer){
            this.setState({trailer: 'No'})
        }
        // POPULATE FORIEGN REG IND
        let currentForiegnReg = this.props.currentForienRegIndictor
        if (currentForiegnReg === 'F'){
            this.setState({foreignRegIndicatorBooleanValue: true, foreignRegIndicator: 'Yes'})
        } else {
            this.setState({foreignRegIndicatorBooleanValue: false, foreignRegIndicator: 'No'})
        }
        // SHOW AMEND FORM AND POPULATE ALL VALUES
        this.setState({showAmendForm: !this.state.showAmendForm, showRemoveForm: false, amendShow: !this.state.amendShow, removeShow: false,
            registrationNumber: this.props.currentVehicleReg, make: this.props.currentMake, model: this.props.currentModel, tradePlate: this.props.currentTradePlate,
            location: this.props.currentLocation, offCover: this.props.currentOffCover, onCover: this.props.currentOnCover, vehicleDerivative: this.props.currentVehicleDerivative, namedDriver: this.props.currentNamedDriver })
        // check this below is it still needed?
            if (this.props.adminRole !== 'Underwriter') {
            this.setState({showAmendForm: !this.state.showAmendForm, showRemoveForm: false, amendShow: !this.state.amendShow, removeShow: false,
                registrationNumber: this.props.currentVehicleReg, make: this.props.currentMake, model: this.props.currentModel, tradePlate: this.props.currentTradePlate,
                location: this.props.currentLocation, offCover: this.props.currentOffCover, onCover: this.props.currentOnCover })
        }

        // DO A BASIC VALIDITY TEST ON THE REG WHEN THE TRADE PLATE IS T VALID UK REG IS NOT NEEDED
        if (this.props.currentTradePlate === 'T'){
            let basicValidTest = Auth.basicValidTest(this.props.currentVehicleReg.toUpperCase())
                if (basicValidTest){
                    this.setState({validReg: true})
                } else {
                    this.setState({validReg: false})
                }
        }
    }

    showRemoveForm = () => {
        this.setState({showRemoveForm: !this.state.showRemoveForm, showAmendForm: false, amendShow: false, removeShow: !this.state.removeShow})
        if (this.props.currentMidStatus === 'Pending'){
            this.setState({dOffDate: this.props.currentOnCover})
        } else {
            this.setState({dOffDate: this.props.currentOffCover})
        }
        
    }
    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        // CHECK VALIDITY OF REG WHILE CHANGING REGSTRATION
        if (name === 'registrationNumber'){
            let regTest = Auth.regValidator(value.toUpperCase())
            let irelandTest = Auth.irishRegValidator(value.toUpperCase())
            if (regTest || irelandTest){
                this.setState({validReg: true})
            } else {
                this.setState({validReg: false})
            }
            if (irelandTest){
                this.setState({irishReg: true, excludeFromMid: true})
            }
            if (!irelandTest){
                this.setState({irishReg: false, excludeFromMid: false})
            }
            if (!regTest){
                this.setState({validReg: false})
            }
        }
        // CHECK VALIDITY OF REG WHILE CHANGING INDICATOR
        if (name === 'foreignRegIndicator'){
            if (value === 'Yes'){
                this.setState({indicatorChange: true, foreignRegIndicatorBooleanValue: true, tradePlate: 'U'}, () => {
                    let basicValidTest = Auth.basicValidTest(this.state.registrationNumber.toUpperCase())
                    if (basicValidTest){
                        this.setState({validReg: true})
                    } else {
                        this.setState({validReg: false})
                    }
                })
            } else {
                this.setState({foreignRegIndicatorBooleanValue: false})
            }
        }
        // BASIC REG CHECK WHILE CHANGING TRAILER
        if (name === 'trailer'){
            if (value === 'Yes'){
                this.setState({trailer: true, excludeFromMid: true})
                let basicValidTest = Auth.basicValidTest(this.state.registrationNumber.toUpperCase())
                if (basicValidTest){
                    this.setState({validReg: true})
                } else {
                    this.setState({validReg: false})
                }
            } else {

                this.setState({trailer: false})
            }
        }
        // BASIC REG CHECK WHILE CHANGING TRADE PLATE
        if (name === 'tradePlate'){
            if (value === 'T'){
                let basicValidTest = Auth.basicValidTest(this.state.registrationNumber.toUpperCase())
                if (basicValidTest){
                    this.setState({validReg: true})
                } else {
                    this.setState({validReg: false})
                }
            }
        }
        // ASSIGNING VALUES TO STATE
        this.setState({[name]: value}, () => {})
    }

    handleChangeEffectiveDate = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        this.setState({[name]: value}, () => {
            if (!this.props.userType){
                this.setEffectiveDateAsOnCover(this.state.effectiveDate)
            }
        })
    }

    setEffectiveDateAsOnCover = (value) => {
        this.setState({onCover: value})
    }
    
    onTradePlateChange = (e) => {
        this.setState({tradePlate: e.target.value})
    }

    validateForm = () => {
        if (this.state.validReg){
            return true
        }
        if (!this.state.validReg && this.state.foreignRegIndicatorBooleanValue && this.props.currentForienRegIndictor === 'F'){
            return true
        }
        if (!this.state.foreignRegIndicatorBooleanValue && !this.state.validReg && this.state.tradePlate === 'U'){
            return false
        }
        if (!this.state.validReg && !this.state.foreignRegIndicatorBooleanValue && this.state.tradePlate === 'T'){
            return true
        }
    }

    chooseEffectiveDate = () => {
        if (this.validateForm()){
            let today = new Date().toISOString().split('T')[0];
            today = Auth.dateConvertor(today, 'amend')
            if (!this.props.userType){
                this.setState({showEffectiveDate: true, amendMore: true, effectiveDate: today}, () => {
                    this.setEffectiveDateAsOnCover(this.state.effectiveDate)
                })
            } else {
                this.setState({showEffectiveDate: true, amendMore: true, effectiveDate: this.state.onCover})
            }
        } else {
            window.alert('Invalid Registration Number')
        }
    } 

    amendVehicle = () => {
        let trailer
        if (this.state.trailer === 'Yes'){
            trailer = true
        } else if (this.state.trailer === 'No' || !this.state.trailer)  {
            trailer = false
        }  
        axios.put(`${api}/vehicle/`+this.props.currentId, {
            registration: this.state.registrationNumber.toUpperCase(),
            tradePlate: this.state.tradePlate,
            vehicleMake: this.state.make,
            vehicleModel: this.state.model,
            vehicleCoverStartDate: this.state.effectiveDate,
            vehicleCoverEndDate: this.state.offCover,
            location: this.state.location,
            excludeFromMid: this.state.excludeFromMid,
            foreignRegistration: this.state.foreignRegIndicatorBooleanValue,
            vehicleDerivative: this.state.vehicleDerivative,
            namedDriver: this.state.namedDriver,
            trailer: trailer
        },
        {
            headers: {
                Authorization: Auth.getTokenForFrontEnd()
            }
        }
        )
        .then(response => {
            this.setState({amendVehicle: false, success: true, errors: false})
            if (this.props.policyChosen) {
                this.props.policyChosenUpdateMethod(this.props.policyId)
                this.setState({showEffectiveDate: false})
            } else {
                if (this.props.userType){
                    this.props.updateMethod(this.props.policyId)
                } else {
                    this.props.updateMethod(localStorage.getItem('policyID'))
                }
                this.setState({showEffectiveDate: false})
            }
        })
        .catch(error => {
            this.setState({errors: true, success: false, amendError: error.response.data, amendErrorCode: error.response.status})
            if (this.state.amendErrorCode !== 200 || this.state.amendErrorCode !== 500){
                this.setState({amendVehicleError: error.response.data})
            }
            if (this.state.amendErrorCode === 500){
                this.setState({amendVehicleError: Constants.DEFAULT_ERROR})
            }
        })
    }

    removeVehicle = (id) => {
        id = this.props.currentId
        let offDate = Auth.dateConvertor(this.state.dOffDate, 'remove')
        axios.delete(`${api}/vehicle/`+id+`/`+offDate, {
            headers: {
                    Authorization: Auth.getTokenForFrontEnd()
            }
        }).then(resp => {
            this.setState({showRemoveForm: false, dOffDate: '', success: true, showErrors: false, amendVehicle: false})
            if (this.props.policyChosen) {
                this.props.policyChosenUpdateMethod()
                this.props.showComponent(this.props.userType, 'Table', false)
            } else {
                this.props.updateMethod()
                if (!this.props.userType){
                    this.props.showComponent(this.props.userType, 'Table', false)
                } else {
                    this.props.showComponent(this.props.userType, 'Rejected', true)
                }
            }
            window.scrollTo(0, 0)
        })
        .catch(error => {
            this.setState({errors: true, success: false, removeError: error.response.data, removeErrorCode: error.response.status})
            if (this.state.removeErrorCode !== 200 || this.state.amendErrorCode !== 500){
                this.setState({removeError: error.response.data})
            }
            if (this.state.removeErrorCode === 500){
                this.setState({removeError: Constants.DEFAULT_ERROR})
            }
        })
    }

    handleCheckClick = () => {
        this.setState({ excludeFromMid: !this.state.excludeFromMid });
    }
    
    amendMore = () => {
        this.setState({amendMore: false, showEffectiveDate: false})
    }
    showComponent = (component, sub) => {
        if (this.props.policyChosen || !this.props.userType){
            this.props.showComponent(this.props.userType, 'Table', sub)
        }
        if (!this.props.policyChosen && this.props.userType){
            this.props.showComponent(this.props.userType, 'Rejected', sub)
        }
       
    }
    render(){
        let subNavigationButton = 'button actionBtn'
        let regIcon
        if (this.state.validReg){
            regIcon += ' valid-reg'
        }
        if (this.state.validReg !== undefined && this.state.validReg !== true){
            regIcon += ' invalid-reg'
        }
        if (this.state.validReg === undefined){
            regIcon += ' empty-reg'
        }
        return (
            <>
                <div id="amend-container">
                    <>
                    {!this.state.vehicleChoice ?
                    <>
                        <button id="close_remove" className="close-icon" onClick={() => {this.showComponent('Table', false)}}><FaWindowClose />CLOSE</button>
                        {!this.state.showAmendForm  ?
                        <>
                            {!this.state.showRemoveForm ?
                            <>
                                <Button className={subNavigationButton} onClick={this.showAmendForm}>Amend</Button>
                                <Button className={subNavigationButton} onClick={this.showRemoveForm}>Remove Off Cover</Button>
                            </>
                            :null}
                        </>

                        :null}
                    </>
                    :null}
                    {this.state.showAmendForm ?
                    <>
                        {this.props.lastAmended ?
                            <p>{Key.VEH_LAST_AMENDED} {this.props.lastAmended}</p>
                        :null}
                        {!this.state.amendMore && !this.showEffectiveDate ?
                            <>
                        {/* Allow admin to re send vehicle to MID */ }
                        {this.props.userType ?
                            <Button className="button" onClick={this.props.triggerMID}>{Key.VEH_RE_SEND_TO_MID}</Button>
                        :null}

                                {this.props.currentMidStatus === Constants.MID_NOT_SENT || this.props.currentMidStatus === Constants.MID_REJECTED ?
                                    <FormField
                                        type="text"
                                        id="registrationNumber"
                                        value={this.state.registrationNumber}
                                        maxLength="12"
                                        label={Labels.REGISTRATION_NUMER}
                                        onChange={this.handleChange}
                                        hint={Key.VEH_REG_HINT}
                                        iconClass={regIcon}
                                        icon={<FaWindowClose/>}
                                        validIcon={<FaCheckCircle/>}
                                        validReg={this.state.validReg}
                                />
                                :
                                <FormField
                                    type="text"
                                    id="registrationNumber"
                                    value={this.state.registrationNumber}
                                    maxLength="12"
                                    label={Labels.REGISTRATION_NUMER}
                                    onChange={this.handleChange}
                                    hint={Key.VEH_REG_HINT}
                                    readOnly={true}
                                />
                                }
                                {this.props.currentMidStatus === Constants.MID_NOT_SENT || this.props.currentMidStatus === Constants.MID_REJECTED ?
                                <FormField
                                    type="select"
                                    id="foreignRegIndicator"
                                    value={this.state.foreignRegIndicator}
                                    onChange={this.handleChange}
                                    label={Labels.FOREIGN_REG_INDICATIOR}
                                    defaultSelect="No"
                                    option1="Yes"   
                                />
                                :
                                <FormField
                                type="select"
                                id="foreignRegIndicator"
                                value={this.state.foreignRegIndicator}
                                onChange={this.handleChange}
                                label={Labels.FOREIGN_REG_INDICATIOR}
                                defaultSelect="No"
                                option1="Yes"  
                                readOnly={true} 
                                />}

                                {this.props.customerType === Role.TRAILER_USER ?
                                    <FormField
                                        type="select"
                                        id="trailer"
                                        value={this.state.trailer}
                                        onChange={this.handleChange}
                                        label="Trailer?"
                                        defaultSelect="No"
                                        option1="Yes"   
                                     />
                                :null}
 
                                <FormField
                                    type="select"
                                    id="tradePlate"
                                    value={this.state.tradePlate}
                                    label={Labels.TRADE_PLATE}
                                    defaultSelect={Labels.CHOOSE_OPTION}
                                    option1={Labels.TRADE_PLATE_U}
                                    option2={Labels.TRADE_PLATE_T}
                                    onChange={this.onTradePlateChange} onClick={this.handleClick}
                                    hint={Key.VEH_TRADE_PLATE_HINT}
                                />

                                
                                <FormField
                                    type="text"
                                    id="make"
                                    value={this.state.make}
                                    maxLength="15"
                                    onChange={this.handleChange}
                                    label={Labels.MAKE}
                                    hint={ this.props.customerType === Role.TRAILER_USER ? Key.VEH_MODEL_HINT_TRAILER : Key.VEH_MAKE_HINT }
                                    
                                />
                                 
                                <FormField
                                    type="text"
                                    id="model"
                                    value={this.state.model}
                                    maxLength="15"
                                    onChange={this.handleChange}
                                    label={Labels.MODEL}
                                    hint={ this.props.customerType === Role.TRAILER_USER ? Key.VEH_MODEL_HINT_TRAILER : Key.VEH_MAKE_HINT }
                                    
                                />


                                {this.props.userType ?
                                    <FormField
                                        type="text"
                                        id="onCover"
                                        value={this.state.onCover}
                                        onChange={this.handleChange}
                                        label={Labels.ON_DATE}
                                        readOnly={true}
                                    />
                                :null}

                                <FormField
                                    type="text"
                                    id="offCover"
                                    value={this.state.offCover}
                                    onChange={this.handleChange}
                                    label={Labels.OFF_DATE}
                                    hint="DD/MM/YYYY"
                                />

                                {this.props.customerType === Role.ICHIBAN_USER ?
                                <>
                                    <FormField
                                    type="text"
                                    id="vehicleDerivative"
                                    value={this.state.vehicleDerivative}
                                    onChange={this.handleChange}
                                    label="Vehicle Derivative"
                                    />

                                    <FormField
                                    type="text"
                                    id="namedDriver"
                                    value={this.state.namedDriver}
                                    onChange={this.handleChange}
                                    label="Named Driver"
                                    />
                                </>                                    
                                :null}

                                <FormField
                                    type='text'
                                    id="location"
                                    value={this.state.location}
                                    onChange={this.handleChange}
                                    label={Labels.LOCATION}
                                />

                                {this.props.userType && this.props.customerType !== Role.TRAILER_USER ?
                                <>
                                {!this.state.irishReg ?
                                    <FormField
                                        type="checkbox"
                                        id="excludeFromMid"
                                        checked={this.state.excludeFromMid}
                                        onChange={this.handleCheckClick}
                                        label="Exclude from MID"
                                    />
                                :null}
                                     
                                </>
                                :null}

                                <Button className="button" onClick={this.chooseEffectiveDate}>{Key.VEH_EFFECTIVE_DATE_BUTTON}</Button>
                            </>
                        :null}

                        {this.state.showEffectiveDate ?
                            <>
                                <FormField
                                    type="text"
                                    id="effectiveDate"
                                    value={this.state.effectiveDate}
                                    onChange={this.handleChangeEffectiveDate}
                                    label={Labels.EFFECTIVE_DATE}
                                />
                                <Button className="button" onClick={() => {this.amendVehicle(
                                        this.state.registrationNumber, this.state.tradePlate, this.state.make,
                                        this.state.model, this.state.offDate, this.state.location, this.state.effectiveDate, this.state.excludeFromMid
                                )}}>{Key.VEH_CONFIRM}</Button>  
                            </>
                        :null}

                        {this.state.success ?
                            <>
                                <Button className="button" onClick={() => {this.showComponent('Table', false)}}>Return to Table</Button>
                            </>
                        :null}
                        <ComponentDisplayMessage
                            error={this.state.errors}
                            success={this.state.success}
                            successMessage="Vehicle Amended Successfully"
                            errorMessage={this.state.amendVehicleError}
                        />
                    </>
                    :null}
                    {this.state.showRemoveForm ?
                    <>
                    <div>
                        <p>If the vehicle was added to this policy in error, and should never have been on cover, then enter the original On Date in the Off Date field</p>
                        <FormField
                            type="text"
                            id="registrationNumber"
                            value={this.props.currentVehicleReg}
                            onChange={this.handleChange}
                            label={Labels.REGISTRATION_NUMER}
                            readOnly={true}
                        />
                        
                        <FormField
                            type="text"
                            id="onDate"
                            value={this.props.currentOnCover}
                            onChange={this.handleChange}
                            label={Labels.ON_DATE}
                            readOnly={true}
                        />
                        {this.props.currentMidStatus === 'Pending' ?
                            <FormField
                                type="text"
                                id="dOffDate"
                                value={this.state.dOffDate}
                                label={Labels.OFF_DATE}
                                onChange={this.handleChange}
                                readOnly={true}
                            />
                        :<FormField
                            type="text"
                            id="dOffDate"
                            value={this.state.dOffDate}
                            label={Labels.OFF_DATE}
                            onChange={this.handleChange}
                        />}

                        <ComponentDisplayMessage
                            error={this.state.errors}
                            success={this.state.success}
                            successMessage="Vehicle Removed Successfully"
                            errorMessage={this.state.removeError}
                            
                        />

                        <Button className="button remove" onClick={() => { this.removeVehicle(this.currentID, this.state.dOffDate) }}>{Key.VEH_REMOVE_BUTTON}</Button>

                    </div>
                    </>
                    :null}
                    </>
                </div>
            </>
        )
    }
}

export default AmendVehicle