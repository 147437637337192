import React from 'react'

const ActiveToggle = props => {
    var wrapperClass = "FormField inline"
    if (props.name === 'midToggle'){
      wrapperClass = ''
    }
    return (
        <div id="activeToggle" className={wrapperClass}>
        <label htmlFor={props.id}>{props.label}</label>
        <label className="switch">
          <input type="checkbox" id={props.id} name={props.name} checked={props.value} onChange={props.action} interaction="true" />
          <span className="slider round"></span>
        </label>
      </div>
    )
}

export default ActiveToggle