import React, { Component } from 'react'
import PolicyEdit from '../Policy/PolicyEdit';
import * as Table from '../../resources/tableFile'
import VehicleTableContainer from '../Vehicle/VehicleTableContainer'



class RejectedAdmin extends Component {
    constructor(props){
        super(props)
        this.state = {
            rejectedPolicyTableHeaders: Table.ADMIN_POLICY_REJECTED,
            rejectedVehicleTableHeaders: Table.ADMIN_REJECTED,

        }
    }

    proceedToEdit = (data) => {
        this.setState({rejectedPolicyDetails: {
            policyNumber: data.policyNumber,
            policyId: data.policyId,
            policyHolder: data.policyHolderName,
            midFileSeqNo: data.midFileSeqNo,
            partyPolicyControlCount: data.partyPolicyControlCount,
            recordType: data.midRecordType,
            midStatus: data.midStatus,
            coverStartDate: data.policyCoverStartDate,
            coverEndDate: data.policyCoverEndDate
            }
          })
        this.props.proceedToEdit(data)
    }

    showMessage = (request) => {
        this.setState({request: request})
    }

    render(){
        return (
            <>
            {!this.props.policyChange ?
            <>
                {!this.props.noRejectedPolicies ?
                <>
                <VehicleTableContainer
                    data={this.props.rejectedPolicies}
                    currentPage={this.props.currentPage}
                    policyChosen={this.props.policyChosen}
                    tableHeaders={this.state.rejectedPolicyTableHeaders}
                    action={this.proceedToEdit}
                    sort={false}
                    filter={false}
                />
                </>
                :null}
                {!this.props.noRejectedVehicles ?
                <VehicleTableContainer 
                    data={this.props.vehicleResults}
                    currentPage={this.props.currentPage}
                    policyChosen={this.props.policyChosen}
                    tableHeaders={this.props.tableHeaders}
                    sort={this.props.sort}
                    filter={this.props.filter}
                    action={this.props.showAmendVehicleFormNew}
                />
                :null}

            </>
           :null}

            {this.props.policyChange ?
                <PolicyEdit 
                    showComponent={this.props.showComponent}
                    rejectedPolicyDetails={this.state.rejectedPolicyDetails}
                    hideEdit={this.props.hideEdit}
                />
            :null}

            </>
        )
    }

}

export default RejectedAdmin