import React, { Component } from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import FormField from '../../atoms/FormField'
import Button from '../../atoms/Button'
import throbber from '../../../images/throbber.gif'
import UtilService from '../../../Util'
const Util = new UtilService();

class RequestEmail extends Component {
    constructor(props){
        super(props)
        this.state = {
            email: '',
            validEmail: '',
            requestSent: ''
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let emailTest = Util.emailValidator(value);
        if (emailTest){
            this.setState({validEmail: true})
        } else {
            this.setState({validEmail: false})
        }
        let errors = this.state.errors;
        this.setState({errors, [name]: value}, () => {})
    }

    sendRequest = () => {
        if (this.state.email !== ''){
            this.props.sendRequest(this.state.email)
        } else {
            window.alert('Please provide an email')
        }
    }


    render(){
        let buttonClass = 'button';
        if (this.state.validEmail){
            buttonClass += ' button-valid'
        }
        return (
            <>
                <div className="forgot-password-form">
                    <span className="password-icon" onClick={this.props.hidePasswordUI}><FaChevronLeft/></span>
                    <h1 className="header">Forgotten your Password?</h1>
                    <p>Enter your email, if you have an account you will recieve an email in your inbox containing a unique code.</p>
                    <FormField
                        type="text"
                        id="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                        label="Email"
                    />
                    {!this.props.waitingForResponse ?
                    <>
                    <Button className={buttonClass} id="sendRequest" onClick={this.sendRequest}>Send me a code</Button>
                    </>
                    :<>
                        <img src={throbber} alt="throb"/>
                    </>}
                    
                    
                </div>
            </>
        )
    }
}

export default RequestEmail