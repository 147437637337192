import * as Constants from './resources/index'
import * as Nav from './resources/navigation'
export default class Util {
    constructor(){
        this.getUserType = this.getUserType.bind(this)
        this.dateConvertor = this.dateConvertor.bind(this)
        this.emailValidator = this.emailValidator.bind(this)
        this.passwordValidatior = this.passwordValidatior.bind(this)
        this.linkCode = this.linkCode.bind(this)
        this.checkEmptyFields = this.checkEmptyFields.bind(this)
        this.goToTop = this.goToTop.bind(this)
        this.checkOpeningTimes = this.checkOpeningTimes.bind(this)

        window.addEventListener('click', this.checkTime)
        

    }

    checkTime = () => {
      var d = new Date();
      var h = d.getHours();
      var app = document.getElementById('portalApp')
      var shutDownWarning = document.getElementById('portalShutDownMessage')
      var role = localStorage.getItem('role')
      if (app !== null && shutDownWarning !== null && role === 'ROLE_CUSTOMER'){
        if (h === Constants.CLOSING_TIME){
         app.style.display = "none";
         shutDownWarning.style.display = "block"
         window.removeEventListener('mousemove', this.checkTime)
        }
      }

    }

    goToTop = () => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    checkOpeningTimes = () => {
      var d = new Date();
      var h = d.getHours();
      var day = d.getDay();
      if (day !== 7){
        // if its not a sunday carry on - SUNDAY CLOSED
        if (h >= Constants.OPEN_TIME && h <= Constants.CLOSING_TIME){
          // opening hours 7am till 8pm
          if (h === Constants.CLOSING_TIME){
            return false
          } else {
            return true // if it between 7 & 8 but not 20 allow login
          }
        } 
        else {
          return false // if hours is before 7 and below 20
        }
      } else {
        return false // shut on sunday
      }
    }

    getUserType = (props) => {
      const user = props.user.sub
      if (user.includes("@")){
        return true
      } if (!user.includes("@")){
        return false
      }
    }

    getCustomerType = (props) => {
      if (props.ichiban === 'true'){
        return 'ROLE_ICHIBAN'
      }
      if (props.trailer === 'true'){
        return 'ROLE_TRAILER'
      }
    }

    isReadOnlyUser = (props) => {
      if (props.role === 'ROLE_CUSTOMER_READ_ONLY'){
        return true
      } else {
        return false
      }
    }

    getNavigation = (userType, policyChosen, component) => {
      if (userType && !policyChosen && component !== 'UserAdminNav'){
        return Nav.ADMIN_NOT_CHOSEN
      }
      if (userType && !policyChosen && component === 'UserAdminNav'){
        return Nav.USER_ADMIN
      }
      if (userType && policyChosen){
        return Nav.ADMIN_CHOSEN
      }
      if (!userType){
        return Nav.ADMIN_CHOSEN
      }
    }

    dateConvertor = (onCover, offCover) => {
        return console.log(onCover + '' + offCover)
    }

    emailValidator = (email) => {
        const regex = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})/;
        return regex.test(email)
    }

    passwordValidatior = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    }

    linkCode = (code1, code2, code3, code4, code5, code6, code7, code8) => {
        let linkedCode = code1+code2+code3+code4+code5+code6+code7+code8
        return linkedCode
    }

    checkEmptyFields = (fields, fieldSet) => {
      let errorFields = []
      var fieldNames = fieldSet.filter(obj => {
        return obj.required === true
      })

      let requiredFields = fieldNames.map(field => {
        return field.fieldName
      })
  
      requiredFields.map(item => {
        if (fields[item] === undefined || fields[item].length < 1){
        errorFields.push(item)
        }
      })

      if (errorFields.length > 0){
        window.alert('Please fill in required fields')
        
      }
      return errorFields
    }

    logoutOnRefresh = (env) => {
      if (env === 'production'){
        window.onunload = () => {
          window.localStorage.removeItem('id_token')
          window.localStorage.removeItem('policyID')
        }
      }
    }
}