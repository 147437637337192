export const ADMIN_NOT_CHOSEN = [
    {
        "name": "Rejected MID",
        "open": false,
        "sub": false,
        "id": "Rejected"
    },
    {
        "name": "Policy Admin",
        "open": true,
        "sub": false,
        "id": "PolicyAdmin"
    },
    {
        "name": "User Admin",
        "open": false,
        "sub": false,
        "id": "UserAdminNav"
    },
    {
        "name": "Help",
        "open": true,
        "sub": false,
        "id": "help"
    }

]

export const USER_ADMIN = [
    {
        "name": "Create Admin",
        "id": "CreateAdmin",
        "open": true,
        "sub": true
    },
    {
        "name": "Admin Customer",
        "id": "CreateSuperCustomer",
        "open": true,
        "sub": true
    },
    {
        "name": "Create PIN",
        "id": "CreatePIN",
        "open": true,
        "sub": true
    },
    {
        "name": "Reset PIN",
        "id": "ResetPIN",
        "open": true,
        "sub": true
    }
]

export const ADMIN_CHOSEN = [
    {
        "name": "Add vehicle",
        "open": true,
        "sub": true,
        "id": "AddVehicle"
    },
    {
        "name": "Import",
        "open": true,
        "sub": true,
        "id": "CustomerImport"
    },
    {
        "name": "Remove Vehicles",
        "open": true,
        "sub": true,
        "id": "Remove"
    },
]