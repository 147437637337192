import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Login from './components/UserAdmin/Login/Login'
import AdminLogin from './components/UserAdmin/Login/AdminLogin'
import { BrowserRouter as Router, Route } from 'react-router-dom';

ReactDOM.render(
  <Router basename="/fleet-app">
      <div>
        <Route exact path='/' component={App} />
        <Route expact path="/login" component={Login} />
        <Route exact path="/admin" component={AdminLogin} />
      </div>
  </Router>,
  document.getElementById('app'))
