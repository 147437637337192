import React from 'react'
import { FaWindowClose, FaCheckCircle } from 'react-icons/fa';

export const POLICY_NUMBER = {
    "fieldName":"policyNumber",
    "fieldType":"text",
    "label":"Policy Number"
}

export const POLICY_SELECT = {
    "fieldName": "policySelect",
    "fieldType": "select",
    "label":"",
    "defaultSelect":"Select an option",
}

export const REGISTRATION_NUMER =  {
    "fieldName":'registrationNumber',
    "fieldType":"text",
    "label":"Vehicle Registration",
    "hint":"(Maximum 12 characters, no spaces)",
    "maxLength": 12,
    "pattern":"[A-Za-z]{3}",
    "icon":<FaWindowClose/>,
    "validIcon":<FaCheckCircle/>,
    "required": true
}

export const FOREIGN_REG_INDICATIOR = {
    "fieldName":'foreignRegIndicator',
    "fieldType":"select",
    "label":"Is this a foreign registration plate?",
    "defaultSelect":"No",
    "option1":"Yes"
}

export const TRAILER_CHECK = {
    "fieldName":'trailer',
    "fieldType":"select",
    "label":"Trailer?",
    "defaultSelect":"No",
    "option1":"Yes"
}

export const TRADE_PLATE = {
    "fieldName":"tradePlate",
    "fieldType":"select",
    "label":"Trade Plate",
    "hint":"U = Standard, T = Trade Plate",
    "readOnly":false,
    "defaultSelect":"Select an option",
    "option1":"U",
    "option2":"T"
}

export const TRADE_PLATE_READ_ONLY = {
    "fieldName":"tradePlateReadOnly",
    "fieldType":"select",
    "label":"Trade Plate",
    "hint":"U = Standard, T = Trade Plate",
    "readOnly":true,
    "defaultSelect":"Select an option",
    "option1":"U",
    "option2":"T"
}

export const MAKE = {
    "fieldName":'make',
    "fieldType":"text",
    "label":"Make",
    "maxLength": 15,
    "hint": "15 characters maximum, Enter 'Trade' for Trade Plates"
}

export const MODEL = {
    "fieldName":'model',
    "fieldType":"text",
    "label":"Model",
    "maxLength": 15,
}

export const MAKE_TRAILER = {
    "fieldName":'make',
    "fieldType":"text",
    "label":"Make",
    "maxLength": 15,
    "hint": "15 characters maximum, Enter 'Trade' for Trade Plates, Enter 'Trailer' for Trailers"
}

export const MODEL_TRAILER = {
    "fieldName":'model',
    "fieldType":"text",
    "label":"Model",
    "maxLength": 15,
    "hint": "15 characters maximum, Enter 'Trade' for Trade Plates, Enter 'Trailer' for Trailers"
}

export const COVER_START_DATE = {
    "fieldName": 'onCover',
    "fieldType": "text",
    "label": "On Date",
    "hint":"DD/MM/YYYY",
    "required": true
}

export const COVER_END_DATE = {
    "fieldName": "offCover",
    "fieldType": "text",
    "label": "Off Date",
    "hint":"DD/MM/YYYY",
    "required": true
}

export const LOCATION = {
    "fieldName": "location",
    "fieldType":"text",
    "label": "Location"
}

export const VEHICLE_DERIVATIVE = {
    "fieldName": "vehicleDerivative",
    "fieldType": "text",
    "label": "Vehicle Derivative",
    "maxLength": 15,
    "required": true
}

export const NAMED_DRIVERS = {
    "fieldName": "namedDriver",
    "fieldType": "text",
    "label": "Named Driver",
    "maxLength": 70,
    "required": true
}