export const VEH_ADMIN_TITLE = 'Vehicle Administration'
export const VEH_INTRO = 'To find a particular vehicle, you can either scroll down through the schedule or enter a Registration in the box below.  Highlight the row containing the vehicle and then press the Amend or Remove button as appropriate. All changes should be completed within 21 days of their effective date.'
export const VEH_INTRO_REJECTED = 'Vehicles rejected by MID, to amend a vehicle record click on a row you wish to amend.'
export const VEH_REMOVE_TITLE = 'Remove vehicle off Cover'
export const VEH_REMOVE_BUTTON = 'Remove'
export const VEH_IMPORT_TITLE = 'Import Vehicle(s)'
export const VEH_REMOVE_VEHICLES = 'Remove Vehicle(s)'
export const VEH_IMPORT_RESPONSE_TITLE = 'Imported Vehicle(s)'
export const VEH_REFRESH_BUTTON = 'Refresh Vehicles'
export const VEH_REFINE_BUTTON = 'Refine Vehicles'
export const VEH_ADD_VEHICLE_TITLE = 'Add new vehicle'
export const VEH_NEW_VEHICLE_BUTTON = 'Submit new vehicle'
export const VEH_AMEND_TITLE = 'Amend Vehicle'
export const VEH_AMEND_FOR_POLICY = 'For Policy:'
export const VEH_LAST_AMENDED = 'Last Amended:'
export const VEH_RE_SEND_TO_MID = 'Re-send to MID'
export const VEH_CURRENTLY = 'Currently:'
export const VEH_EFFECTIVE_DATE_BUTTON = 'Choose Effective Date'
export const VEH_OR = 'Or'
export const VEH_REMOVE_OFF_COVER_BUTTON = 'Remove Vehicle off cover'
export const VEH_BACK_BUTTON = 'Back'
export const VEH_CONFIRM = 'Confirm'
export const VEH_EXPORT_TEXT = "Clicking [Export Now] will export a list of vehicles based on the setting of the Active toggle and / or any Location entered above.  If you want a list of all vehicles which have been on cover during the selected period of insurance, please adjust these accordingly – see bottom left and also the Downloads folder on your computer for the resulting file."
export const VEH_EXPORT_REJECTED_TEXT = "Clicking [Export Now] will export a list of vehicles based on the table content. See bottom left and also the Downloads folder on your computer for the resulting file."
export const VEH_NO_EXPORT = 'No vehicles to Export'
export const VEH_REG_HINT = '12 characters maximum, no spaces'
export const VEH_TRADE_PLATE_HINT = 'U = Standard, T = Trade Plate'
export const VEH_MAKE_HINT = '15 characters maximum, Enter "Trade" for Trade Plates'
export const VEH_MODEL_HINT = '15 characters maximum, Enter "Trade" for Trade Plates'
export const VEH_MODEL_HINT_TRAILER = '15 characters maximum, Enter "Trade" for Trade Plates, Enter "Trailer" for Trailers'

// BUTTONS //
export const BULK_IMPORT = "Bulk import vehicles"
export const IMPORT_SPECIFIC = "Import policy specific"
export const IMPORT_VEHICLES_CUST = "Import Vehicle(s)"
export const ADD_VEHICLE = "Add vehicle"
export const EXPORT_VEHICLES = "Export Vehicle List (CSV)"
export const SEARCH_VEHICLES = "Vehicle Search"