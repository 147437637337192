import React, { Component } from 'react'
import { FaChevronLeft, FaCheckCircle } from 'react-icons/fa'
import Button from '../../atoms/Button'

class Success extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    render(){
        return (
            <>
                <div className="forgot-password-info">
                    <span className="password-icon" onClick={this.props.hidePasswordUI}><FaChevronLeft/></span>
                    <h1 className="header">{this.props.title}</h1>
                    <span className="password-info-icon success"><FaCheckCircle/></span>
                    <p>{this.props.message}</p>
                    <Button className="button" onClick={this.props.action}>{this.props.actionBtn}</Button>
                    {this.props.extraAction ?
                    <p className="extra-action" onClick={this.props.getAnotherCode}>{this.props.extraAction}</p>
                    :null}
                </div>
            </>
        )
    }
}

export default Success