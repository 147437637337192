import React, { Component } from 'react'
import CSVReader from 'react-csv-reader'
import * as Key from '../resources/translations'
import { FaWindowClose } from 'react-icons/fa';
import AuthService from '../../UserAdmin/services/AuthService'
const Auth = new AuthService();
const api = Auth.api

class RemoveVehicles extends Component {
    constructor(props){
        super(props)
        this.state = {
            policyNumber: ''
        }
    }

    toggleMessages = () => {
        this.props.toggleMessages()
    }

    showComponent = (component, sub) => {
        this.props.showComponent(this.props.userType, component, sub)
    }

    handleImportDataForAdmin = (data, policyNumber) => {
        if (this.props.userType){
            policyNumber = this.state.policyNumber
        }
        const newData = data.join('\n')
        this.setState({importData: newData, importFileIssue: false})
        this.testSendAdmin(this.state.importData, policyNumber)
    }

    testSendAdmin = (data, policyNumber) => {
        var xhr = new XMLHttpRequest();
        var self = this;
        xhr.open("POST", `${api}/remove/`+ this.props.policyId);
        xhr.setRequestHeader("authorization", Auth.getTokenForFrontEnd());
        xhr.send(data);
        document.getElementById("react-csv-reader-input").value = "";
        xhr.onload = function() {
            if (xhr.status === 409) {
                self.toggleMessages()
                var errorResponse = JSON.parse(this.response);
                self.setState({success: false, importErrorCode: xhr.status, importErrors: errorResponse, importErrorLength: xhr.response.length, errors: true})
                window.scrollTo(0,document.body.scrollHeight);
                self.setState({showImportUi: false})
            } 

            if (xhr.status === 200){
                self.toggleMessages()
              var myArr = JSON.parse(this.response);
              let today = new Date().toISOString().split('T')[0];
              self.setState({success: true, errors: false, importResponses: myArr, showErrors: false, importErrors: false, todaysDate: today,
                importDefaultError: false,importFileContentError: false, importFileFormatError: false, importFileDuplicateError: false})
                self.props.refreshVehiclesAfterImport()
            }
            if (xhr.status === 500){
                self.setState({success: false, errors: true, importErrorCode: xhr.status})
            }
        };
    }

    render(){
        return(
            <>
            <span id="close_amend" className="close-icon" onClick={() => {this.showComponent('Table', false)}}><FaWindowClose />CLOSE</span>

            
            <form>
                <h1 className="header">{Key.VEH_REMOVE_VEHICLES}</h1>

                <div className="FormField">
                    <br/>
                        <CSVReader onFileLoaded={(data) => this.handleImportDataForAdmin(data, this.props.policyNumber)} />
                    <br/>
                </div>
            </form>
            {this.state.errors && this.props.displayMessages ?
                    <ul>
                    {this.state.importErrors.map((importError, index) =>
                        <li key={index}>{importError}</li>
                    )}
                    </ul>
                :null}
                {this.state.importResponses && !this.state.errors ?
                <div className="importSuccess">
                    <ul>
                    {this.state.importResponses.map((response, index) =>
                        <li key={index}>{response}</li>
                    )}
                    </ul>    
                </div>
                                   
            :null}
            </>
        )
    }
}

export default RemoveVehicles