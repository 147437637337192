import React from 'react'
import { CSVLink } from 'react-csv'

export const ExportReactCSV = ({csvData, fileName, policyNumber}) => {
    return (
        <>
        {policyNumber !== undefined ?
        <CSVLink data={csvData} id="exportButton" filename={policyNumber + fileName+'.csv'}>Export Now</CSVLink>
        : 
        <CSVLink data={csvData} id="exportButton" filename={fileName+'.csv'}>Export Now</CSVLink>
        }
        
        </>
    )
}