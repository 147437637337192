import React from 'react'

const Header = props => {
    return (
        <>
        <h2 className="header">{props.value}</h2>
        </>
    )
}

export default Header