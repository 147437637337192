import React, { Component } from 'react';
import AuthService from '../services/AuthService';
import Header from '../../Header'
import Button from '../../atoms/Button'
import InputField from '../../atoms/InputField'
import Label from '../../atoms/Label'
import '../../../../src/App.css'
import '../../../../src/Button.css'
import Util from '../../../Util'
import ComponentDisplayMessage from '../../ComponentDisplayMessage';

class Login extends Component {
    constructor(){
        super();
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleFormSubmitForCustomer = this.handleFormSubmitForCustomer.bind(this);
        this.handleChangeForPolicyNumber = this.handleChangeForPolicyNumber.bind(this);
        this.Auth = new AuthService();
        this.U = new Util();
        this.state = {
            email: '',
            password: '',
            policyNumber: '',
            pinNumber: '',
            showAdminForm: false,
            showErrors: false,
            shutPortal: false
        }
    }

    componentDidMount(){
        if(this.Auth.loggedIn())
            this.props.history.replace('/');
            if (!this.U.checkOpeningTimes()){
                this.setState({shutPortal: false})
            } else {
                this.setState({shutPortal: false})
            }
    }

    

    render() {
        return (
            <>
            <Header showNav={false} />
            <ComponentDisplayMessage
                maintenanceWarning={this.state.maintenanceWarning}
                maintenanceDown={this.state.maintenanceDown}
            />
            <div className="LoginWrapper">
                <form id="customer-form">
                    <div className="FormField">
                        <Label for="policyNumber">Policy number:</Label>
                        <InputField
                            name="policyNumber"
                            className="policyNumber-field"
                            type="text"
                            value={this.state.policyNumber}
                            onChange={this.handleChangeForPolicyNumber}
                        />
                    </div>
                    <div className="FormField">
                        <Label for="pinNumber">PIN Number:</Label>
                        <InputField
                                name="pinNumber"
                                type="password"
                                value={this.state.pinNumber}
                                onChange={this.handleChange}
                            />
                    </div>
                    {this.state.showErrors ?
                        <p className="errorText">Please check the reference data entered and try again or contact the support team via the details below</p>
                    :null}
                    {this.state.lockedOutLogin ?
                        <p className="errorText">Too many attempted logins, your account has been temporarily locked, please try again later, or contact the support team via the details below</p>
                    :null}
                    {!this.state.shutPortal ?
                    <>
                    <Button className="button" onClick={this.handleFormSubmitForCustomer}>Log In</Button>
                    </>
                    :null}
                    <div className="HelpText">
                        <p>Please contact the Support team with any login issues via:</p>
                        <p><b>Telephone number:</b> 0330 022 6513</p>
                        <p>or</p>
                        <p><b>E-mail:</b> fleetmid@aioinissaydowa.eu</p>
                        <p>Lines are open for help between <b>08:30 to 17:30 - Monday to Friday</b></p>
                        <p><b>The application will be available between 07:00 and 20:00 - Monday to Saturday</b></p>
                    </div>
                    </form>                 
            </div>
            </>
        );
    }

    handleChangeForPolicyNumber(e){
        this.setState(
            {
                [e.target.name]: e.target.value.toUpperCase()
            }
        )

    }

    handleChange(e){
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )

    }

    handleFormSubmit(e){
        e.preventDefault();
        this.Auth.login(this.state.email,this.state.password)
            .then(res =>{
               this.props.history.replace('/');
            })
            .catch(err =>{
                this.setState({showErrors: true})
            })
    }

    handleFormSubmitForCustomer(e){
        e.preventDefault();
        let policyNumber = this.state.policyNumber
        policyNumber.toUpperCase();
        this.Auth.loginForCustomer(policyNumber,this.state.pinNumber)
            .then(res => {
                this.props.history.replace('/');
            })
            .catch(err => {
                let errorToken = localStorage.getItem("error")
                if (errorToken === 'locked out') {
                    this.setState({lockedOutLogin: true, showErrors: false})
                    localStorage.removeItem("error")
                } else {
                    this.setState({showErrors: true, lockedOutLogin: false})
                }
            })
    }
}

export default Login;