import React, {Component} from 'react'
import InputField from './InputField'

class FormField extends Component {
    render(){
        return (
            <>
                <div className="row" id={this.props.id + '-row'}>
                    <div className="col-50">
                        {this.props.hint && !this.props.readOnly ?
                        <>
                        <label className="withHint">{this.props.label}</label><br/><span className="hint-text">{this.props.hint}</span>
                        </>
                        :<label>{this.props.label}</label>}
                    </div>
                    <div className="col-75">
                        {this.props.editable ?
                            <>
                            <span onClick={this.props.function}>{this.props.editIcon}</span>
                            </>
                            :null}
                        {!this.props.readOnly ?
                        <>
                        {this.props.type === 'text' || this.props.type === 'email' || this.props.type === 'password' ?
                        <>
                            <InputField type={this.props.type} id={this.props.id} pattern={this.props.pattern} name={this.props.id} className={this.props.className} value={this.props.value} onChange={this.props.onChange} maxLength={this.props.maxLength}/>
                            
                            {this.props.icon ?
                                <span id="validRegorNot" className={this.props.iconClass}>
                                    {this.props.validReg ?
                                    <>
                                    {this.props.validIcon}
                                    </>
                                    :
                                    <>
                                    {this.props.icon}
                                    </>}
                                </span>
                            :null}
                        </>
                        :null}
                        {this.props.type === 'select' ?
                        <>
                        <select id={this.props.id} name={this.props.id} value={this.props.value} onChange={this.props.onChange} onClick={this.props.onClick}>
                            <>
                                {this.props.location ?
                                <>
                                    {this.props.options}
                                </>
                                :<>
                                <option defaultValue="">{this.props.defaultSelect}</option>
                                {this.props.option1 ? <option value={this.props.option1}>{this.props.option1}</option>:null}
                                {this.props.option2 ? <option value={this.props.option2}>{this.props.option2}</option>:null}
                                {this.props.option3 ? <option value={this.props.option3}>{this.props.option3}</option>:null}
                                {this.props.option4 ? <option value={this.props.option4}>{this.props.option4}</option>:null}
                                </>}
                            </>
                        </select>
                        </>                 
                        :null}
                        {this.props.type === 'list' ?
                        <>
                            <input list={this.props.list} id={this.props.id} name={this.props.id} />

                            <datalist id={this.props.list}>
                                <>
                                    {this.props.options}
                                </>
                            </datalist>
                        </>
                        :null}
                        {this.props.type === 'checkbox' ?
                        <>
                        <label className="switch">
                            <input type={this.props.type} id={this.props.id} name={this.props.id} checked={this.props.checked} onChange={this.props.onChange}/>
                            <span className="slider round"></span>
                        </label>
                        </>
                        :null}
                        </>
                        :<p className="read-only-text" id={this.props.id + '-read-only'}>{this.props.value}</p>}
                        
                    </div>
                </div>
            </>
        )
    }
}

export default FormField