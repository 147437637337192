import React, { Component } from 'react'
import axios from 'axios'
import * as Key from './resources/translations';
import AuthService from '../UserAdmin/services/AuthService'
import VehicleService from './services/VehicleService'
import DisplayBlock from '../DisplayBlock'
import AddNewVehicle from './AddNewVehicle'
import AmendVehicle from './AmendVehicle'
import ImportVehicles from './import/ImportVehicles';
import * as Role from  '../../resources/roles'
import RejectedAdmin from '../MID/RejectedAdmin'
import 'react-base-table/styles.css'
import * as Table from '../../resources/tableFile'
import VehicleTableContainer from '../Vehicle/VehicleTableContainer'
const Auth = new AuthService();
const api = Auth.api

class VehicleAdmin extends Component {
    constructor(props){
        super(props)
        this.Vehicle = new VehicleService();
        this.state = {
            addANewVheicle: false,
            amendVehicle: false,
            showImportUi: false,
            bulkImportUi: false,
            currentDate: '',
            currentID: '',
            currentVehicleReg: '',
            currentTradePlate: '',
            currentMake: '',
            curentModel: '',
            currentOnCover: '',
            currentOffCover: '',
            currentLocation: '',
            newPolicyNumber: '',
            newVehicleReg: '',
            newTradePlate: '',
            newMake: '',
            newModel: '',
            newOnCover: '',
            newOffCover: '',
            newLocation: '',
            dOffDate: '',
            importPolicyNumber: '',
            showErrors: false,
            showSuccess: false,
            duplicateVehicle: false,
            vehicleStartDateError: false,
            importRefresh: false,
            importResponses: false,
            importFileContentError: false,
            importFileDuplicateError: false,
            importFileFormatError: false,
            importDefaultError: false,
            importMessage: true,
            midTable: false,
            rejectedVehicles: true,
            rejectedPolicies: false,
            rejectedPolicyTableVisible: true,
            rejectedView: ''
        }
    }

    componentDidMount(){
       this.setState({currentPage: 'Table'}, () => {
            if (this.props.policyChosen === undefined && this.props.userType){
                this.setState({rejectedView: true})
            }
       })

       if (this.props.policyChosen && this.props.userType || !this.props.userType){
            this.setState({tableHeaders: Table.CUSTOMER_STANDARD, sort: true, filter: true})
       }
       if (!this.props.policyChosen && this.props.userType){
           this.setState({tableHeaders: Table.ADMIN_REJECTED, sort: false, filter: false})
       }
    }

    addANewVheicleUi = () => {
        this.setState({addANewVheicle: !this.state.addANewVheicle, policySpecificImportUi: false, amendVehicle: false, showImportUi: false, refineVehicles: false, midTable:false, aVehicleReg: '', aTradePlate: '', aOnDate: '', aOffDate: '', aLocation: '', showErrors: false, importFileIssue: false, bulkImportUi:false})
    }

    showBulkImport = () => {
        const element = document.getElementById('logoutbutton');
        if (!this.state.bulkImportUi){
            element.scrollIntoView({behavior: 'smooth'});
        }
        this.setState({bulkImportUi: !this.state.bulkImportUi, policySpecificImportUi: false, importFileContentError: false, midTable: false, addANewVheicle: false})
    }

    policySpecificImport = () => {
        this.setState({displayMessages: false})
        const element = document.getElementById('logoutbutton');
        if (!this.state.policySpecificImportUi){
            element.scrollIntoView({behavior: 'smooth'});
        }
        this.setState({policySpecificImportUi: !this.state.policySpecificImportUi, bulkImportUi: false, importFileContentError: false,
            midTable:false, addANewVheicle: false, importErrors: false, importSuccess: false})
    }

    showRemoveVehicles = () => {
        this.setState({displayMessages: false})
        const element = document.getElementById('logoutbutton');
        if (!this.state.policySpecificImportUi){
            element.scrollIntoView({behavior: 'smooth'});
        }
        this.setState({removeVehicles: !this.state.removeVehicles, bulkImportUi: false, policySpecificImportUi: false, importFileContentError: false,
            midTable:false, addANewVheicle: false, importErrors: false, importSuccess: false})
    }

    toggleMessages = () => {
        this.setState({displayMessages: true})
    }

    customerImport = () => {
        const element = document.getElementById('logoutbutton');
        if (!this.state.policySpecificImportUi){
            element.scrollIntoView({behavior: 'smooth'});
        }
        this.setState({policySpecificImportUi: !this.state.policySpecificImportUi, amendVehicle: false, midTable: false, addANewVheicle: false})
    }

    showMidTable = () => {
        this.setState({midTable: !this.state.midTable, addANewVheicle: false, policySpecificImportUi: false, showImportUi: false, bulkImportUi: false})
    }

    searchVehicleUi = () => {
        this.setState({refineVehicles: !this.state.searchVehicleUi, addANewVheicle: false,})
    }

    searchVehicleUiNotChosen = () => {
        this.setState({refineVehicles: !this.state.refineVehicles})
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({errors, [name]: value}, () => {
            
        })
    }

    showAmendVehicleForm = (vehicleId, policyNumber, registration, vehicleMake, vehicleModel, tradePlate, onCover, offCover, location, midStatus, updated, record_type, midSent, foreignReg, vehicleDerivative, namedDriver, trailer ) => {
        if (midStatus === 'Investigate' && !this.props.userType){
            // dont show amend form
        } else {
            if (this.props.adminRole !== Role.UNDERWRITER && this.props.readOnlyUser !== true){  
                this.setState({currentID: vehicleId, currentPolicyNumber: policyNumber, currentVehicleReg: registration,
                currentTradePlate: tradePlate, currentMake: vehicleMake, currentModel: vehicleModel, currentOnCover: onCover,
                currentOffCover: offCover, currentLocation: location, currentUpdatedTime: updated, currentRecordType: record_type,
                currentMidStatus: midStatus, currentMidSent: midSent, currentForeignReg: foreignReg, currentVehicleDerivative: vehicleDerivative, currentNamedDriver: namedDriver, currentTrailer: trailer}) 
                let dToday = new Date().toISOString().split('T')[0];
                this.setState({ rejectedPolicyTableVisible: false, amendVehicle: true, importFileIssue: false, addANewVheicle: false, showImportUi: false, newVehicleReg: '', newTradePlate: '', newMake: '', newModel: '', newOnCover: '', newOffCover: '', newLocation: '', showErrors: false, dOffDate: dToday})
                this.props.amendUiState()
            }
        }

    }

    showAmendVehicleFormNew = (row ) => {
        this.props.showComponent(this.props.userType, 'Amend', true, true)
        if (row.midStatus === 'Investigate' && !this.props.userType){
            // dont show amend form
        } else {
            if (this.props.adminRole !== Role.UNDERWRITER && this.props.readOnlyUser !== true){
                this.setState({currentID: row.vehicleId, currentPolicyNumber: row.policyNumber, currentVehicleReg: row.registration,
                currentTradePlate: row.tradePlate, currentMake: row.vehicleMake, currentModel: row.vehicleModel, currentOnCover: row.vehicleCoverStartDate,
                currentOffCover: row.vehicleCoverEndDate, currentLocation: row.location, currentUpdatedTime: row.lastUpdated, currentRecordType: row.recordType,
                currentMidStatus: row.midStatus, currentMidSent: row.midSent, currentForeignReg: row.foreignReg, currentVehicleDerivative: row.vehicleDerivative, currentNamedDriver: row.namedDriver, currentTrailer: row.trailer})
                let dToday = new Date().toISOString().split('T')[0];
                this.setState({ rejectedView: true, rejectedPolicyTableVisible: false, amendVehicle: true, importFileIssue: false, addANewVheicle: false, showImportUi: false, newVehicleReg: '', newTradePlate: '', newMake: '', newModel: '', newOnCover: '', newOffCover: '', newLocation: '', showErrors: false, dOffDate: dToday})
                this.props.amendUiState()
            }
        }
    }

    closeSearchVehicles = () => {
        this.setState({refineVehicles: false})
    }

    closeRemoveVehicle = () => {
        this.setState({deleteUI: false})
    }
    
    closeAddVehicle = () => {
        if (this.props.userType){
            this.setState({newPolicyNumber: ''})
        }
        this.props.addANewVehicleUi()
        this.setState({addANewVheicle: false, newVehicleReg: '', newTradePlate: '', newModel: '', newMake: '', newLocation: '', newOnCover: '', newOffCover: ''})
    }


    triggerMID = (event) => {
        event.preventDefault()
        this.setState({showErrors: false})
        axios.post(`${api}/resetMid/`+this.state.currentID, {},
            {
                headers: {
                        Authorization: Auth.getTokenForFrontEnd()
                }
            }
            ).then(() => {
                this.setState({amendVehicle: false})
                if (this.props.policyChosen){
                    this.props.policyChosenUpdateMethod(this.props.policyId)
                } else {
                    this.props.updateMethod(this.props.policyId)
                }
                this.setState({showSuccess: true, showErrors: false})
                    window.scrollTo(0, 0);
            })
            .catch(err => {
                this.setState({showErrors: true, showSuccess: false, triggerErrorCode: err.response.status, triggerErrorMsg: err.response.data, amendVehicle: false})
                window.scrollTo(0, 0);
            })
    }

    componentDidUpdate() {
        if (this.state.policySpecificImportUi || this.state.bulkImportUi){
            const element = document.getElementById('react-csv-reader-input');
            element.scrollIntoView({behavior: 'smooth'})
        }
    }

    closeMessage = () => {
        this.setState({showErrors: false, showSuccess: false})
    }

    closeImportUis = () => {
        this.setState({bulkImportUi: false, policySpecificImportUi: false, removeVehicles: false})
        if (!this.props.userType){
            this.props.closeCustomerImport()
            this.props.closeCustomerRemoveImport()
        }
        if (this.props.userType){
            this.props.closeAdminImports()
        }
    }

    getActive = (value) => {
        this.setState({active: value})
    }

    showRejectedVehicles = () => {
        this.Vehicle.getRejectedVehicles()
        this.setState({rejectedPolicies: false, rejectedVehicles: true})
    }

    proceedToEdit = (data) => {
        this.setState({policyChange: true})
    }

    hideEdit = () => {
        this.setState({policyChange: false})
        this.props.updateRejectedPolicies()
    }  

    render(){

        let vehicleSection = 'vehicle-section'
        if (this.props.policyChosen || this.props.userType){
            vehicleSection += '-policy-chosen'
        }
        if (this.props.readOnlyUser){
            vehicleSection += '-ty-user'
        }
        return (
            <div className="section" id={vehicleSection}>
            {this.state.showErrors || this.state.showSuccess ?
                <DisplayBlock
                    closeDisplayMessage={this.closeMessage}
                    errors={this.state.showErrors} 
                    success={this.state.showSuccess} 
                    duplicateVehicle={this.state.duplicateVehicle}
                    endDateError={this.state.endDateError}
                    vehicleStartDateError={this.state.startDateError}
                    importRefresh={this.state.importRefresh}
                    importDefaultError={this.state.importDefaultError}
                    importFileContentError={this.state.importFileContentError}
                    importFileFormatError={this.state.importFileFormatError}
                    importFileDuplicateError={this.state.importFileDuplicateError}
                    nothingToUpdate={this.state.nothingToUpdate}
                />
            :null}

            {this.props.currentPage === 'Rejected' && this.props.userType && !this.props.policyChosen || this.props.currentPage === 'Rejected' && this.props.userType && this.props.policyChosen === undefined ?
                <RejectedAdmin
                    currentPage={this.props.currentPage}
                    policyChosen={this.props.policyChosen}
                    rejectedPolicies={this.props.rejectedPolicies}
                    policyChange={this.state.policyChange}
                    noRejectedVehicles={this.props.noRejectedVehicles}
                    noRejectedPolicies={this.props.noRejectedPolicies}
                    policyChange={this.state.policyChange}
                    proceedToEdit={this.proceedToEdit}
                    showComponent={this.showComponent}
                    hideEdit={this.hideEdit}
                    rejectedPolicyTableVisible={this.state.rejectedPolicyTableVisible}
                    vehicleResults={this.props.vehicleResults}
                    tableHeaders={this.state.tableHeaders}
                    showAmendVehicleFormNew={this.showAmendVehicleFormNew}
                />
            :null}

            {(this.props.currentPage === 'Table' || this.props.currentPage === 'MidTable') ?
                <VehicleTableContainer 
                    data={this.props.vehicleResults}
                    adminRole={this.props.adminRole}
                    readOnlyUser={this.props.readOnlyUser}
                    currentPage={this.props.currentPage}
                    policyChosen={this.props.policyChosen}
                    userType={this.props.userType}
                    content={Key.VEH_INTRO}
                    rejectedContent={Key.VEH_INTRO_REJECTED}
                    policyStatus={this.props.policyStatus}
                    showComponent={this.showComponent}
                    tableHeaders={this.state.tableHeaders}
                    filter={this.state.filter}
                    sort={this.state.sort}
                    setTableHeaders={this.setTableHeaders}
                    activeFunction={this.props.activeFunction}
                    action={this.showAmendVehicleFormNew}
                    newVehicleId={this.props.newVehicleId}
                    vehicleAdded={this.props.vehicleAdded}
                    resetVehicleAdded={this.props.resetVehicleAdded}
                    midPolicyData={this.props.midPolicyData}
                    searchReg={this.props.searchReg}
                    searchLocation={this.props.searchLocation}
                />
            :null}
            
            {this.props.currentPage === 'Amend' ?
                <AmendVehicle
                    userType={this.props.userType}
                    adminRole={this.props.adminRole}
                    customerType={this.props.customerType}
                    policyChosen={this.props.policyChosen}
                    showComponent={this.props.showComponent}
                    triggerMID={this.triggerMID}
                    policyNumber={this.state.currentPolicyNumber}
                    currentId={this.state.currentID}
                    currentUpdatedTime={this.state.currentUpdatedTime}
                    currentVehicleReg={this.state.currentVehicleReg}
                    currentTradePlate={this.state.currentTradePlate}
                    currentMake={this.state.currentMake}
                    currentModel={this.state.currentModel}
                    currentOnCover={this.state.currentOnCover}
                    currentOffCover={this.state.currentOffCover}
                    currentLocation={this.state.currentLocation}
                    effectiveDate={this.state.effectiveDate}
                    currentRecord_type={this.state.currentRecordType}
                    currentMidStatus={this.state.currentMidStatus}
                    currentMidSent={this.state.currentMidSent}
                    currentForienRegIndictor={this.state.currentForeignReg}
                    dOffDate={this.state.dOffDate}
                    removeVehicle={this.removeVehicle}
                    amendVehicle={this.amendVehicle}
                    showDeleteUI={this.showDeleteUI}
                    updateMethod={this.props.updateMethod}
                    policyId={this.props.policyId}
                    policyChosenUpdateMethod={this.props.policyChosenUpdateMethod}
                    currentVehicleDerivative={this.state.currentVehicleDerivative}
                    currentNamedDriver={this.state.currentNamedDriver}
                    currentTrailer={this.state.currentTrailer}
                />
            :null}

            {this.props.currentPage === 'AddVehicle' || this.props.addANewVheicle ?
                <div id="add-vehicle-container">
                    <AddNewVehicle
                        customerType={this.props.customerType}
                        policyExpiryDate={this.props.policyExpiryDate}
                        locations={this.props.locations}
                        closeAmendVehicle={this.closeAddVehicle}
                        addANewVehicleUi={this.props.addANewVehicleUi}
                        userType={this.props.userType}
                        policyChosen={this.props.policyChosen}
                        newPolicyNumber={this.props.policyNumber}
                        newVehicleReg={this.state.newVehicleReg} 
                        newTradePlate={this.state.newTradePlate} 
                        newMake={this.state.newMake}
                        newModel={this.state.newModel} 
                        newLocation={this.state.newLocation} 
                        newOnCover={this.state.newOnCover} 
                        newOffCover={this.state.newOffCover}
                        handleChange={this.handleChange}
                        policyChosenUpdateMethod={this.props.policyChosenUpdateMethod}
                        submitNewVehicleForCustomer={this.submitNewVehicleForCustomer}
                        submitNewVehicleforAdmin={this.submitNewVehicleforAdmin}
                        updateMethod={this.props.updateMethod}
                        showComponent={this.props.showComponent}
                        policyId={this.props.policyId}
                        policyExpiryDate={this.props.policyExpiryDate}
                    />  
                </div>
            :null}
            
            <ImportVehicles
                userType={this.props.userType}
                policyNumber={this.props.policyNumber}
                policyChosen={this.props.policyChosen}
                closeImportUis={this.closeImportUis}
                bulkImportUi={this.state.bulkImportUi}
                removeVehicles={this.props.removeVehicles}
                adminRemoveVehicles={this.state.removeVehicles}
                updateMethod={this.props.updateMethod}
                policyChosenUpdateMethod={this.props.policyChosenUpdateMethod}
                toggleMessages={this.toggleMessages}
                customerImport={this.props.customerImport}
                currentPage={this.props.currentPage}
                showComponent={this.props.showComponent}
                policyId={this.props.policyId}
                policyState={this.props.policyState}
                customerType={this.props.customerType}
            />            
            </div>
        )

    }
}

export default VehicleAdmin