import React, { Component } from 'react'
import CSVReader from 'react-csv-reader'
import * as Key from '../resources/translations'
import { FaWindowClose } from 'react-icons/fa';
import AuthService from '../../UserAdmin/services/AuthService'
const Auth = new AuthService();
const api = Auth.api

class BulkImport extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    toggleMessages = () => {
        this.props.toggleMessages()
    }

    handleImportDataForAdmin = (data) => {
        let headers
        let result
        var checkWording = data.toString().toUpperCase()
        if (checkWording.includes('REGISTRATION')){
            headers = data.shift().join(',')
            result = data.map(function (row) {
                    if (row[10] !== undefined && row[11] !== undefined){
                        var valueSet1 = row[10]
                        var valueSet2 = row[11]
                        row.pop(0)
                        row.pop(0)
                        var removedCommas = valueSet1.replace(/,/g, "")
                        var removedCommas2 = valueSet2.replace(/,/g, "")
                        row.push(removedCommas)
                        row.push(removedCommas2);
                        return row.map(function(field){ return '"' + field + '"';})
                    }             
                     else {
                        return row.map(function(field){ return '"' + field + '"';})
                    }
    
             })
        } else {
            result = data.map(function (row) {
                    if (row[10] !== undefined && row[11] !== undefined){
                        var valueSet1 = row[10]
                        var valueSet2 = row[11]
                        row.pop(0)
                        row.pop(0)
                        var removedCommas = valueSet1.replace(/,/g, "")
                        var removedCommas2 = valueSet2.replace(/,/g, "")
                        row.push(removedCommas)
                        row.push(removedCommas2);
                        return row.map(function(field){ return '"' + field + '"';})
                                
                    } else {
                        return row.map(function(field){ return '"' + field + '"';})
                    }
    
             })
        }
        if (checkWording.includes('Registration')){
            this.setState({importData: headers+'\n'+result.join('\n'), importFileIssue: false})
        } else {
            this.setState({importData: result.join('\n'), importFileIssue: false})
        }
       this.testSendAdmin(this.state.importData)
    }

    testSendAdmin = (data) => {
        var xhr = new XMLHttpRequest();
        var self = this;
        xhr.open("POST", `${api}/import/`);
        xhr.setRequestHeader("authorization", Auth.getTokenForFrontEnd());
        xhr.send(data);
        document.getElementById("react-csv-reader-input").value = "";
        xhr.onload = function() {
            if (xhr.status === 409) {
                self.toggleMessages()
                var errorResponse = JSON.parse(this.response);
                self.setState({success: false, importErrorCode: xhr.status, importErrors: errorResponse, importErrorLength: xhr.response.length, errors: true})
                window.scrollTo(0,document.body.scrollHeight);
                self.setState({showImportUi: false})
            } 

            if (xhr.status === 200){
                self.toggleMessages()
              var myArr = JSON.parse(this.response);
              let today = new Date().toISOString().split('T')[0];
              self.setState({success: true, errors: false, importResponses: myArr, showErrors: false, importErrors: false, todaysDate: today,
                importDefaultError: false,importFileContentError: false, importFileFormatError: false, importFileDuplicateError: false})
                self.props.refreshVehiclesAfterImport()
            }
            if (xhr.status === 500){
                self.setState({success: false, errors: true, importErrorCode: xhr.status})
            }
        };
    }

    render(){
        return(
            <>
            <span id="close_amend" className="close-icon" onClick={this.props.closeImportUis}><FaWindowClose />CLOSE</span>
            <form>
                <h1 className="header">{Key.VEH_IMPORT_TITLE}</h1>
                <div className="FormField">
                    <br/>
                        <CSVReader onFileLoaded={(data) => this.handleImportDataForAdmin(data)} />
                    <br/>
                </div>
            </form>
            {this.state.errors && this.props.displayMessages ?
                    <ul>
                    {this.state.importErrors.map((importError, index) =>
                        <li key={index}>{importError}</li>
                    )}
                    </ul>
                :null}
                {this.state.importResponses && !this.state.errors ?
                <div className="importSuccess">
                    <ul>
                    {this.state.importResponses.map((response, index) =>
                        <li key={index}>{response}</li>
                    )}
                    </ul>    
                </div>
                                   
                :null}
            </>
        )
    }
}

export default BulkImport