import React, {useState} from 'react';
import { FaBars, FaSignOutAlt } from 'react-icons/fa';
import VehicleService from './Vehicle/services/VehicleService'

const Header = props => {
    const Vehicle = new VehicleService()
    const [userState] = useState(props);
    function openNav() {
        if (props.userType && !props.policyChosen){
            Vehicle.getRejectedVehicles()
            .then(result => {
                props.setExport(Vehicle.getExportData(result, props.userType, undefined))
            })
        } else {
            if (props.currentPage === 'Table'){
                if (props.policyStatus !== 'EXPIRED'){
                    var active = "true"
                    var registration = document.querySelector("#registration > div > input").value
                    var location = document.querySelector("#location > div > input").value
                    active = document.getElementById("active").getAttribute("interaction")
                    Vehicle.searchVehiclesForExport(props.policyId, registration, location, active)
                    .then((result) => {
                        props.setExport(Vehicle.getExportData(result, props.userType, props.customerType))
                    })
                }
            }
            if (props.currentPage === 'ExpiredVehicles'){
                Vehicle.policyVehicleFullHistory(props.policyId)
                .then((result) => {
                    props.setExport(Vehicle.getExportData(result, props.userType, props.customerType))
                })
            }
        }
        
        document.getElementById("sideNav").style.width = "450px";
        // eslint-disable-next-line
        document.getElementById('main-view').classList.add("untouchable")

        
    }
    return (
        <div className="Header">
            <span onClick={props.handleLogout}>{props.readOnlyUser ? <FaSignOutAlt/>:null}</span>
            {userState.showNav ?
                <span id="open" onClick={openNav}><FaBars/></span>
            :null}
                
            <h1>Commercial Motor MID Extranet</h1>
        </div>
    )
}

export default Header