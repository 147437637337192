import React, {Component} from 'react'

class SummaryText extends Component {
    render(){
        return(
            <>
            {this.props.userType ?
                <>
                    {this.props.policyChosen ?
                        <p>{this.props.content}</p>
                    :
                    <>
                    <h1 className="header">Rejected Vehicles</h1>
                    <p>{this.props.rejectedContent}</p>
                    </>}
                </>
            :<p>{this.props.content}</p>}
            </>
        )
    }
}

export default SummaryText