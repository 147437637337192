export default class VehicleService {
    constructor(){
        this.fetch = this.fetch.bind(this)
        this.getRejectedVehicles = this.getRejectedVehicles.bind(this)
        this.getRejectedVehiclesNew = this.getRejectedVehiclesNew.bind(this)
        this.getLocations = this.getLocations.bind(this)
        this.policyVehicleSearch = this.policyVehicleSearch.bind(this)
        this.policyVehicleUpdate = this.policyVehicleUpdate.bind(this)
        this.amendVehicle = this.amendVehicle.bind(this)
        this.removeVehicle = this.removeVehicle.bind(this)
        this.getExportData = this.getExportData.bind(this)
        this.api = ''
        this.checkForNull = this.checkForNull.bind(this)
        if (process.env.NODE_ENV === 'development'){
            this.api = process.env.REACT_APP_SECRET_KEY
        }
        if (process.env.NODE_ENV === 'production'){
            this.api = process.env.REACT_APP_SECRET_KEY
        }
    }

    getRejectedVehicles() {
        return this.fetch(`${this.api}/rejected`, {
            method: 'GET'
        }).then(res => {
            if (!Array.isArray(res) && Object.keys(res).length === 0) {
                throw new Error('Empty Response');
            }
            return res
        })
        .catch(error => {
            return ''
        })
    }

    getRejectedVehiclesNew() {
        this.fetch(`${this.api}/rejected`, {
            method: 'GET'
        }).then(res => {
            return res
        })
        .catch(error => {
            if (error.status === 404){
                return "No rejected vehicles"
            } else {
                return error.status
            }
        })
    }

    getLocations(policyNumber) {
        return this.fetch(`${this.api}/vehicle/locations?policy=`+policyNumber, {
            method: 'GET'
        }).then(res => {
            if (!Array.isArray(res) && Object.keys(res).length === 0) {
                throw new Error('Empty Response');
              }
            return res
        })
    }
    policyVehicleSearch(policyId) {
        return this.fetch(`${this.api}/vehicle/`+policyId+'?active=true', {
            method: 'GET'
        }).then(res => {
            return res
        })
    }

    searchVehiclesForExport(policyId, registration, location, active) {
        return this.fetch(`${this.api}/vehicle/`+policyId+'?registration='+registration+'&location='+location+'&active='+active, {
            method: 'GET'
        }).then(res => {
            return res
        })
    }

    policyVehicleSearchExpired(id) {
        return this.fetch(`${this.api}/vehicle/`+id+'?expired=true', {
            method: 'GET'
        }).then(res => {
            return res
        })
    }

    policyVehicleFullHistory(id) {
        return this.fetch(`${this.api}/vehicle/`+id+'?active=false', {
            method: 'GET'
        }).then(res => {
            return res
        })
    }

    policyVehicleSearchInactive(id) {
        return this.fetch(`${this.api}/vehicle/`+id+'?active=false', {
            method: 'GET'
        }).then(res => {
            return res
        })
    }

    policyVehicleUpdate(id, active) {
        return this.fetch(`${this.api}/vehicle/`+id+'?active='+active, {
            method: 'GET'
        }).then(res => {
            return res
        })

    }

    searchVehiclesFalse(id, location, registration) {
        return this.fetch(`${this.api}/vehicle/`+id+'?registration='+registration.toUpperCase()+'&location='+location+'&active=false', {
            method: 'GET'
        }).then(res => {
            return res
        })
    }

    searchVehiclesTrue(id, location, registration) {
        return this.fetch(`${this.api}/vehicle/`+id+'?registration='+registration.toUpperCase()+'&location='+location+'&active=true', {
            method: 'GET'
        }).then(res => {
            return res
        })
    }

    amendVehicle(currentID, registrationNumber, tradePlate, make, model, effectiveDate, offCover, location){
        return this.fetch(`${this.api}/vehicle/`+currentID, {
            method: 'PUT',
            body: JSON.stringify({
                registration: registrationNumber,
                tradePlate: tradePlate,
                vehicleMake: make,
                vehicleModel: model,
                vehicleCoverStartDate: effectiveDate,
                vehicleCoverEndDate: offCover,
                location: location
            },
            {
                headers: {
                    Authorization: this.getTokenForFrontEnd()
                }
            })

        }).then(res => {
            return res
        })
    }

    removeVehicle(id, day,month,year){
        return this.fetch(`${this.api}/vehicle/`+id+`/`+year+month+day, {
            method: 'DELETE',
        }).then(res => {
            return res
        })
    }

    checkForNull(item){
        if (item !== undefined){
            return item
        } else {
            // do nothing
        }
    }

    getExportData(res, userType, customerType){
        let exportObjectData = ''
        if (userType){
            if (customerType === 'ROLE_ICHIBAN'){
                exportObjectData = res.map(item => { return {
                    'Policy Number': this.checkForNull(item.policyNumber),
                    'Registration': this.checkForNull(item.registration),
                    'Trade Plate': this.checkForNull(item.tradePlate),
                    'Make': this.checkForNull(item.vehicleMake),
                    'Model': this.checkForNull(item.vehicleModel),
                    'On Date': this.checkForNull(item.vehicleCoverStartDate),
                    'Off Date': this.checkForNull(item.vehicleCoverEndDate),
                    'Location': this.checkForNull(item.location),
                    'Named Driver': this.checkForNull(item.namedDriver),
                    'Vehicle Derivative': this.checkForNull(item.vehicleDerivative),
                    'Last Updated': this.checkForNull(item.lastUpdated),
                    'Mid Status': this.checkForNull(item.midStatus),
                    'Active': this.checkForNull(item.displayActive)
                }})
            } else {
                exportObjectData = res.map(item => { return {
                    'Policy Number': this.checkForNull(item.policyNumber),
                    'Registration': this.checkForNull(item.registration),
                    'Trade Plate': this.checkForNull(item.tradePlate),
                    'Make': this.checkForNull(item.vehicleMake),
                    'Model': this.checkForNull(item.vehicleModel),
                    'On Date': this.checkForNull(item.vehicleCoverStartDate),
                    'Off Date': this.checkForNull(item.vehicleCoverEndDate),
                    'Location': this.checkForNull(item.location),
                    'Last Updated': this.checkForNull(item.lastUpdated),
                    'Mid Status': this.checkForNull(item.midStatus),
                    'Active': this.checkForNull(item.displayActive)
                 }});
            }
            
        }
        if (!userType){
            if (customerType === 'ROLE_ICHIBAN'){
                exportObjectData = res.map(item => { return {
                    'Registration': this.checkForNull(item.registration),
                    'Trade Plate': this.checkForNull(item.tradePlate),
                    'Make': this.checkForNull(item.vehicleMake),
                    'Model': this.checkForNull(item.vehicleModel),
                    'On Date': this.checkForNull(item.vehicleCoverStartDate),
                    'Off Date': this.checkForNull(item.vehicleCoverEndDate),
                    'Location': this.checkForNull(item.location),
                    'Named Driver': this.checkForNull(item.namedDriver),
                    'Vehicle Derivative': this.checkForNull(item.vehicleDerivative),
                    'Last Updated': this.checkForNull(item.lastUpdated),
                    'Mid Status': this.checkForNull(item.midStatus),
                    'Active': this.checkForNull(item.displayActive)
                }})
            } else {
                exportObjectData = res.map(item => { return {
                    'Registration': this.checkForNull(item.registration),
                    'Trade Plate': this.checkForNull(item.tradePlate),
                    'Make': this.checkForNull(item.vehicleMake),
                    'Model': this.checkForNull(item.vehicleModel),
                    'On Date': this.checkForNull(item.vehicleCoverStartDate),
                    'Off Date': this.checkForNull(item.vehicleCoverEndDate),
                    'Location': this.checkForNull(item.location),
                    'Last Updated': this.checkForNull(item.lastUpdated),
                    "Mid Status": this.checkForNull(item.midStatus),
                    'Active': this.checkForNull(item.displayActive)
                 }});
            }
        }
        else {
            
        }
        return exportObjectData
    }

    getTokenForFrontEnd() {
        return 'Bearer '+this.getToken()
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token')
    }

    fetch(url, options) {
            // performs api calls sending the required authentication headers
            const headers = {
                'Accept': '*/*',
                'Content-Type': 'application/json'
            }

            // Setting Authorization header
            // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
            headers['Authorization'] = 'Bearer ' + this.getToken()

            return fetch(url, {
                headers,
                ...options
            })
                .then(this._checkStatus)
                .then(response => response.json())
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error.response
        }
    }
}
