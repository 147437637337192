import React, { Component } from 'react'
import FormField from '../atoms/FormField'
import * as Labels from '../../resources/labels'
import Button from '../atoms/Button'
import { FaPencilAlt } from 'react-icons/fa';
import axios from 'axios'
import AuthService from '../UserAdmin/services/AuthService'
import { FaWindowClose } from 'react-icons/fa';
const Auth = new AuthService();
const api = Auth.api

class PolicyEdit extends Component {
    constructor(props){
        super(props)
        this.state = {
            policyDetails: this.props.rejectedPolicyDetails
        }
    }

    handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        this.setState({[name]: value}, () => {})
        this.setState({changes: true})
    }

    updateState = (name,value) => {
        this.setState({name: value})
    }

    editField = (field) => {
        this.setState({field: field})
    }

    submitPolicyEdit = () => {
        axios.put(`${api}/policy/`+this.props.rejectedPolicyDetails.policyId, {
            ppcc: parseInt(this.state.newPppc)
        },{
            headers: {
                Authorization: Auth.getTokenForFrontEnd()
            }
        })
        .then(resp => {
            this.setState({request: true})
        })
        .catch(err => {
            this.setState({error: true, errorMessage: "There has been an error while submitting requst."})
        })
    }


    render(){
        return (
            <>
            <div className="panel" id="policyEdit">
            <span id="close_amend" className="close-icon" onClick={() => {this.props.hideEdit()}}><FaWindowClose />CLOSE</span>
            <h1>Policy Edit</h1>
                <form>
                    <>
                        <div className="FormField">
                            <FormField
                                type="text"
                                id="policyholder"
                                className="policyholder-field"
                                value={this.props.rejectedPolicyDetails.policyHolder}
                                onChange={this.handleChange}
                                label={Labels.POLICY_HOLDER_NAME}
                                readOnly={true}
                            />  
                        </div>
                        <div className="FormField" id="ppccChange">
                            
                            {this.state.field === undefined ? <span className="editIcon" onClick={() => {this.editField('newPppc')}}><FaPencilAlt/></span>:null}
                            {this.state.field !== 'newPppc' ?
                                <FormField
                                    type="text"
                                    id="ppcc"
                                    className="ppcc-field"
                                    value={this.props.rejectedPolicyDetails.partyPolicyControlCount}
                                    label={Labels.PPCC}
                                    readOnly={true}
                                /> 
                            : <FormField
                                    type="text"
                                    maxLength="2"
                                    id="newPppc"
                                    className="ppcc-field"
                                    value={this.state.newPppc}
                                    onChange={this.handleChange}
                                    label='New Party Policy Control Count'
                                />
                            }

                        </div>
                        <div className="FormField">
                            <FormField
                                type="text"
                                id="coverStartDate"
                                className="coverStartDate-field"
                                value={this.props.rejectedPolicyDetails.coverStartDate}
                                onChange={this.handleChange}
                                label={Labels.COVER_START_DATE}
                                readOnly={true}
                            />  
                        </div>
                        <div className="FormField">
                            <FormField
                                type="text"
                                id="coverEndDate"
                                className="coverEndDate-field"
                                value={this.props.rejectedPolicyDetails.coverEndDate}
                                onChange={this.handleChange}
                                label={Labels.EXPIRY_DATE}
                                readOnly={true}
                            />  
                        </div>
                    </>
                </form>
                
            </div>
            {this.state.newPppc !== this.state.ppcc && this.state.changes ?
            <>
            <div className="panel" id="edit-changes">
                <h1>Summary of Changes</h1>
                <div className="changeField">
                    <table>
                        <tr>
                            <td>Current PPCC</td>
                            <td></td>
                            <td>New PPCC</td>
                        </tr>
                        <tr>
                        <td>{this.props.rejectedPolicyDetails.partyPolicyControlCount}</td>
                        <td>~></td>
                        <td>{this.state.newPppc}</td>
                        </tr>
                    </table>
                </div>
                {!this.state.request ?
                <Button className="button" id="submitPolicyEdit" onClick={this.submitPolicyEdit}>Submit Changes</Button>
                :
                <div className="componentDisplayMessage success">
                <p>Change has been successful</p>
                <p onClick={() => {this.props.hideEdit()}}>Close Window</p>
                </div>
                }
                
                {this.state.error ?
                    <div className="componentDisplayMessage error">
                        <p>{this.state.errorMessage}</p>
                    </div>                    
                :null}
            </div>
            </>
            :null}

                
            </>
        )
    }
    
}

export default PolicyEdit