import React, {Component} from 'react'
import { ExportReactCSV } from './ExportReactCSV'
import * as Key from '../resources/translations'
import { FaWindowClose } from 'react-icons/fa';

class ExportVehicle extends Component {
    constructor(props){
        super(props)
        this.state = {
            fileName: '_vhcl_file_'+new Date().toISOString().split('T')[0],
            rejectedFileName: 'Rejected_vhcl_file_'+new Date().toISOString().split('T')[0],
            adminFileName: '_vhcl_file'+new Date().toISOString().split('T')[0]
        }
    }
    render(){
        return (
            <>
            {this.props.exportData ?
                <>
                {this.props.policyChosen || !this.props.userType ?
                    <span id="close_amend" className="close-icon" onClick={this.props.closeExport}><FaWindowClose />CLOSE</span>
                :<span id="close_amend" className="close-icon" onClick={this.props.closeExportNotChosen}><FaWindowClose />CLOSE</span>
                }
                {!this.props.userType ?
                    <p id="exportText">{Key.VEH_EXPORT_TEXT}</p>
                :<p id="exportText">{Key.VEH_EXPORT_REJECTED_TEXT}</p>}
                    
                    {this.props.userType ? 
                        <>
                            {this.props.policyChosen ?
                            <>
                                <ExportReactCSV csvData={this.props.exportData} fileName={this.state.adminFileName} policyNumber={this.props.adminPolicyNumber} /> 
                            </>
                            :<ExportReactCSV csvData={this.props.exportData} fileName={this.state.rejectedFileName}/> }
                    </>                   
                    :
                    <>
                    {this.props.chosenPolicy ?
                    <ExportReactCSV csvData={this.props.exportData} fileName={this.state.fileName} policyNumber={this.props.chosenPolicy}/>
                    :<ExportReactCSV csvData={this.props.exportData} fileName={this.state.fileName} policyNumber={this.props.policyNumber}/>}
                    </>
                    }
                </>
                :<p>{Key.VEH_NO_EXPORT}</p>
            }
            </>
        )
    }
}

export default ExportVehicle