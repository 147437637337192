import React, { Component } from 'react'
import FormField from './FormField'

class FieldSet extends Component {
    constructor(props){
        super(props)
        this.state = {
            fields: this.props.data
        }
    }

    handleChange = (event) => {
        this.props.onChange(event)
       
        
    }


    render(){
        return (
            <>
                {this.state.fields ?
                    <>
                        {this.state.fields.map((field, index) => 
                           <FormField
                            type={field.fieldType}
                            id={field.fieldName}
                            className={field.fieldName+ '-field'}
                            label={field.label}
                            hint={field.hint}
                            maxLength={field.maxLength}
                            pattern={field.pattern}
                            icon={field.icon}
                            validIcon={field.validIcon}
                            validReg={this.props.validReg}
                            iconClass={this.props.iconClass}
                            defaultSelect={field.defaultSelect}
                            option1={field.option1}
                            option2={field.option2}
                            onChange={this.handleChange}
                            readOnly={field.readOnly}
                       />  
                        )}
                    </>
                :null}

            </>
        )
    }
}

export default FieldSet