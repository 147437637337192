// ERROR CONDITIONS
export const VEH_DUPLICATE = "duplicate"
export const VEH_START_DATE_IN_PAST = "start date is in the past"
export const VEH_START_DATE_AFTER_END = "Unable to create vehicle as cover start date is after policy cover end date"
export const VEH_END_DATE_BEFORE_START = "Unable to create vehicle as cover start date is after cover end date"
export const VEH_START_DATE_GREATER_THAN_END = "Can't update as cover start date is greater than cover end date"
export const VEH_FILE_CONTENT_ERROR = "content"
export const VEH_FILE_FORMAT_ERROR = "format"
export const VEH_NO_UPDATE = "Nothing"
export const FILL_IN_FIELDS = "Please fill in required fileds"
export const DEFAULT_ERROR = "There has been an error, please try again or contact support."

// MID CONDITIONS
export const MID_REJECTED = "Investigate"
export const MID_NOT_SENT = "Pending"
export const MID_SENT = "Sent to MID"
export const MID_ACCEPTED = "On MID"
export const MID_DEFUNCT = ""

// ERROR CODES
export const NOT_FOUND = 404

// PORTAL SHUT DOWN
export const OPEN_TIME = 7
export const CLOSING_TIME = 20
export const PORTAL_SHUTDOWN = ` <p>Please note the Portal is no longer accesible during the current time.</p>
<p>The application will be available between 07:00 and 20:00 - Monday to Saturday</p>`