import React from 'react'
import ExportPolicyLog from './ExportPolicyLog'

const PolicyLogTable = props => {
    return (
        <>
        {props.logData ?
        <>
        <div id="export">
            <ExportPolicyLog logData={props.logData} />
        </div>
        <table id="logSearchResults">
            <thead>
                <tr>
                    {props.logDataNumber > 1 ?
                    <td>Policy Number</td>
                    :null}
                    <td>Policy Start Date</td>
                    <td>Policy End Date</td>
                    <td>Active Vehicles</td>
                    <td>Number of times logged in</td>
                    <td>Last Activity</td>
                </tr>
            </thead>    
            <tbody>
                {props.logData.map((res, index) => 
                    <tr className="" key={index}>
                        {props.logDataNumber > 1 ?
                            <td>{res.policyNumber}</td>
                        :null}
                        <td>{res.coverStart}</td>
                        <td>{res.coverEnd}</td>
                        <td>{res.active}</td>
                        <td>{res.activityCount}</td>
                        <td>{res.latestLogin}</td>
                    </tr>
                )}
            </tbody>                   
        </table>
        </>
        :null}


        </>
    )
}

export default PolicyLogTable