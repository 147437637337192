import React from 'react'
import { Component } from 'react';
import axios from 'axios'
import FormField from '../atoms/FormField'
import * as Key from './resources/translations';
import AuthService from '../UserAdmin/services/AuthService'
import PolicyService from '../Policy/services/PolicyService'
import Button from '../atoms/Button'
import ComponentDisplayMessage from '../ComponentDisplayMessage'
import UpdateCustomerAdmin from './UpdateCustomerAdmin';
import { FaTimesCircle } from 'react-icons/fa';
import ResetAdminPIN from './ResetAdminPIN';
import Para from '../atoms/Para';
import Header from '../atoms/Header';
const Auth = new AuthService();
const Policy = new PolicyService();
const api = Auth.api
let array = []

class CreateSuperCustomer extends Component {
    constructor(props){
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            policy: '',
            policies: [],
            method: '',
            methodOptions: ['Create', 'Update', 'ResetPIN'],
        }
        
    }

    handleChange(event){
        event.preventDefault();
        const { name, value } = event.target;

        let errors = this.state.errors;
        this.setState({errors, [name]: value}, () => {})
    }

    onMethodChange = (e) => {
        this.setState({type: e.target.value}, () => {
            console.log(this.state.type)
            if (this.state.type === 'Create'){
                this.setState({method: this.state.type, policyNumber: '', policies: []})
            }
            if (this.state.type === 'Update'){
                this.setState({method: this.state.type, policyNumber: '', policies: []})
            }
            if (this.state.type === 'ResetPIN'){
                this.setState({method: this.state.type, policyNumber: '', policies: []})
            }
        })
    }

    addPolToArray = () => {
        if (this.state.policy.length === 7){
            Policy.policySearch(this.state.policy, '')
            .then(res => {
                if(array.indexOf(this.state.policy) !== -1){
                    alert("Policy has already been added")
                } else{
                    array.push(this.state.policy.toUpperCase())
                    this.setState({policies: array, policy: '', notAPolicy: false})
                }
            })
            .catch(error => {
                this.setState({notAPolicy: true})
            })
        } else {
            this.setState({notAPolicy: true})
        }
    }



    getAPIN = () => {
        Auth.generatePIN()
         .then(res => {
             this.setState({pinNumber: res})
             
         })
         .catch(err => {
             this.setState({showErrors: true})
             window.scrollTo(0, 0)
         })
     }

    attachPinToPolicy = (e) => {
        if (this.state.policyNumber.length === 7){
            e.preventDefault();
            axios.post(`${api}/adminCustomer`,{
                loginId: this.state.policyNumber.toUpperCase(),
                name: this.state.policyHolderName,
                policies: this.state.policies,
                pin: this.state.pinNumber
            },
            {
                headers: {
                    Authorization: Auth.getTokenForFrontEnd()
                }
            }
            )
            .then(resp => {
                this.setState({success: true, errors: false, policyNumber: '', policyHolderName: '', pin: '', alreadyRegistered: false, doesNotExist: false, policies: []})
            })
            .catch(err => {
                this.setState({success: false, errors: true, errorCode: err.response.status, errorMessage: err.response.data})
                if (this.state.errorCode === 409 && this.state.errorMessage.includes('already registered')){
                    this.setState({alreadyRegistered: true})
                }
                if (this.state.errorCode === 404 && this.state.errorMessage.includes('does not exist')){
                    this.setState({doesNotExist: true, errorMessage: this.state.errorMessage})
                }
            })
        } else {
            window.alert('Policy Number must be 7 characters long')
        }
        
    }

    removePolicy = (policyNumber) => {
        const index = this.state.policies.indexOf(policyNumber);
        if (index > -1) {
          this.state.policies.splice(index, 1);
        }
        let pols = this.state.policies;
        this.setState({policies: pols})
    }

    render(){
        return (
            <>
                 <div className="section" id="create-super-customer">
                    <select onChange={this.onMethodChange} className="optionsSwitcher"> 
                        <option value=""> -- Choose Method -- </option>
                        {this.state.methodOptions.map((item) => <option value={item}>{item}</option>)}
                    </select>
                    
                {this.state.method === 'Create' ?
                <>
                    <Header value="Create Admin Customer" />
                    <FormField
                        type="text"
                        id="policyNumber"
                        label={Key.POLICY_NUMBER}
                        onChange={this.handleChange}
                        value={this.state.policyNumber}
                        hint={"Policy Number must start with a 'S'"}
                        maxLength="7"
                    />
                    <FormField
                        type="text"
                        id="policyHolderName"
                        label={Key.POLICY_HOLDER_NAME}
                        onChange={this.handleChange}
                        value={this.state.policyHolderName}
                    />
                    <FormField
                        type="text"
                        id="policy"
                        label="Add a policy:"
                        onChange={this.handleChange}
                        value={this.state.policy}
                        hint={Key.POLICY_NUMBER_HINT}
                    />
                    <button className="button" id="addPoltoArray" name="addPoltoArray" onClick={() => this.addPolToArray()}>Add</button>
                    {this.state.notAPolicy ?
                        <p>This is not a valid policy</p>
                    :null}
                    {this.state.policies.length >= 1 ?
                        <>
                        <Para value="Policies added:" bold={true} />
                        <table className="listOfPolTable">
                        {this.state.policies.map((field, index) => 
                        <>
                        <tr>
                            <td>{field}</td>
                            <td onClick={() => this.removePolicy(field)}><span><FaTimesCircle/></span></td>
                        </tr>
                        </>
                        )}
                        </table> 
                        </>
                    :null}
            
                                    <div className="FormField">
                                        {!this.state.pinNumber ?
                                            <Button className="button" onClick={this.getAPIN}>{Key.SHOW_PIN}</Button>
                                        :null}
                                    </div>
                                    {this.state.pinNumber ?
                                        <FormField
                                            type="text"
                                            id="pinNumber"
                                            value={this.state.pinNumber}
                                            label={Key.PIN_LABEL}
                                            onChange={this.handleChange}
                                            readOnly={true}
                                        />
                                    :null}
            
                                    
                                    {this.state.policyNumber && this.state.pinNumber ?
                                       <Button className="button actionBtn" onClick={this.attachPinToPolicy}>{Key.ATTACH_PIN}</Button>
                                   :null}

                                    <ComponentDisplayMessage
                                        error={this.state.errorMessage}
                                        alreadyRegistered={this.state.alreadyRegistered}
                                        doesNotExist={this.state.doesNotExist}
                                        defaultErrorMessage={Key.DEFAULT_ERROR}
                                        errorMessage={this.state.errorMessage}
                                        success={this.state.success}
                                        successMessage="Policy Created"
                                    />
                    </>
                :null}
                {this.state.method === 'Update' ?
                    <>
                    <UpdateCustomerAdmin />
                    </>
                :null}

                {this.state.method === 'ResetPIN' ?
                    <>
                    <ResetAdminPIN />
                    </>
                :null}
                </div>
            </>
        )
    }
}

export default CreateSuperCustomer