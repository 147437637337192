import React, { Component } from 'react'
import CSVReader from 'react-csv-reader'
import AuthService from '../UserAdmin/services/AuthService'
const Auth = new AuthService();
const api = Auth.api

class PolicyImport extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    toggleMessages = () => {
        this.props.toggleMessages()
    }

    handleImportDataForAdmin = (data) => {
        this.props.closeLog(true)
        const headers = data.shift().join(',')
        const result = data.map(function (row) {
            return row.map(function(field){ return '"' + field + '"';})
         })      
        this.setState({importData: headers+'\n'+result.join('\n'), importFileIssue: false})
        this.testSendAdmin(this.state.importData)
    }
    testSendAdmin = (data) => {
        var xhr = new XMLHttpRequest();
        var self = this;
        xhr.open("POST", `${api}/import/policies`);
        xhr.setRequestHeader("authorization", Auth.getTokenForFrontEnd());
        xhr.send(data);
        document.getElementById("react-csv-reader-input").value = "";
        xhr.onload = function() {
            if (xhr.status === 409) {
                self.toggleMessages()
                var errorResponse = JSON.parse(this.response);
                self.setState({success: false, importErrorCode: xhr.status, importErrors: errorResponse, importErrorLength: xhr.response.length, errors: true})
                window.scrollTo(0,document.body.scrollHeight);
                self.setState({showImportUi: false})
            } 

            if (xhr.status === 200){
                self.toggleMessages()
              var myArr = JSON.parse(this.response);
              let today = new Date().toISOString().split('T')[0];
              self.setState({success: true, errors: false, importResponses: myArr, showErrors: false, importErrors: false, todaysDate: today,
                importDefaultError: false,importFileContentError: false, importFileFormatError: false, importFileDuplicateError: false})
            }
            if (xhr.status === 500){
                self.toggleMessages()
                self.setState({success: false, errors: true, importErrorCode: xhr.status})
            }
        };
    }

    render(){
        return (
            <>
            {this.props.currentPage === 'PolicyAdmin' ?
            <>
            <div className="panel">
            <form>
                    <h1 className="header">Policy Import</h1>
                    <div className="FormField">
                        <br/>
                            <CSVReader onFileLoaded={(data) => this.handleImportDataForAdmin(data)} />
                        <br/>
                    </div>
                </form>
                {this.state.errors && this.props.displayMessages && this.state.importErrorCode !== 500 ?
                    <ul id="errorList">
                    {this.state.importErrors.map((importError, index) =>
                        <li key={index}>{importError}</li>
                    )}
                    </ul>
                :null}
                {this.state.errors && this.props.displayMessages && this.state.importErrorCode === 500 ?
                    <p className="errorText">System Error, contact support team</p>
                :null}
                {this.state.importResponses && !this.state.errors ?
                <div className="policyImportResponse">
                    <ul id="responseList">
                    {this.state.importResponses.map((response, index) =>
                        <li key={index}>{response}</li>
                    )}
                    </ul>    
                </div>
                                   
                :null}
            </div>

            </>
            :null}
            </>
        )
    }
}

export default PolicyImport