import React, { Component } from 'react'
import { FaPlus } from 'react-icons/fa'
import { FaMinus } from 'react-icons/fa'

class ContactUs extends Component {
    constructor(props){
        super(props)
        this.state = {
        
        }
    }

    toggleContact = () => {
        this.setState({contactContent: !this.state.contactContent})
    }
    render(){
        return (
            <div id="contact-us" className="toggle-container">
                <div className="toggle-header" onClick={this.toggleContact}>
                    <h2>{this.props.title}</h2>
                    {this.state.contactContent ?
                    <span className="toggle-icon"><FaMinus/></span>
                    :<span className="toggle-icon"><FaPlus /></span>}
                </div>
                {this.state.contactContent ?
                <>
                    <p><b>{this.props.emailMethod}</b> {this.props.email} <b>{this.props.contactMethod}</b> {this.props.telephone}</p>
                    <p>{this.props.linesOpen}</p>
                </>
                :null}
            </div>
        )
    }
}

export default ContactUs