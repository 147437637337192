import React, { Component } from "react";
import axios from "axios";
import AuthService from "./services/AuthService";
import * as Key from "./resources/translations";
import Button from "../atoms/Button";
import ComponentDisplayMessage from "../ComponentDisplayMessage";
import FormField from "../atoms/FormField";
const Auth = new AuthService();
const api = Auth.api;

class AttachPIN extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {};
  }

  onCustomerTypeChange = (e) => {
    this.setState({ type: e.target.value }, () => {
      if (this.state.type === Key.READ_ONLY) {
        this.setState(
          { readOnlyCustomer: true, altProfile1: false, altProfile2: false },
          () => {}
        );
      } else {
        this.setState({ readOnlyCustomer: false }, () => {});
      }
      if (this.state.type === Key.ICHIBAN) {
        this.setState({ altProfile1: true, altProfile2: false });
      }
      if (this.state.type === Key.TRAILER) {
        this.setState({ altProfile1: false, altProfile2: true });
      }
    });
  };

  getAPIN = () => {
    Auth.generatePIN()
      .then((res) => {
        this.setState({ pinNumber: res });
      })
      .catch((err) => {
        this.setState({ showErrors: true });
        window.scrollTo(0, 0);
      });
  };

  attachPinToPolicy = (e) => {
    e.preventDefault();
    axios
      .post(
        `${api}/customer/`,
        {
          policyNumber: this.state.policyNumber,
          pinNumber: this.state.pinNumber,
          readOnly: this.state.readOnlyCustomer,
          altProfile1: this.state.altProfile1,
          altProfile2: this.state.altProfile2,
        },
        {
          headers: {
            Authorization: Auth.getTokenForFrontEnd(),
          },
        }
      )
      .then((resp) => {
        this.setState({
          success: true,
          errors: false,
          policyNumber: "",
          alreadyRegistered: false,
          doesNotExist: false,
        });
      })
      .catch((err) => {
        this.setState({
          success: false,
          errors: true,
          errorCode: err.response.status,
          errorMessage: err.response.data,
        });
        if (
          this.state.errorCode === 409 &&
          this.state.errorMessage.includes("already registered")
        ) {
          this.setState({ alreadyRegistered: true });
        }
        if (
          this.state.errorCode === 409 &&
          this.state.errorMessage.includes("does not exist")
        ) {
          this.setState({ doesNotExist: true });
        }
      });
  };

  render() {
    return (
      <>
        <div className="section" id="attachPin">
          <h1 className="header">{Key.PIN_TITLE}</h1>
          <FormField
            type="text"
            id="policyNumber"
            className="policyNumber-field"
            value={this.state.policyNumber}
            onChange={this.handleChange}
            label={Key.POLICY_NUMBER}
          />
          <div className="FormField">
            {!this.state.pinNumber ? (
              <Button className="button" onClick={this.getAPIN}>
                {Key.SHOW_PIN}
              </Button>
            ) : null}
          </div>

          {this.state.pinNumber ? (
            <FormField
              type="text"
              id="pinNumber"
              value={this.state.pinNumber}
              label={Key.PIN_LABEL}
              onChange={this.handleChange}
              readOnly={true}
            />
          ) : null}
          <FormField
            type="select"
            id="customerType"
            value={this.state.type}
            label={Key.CUSTOMER_TYPE}
            defaultSelect={Key.CHOOSE_OPTION}
            option1={Key.NORMAL}
            option2={Key.READ_ONLY}
            option3={Key.ICHIBAN}
            option4={Key.TRAILER}
            onChange={this.onCustomerTypeChange}
          />

          {this.state.policyNumber && this.state.pinNumber ? (
            <Button
              className="button actionBtn"
              onClick={this.attachPinToPolicy}
            >
              {Key.ATTACH_PIN}
            </Button>
          ) : null}

          <ComponentDisplayMessage
            error={this.state.errorMessage}
            alreadyRegistered={this.state.alreadyRegistered}
            doesNotExist={this.state.doesNotExist}
            defaultErrorMessage={Key.DEFAULT_ERROR}
            success={this.state.success}
            successMessage={Key.PIN_ATTACHED}
          />
        </div>
      </>
    );
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
}

export default AttachPIN;
