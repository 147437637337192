import React, { Component } from 'react'
import FormField from '../../atoms/FormField'
import Button from '../../atoms/Button'
import ComponentDisplayMessage from '../../ComponentDisplayMessage'
import UtilService from '../../../Util'
const Util = new UtilService();

class CreatePassword extends Component {
    constructor(props){
        super(props)
        this.state = {
            code1: '',
            code2: '',
            code3: '',
            code4: '',
            code5: '',
            code6: '',
            code7: '',
            code8: '',
            password: '',
            confirmPassword: '',
            validPasswords: ''
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        this.setState({doNotMatch: false, requiredFields: false, error: false})
        if (name === 'password'){
            let passwordTest = Util.passwordValidatior(value);
            if (passwordTest){
                this.setState({validPassword: true})
            } else {
                this.setState({validPassword: false, confirmPassword: '', validPasswords: false})
            }
        }
        if (name === 'confirmPassword'){
            let passwordTest = Util.passwordValidatior(value);
            if (passwordTest){
                this.setState({confirmPasswordValid: true})
            }
            if (value === this.state.password){
                this.setState({validPasswords: true})
            }
        }
        let errors = this.state.errors;
        this.setState({errors, [name]: value}, () => {})
    }

    componentDidMount(){
        var container = document.getElementsByClassName("codeContainer")[0];
        container.onkeyup = function(e) {
            var target = e.srcElement;
            var maxLength = parseInt(target.attributes["maxlength"].value, 10);
            var myLength = target.value.length;
            if (myLength >= maxLength) {
                var next = target;
                // eslint-disable-next-line
                while (next = next.nextElementSibling) {
                    if (next == null)
                        break;
                    if (next.tagName.toLowerCase() === "input") {
                        next.focus();
                        break;
                    }
                }
            }
        }
    }

    validatePassword = () => {
        if (this.state.password && this.state.confirmPassword){
            if (this.state.confirmPassword === this.state.password){
                this.setState({validPasswords: true})
                return true
            } else {
                this.setState({doNotMatch: true, requiredFields: false})
            }
        } else {
            this.setState({requiredFields: true, doNotMatch: false})
        }
    }

    validateCode = () => {
        if (Util.linkCode(this.state.code1,this.state.code2,this.state.code3,
            this.state.code4,this.state.code5,this.state.code6,this.state.code7,this.state.code8).length === 8){
                return true
            } else {
                window.alert('Code is invalid')
            }
    }

    createPassword = () => {
        if (this.validatePassword() && this.validateCode()){
            this.props.action(this.state.password, Util.linkCode(this.state.code1,this.state.code2,this.state.code3,
                this.state.code4,this.state.code5,this.state.code6,this.state.code7,this.state.code8))
        } else {
            this.setState({error: true})
        }
    }

    render(){
        let buttonClass = 'button';
        return (
            <>
                <div className="create-password-code-form">
                    <h1 className="header">Create new password</h1>
                        <div className="codeContainer">
                            <label htmlFor="codes" id="codeLabel">Request Code:</label>
                            <input type="text" maxLength="1" id="code1" name="code1" onChange={this.handleChange} value={this.state.code1}/>
                            <input type="text" maxLength="1" id="code2" name="code2" onChange={this.handleChange} value={this.state.code2}/>
                            <input type="text" maxLength="1" id="code3" name="code3" onChange={this.handleChange} value={this.state.code3}/>
                            <input type="text" maxLength="1" id="code4" name="code4" onChange={this.handleChange} value={this.state.code4}/>
                            <input type="text" maxLength="1" id="code5" name="code5" onChange={this.handleChange} value={this.state.code5}/>
                            <input type="text" maxLength="1" id="code6" name="code6" onChange={this.handleChange} value={this.state.code6}/>
                            <input type="text" maxLength="1" id="code7" name="code7" onChange={this.handleChange} value={this.state.code7}/>
                            <input type="text" maxLength="1" id="code8" name="code8" onChange={this.handleChange} value={this.state.code8}/>
                        </div>                        
                        <FormField
                            type="password"
                            id="password"
                            label="Password"
                            hint="Password must be at least 8 characters and contain 1 uppercase letter and 1 special character"
                            value={this.state.password}
                            onChange={this.handleChange}
                        />
                        {this.state.validPassword ?
                        <>
                            <FormField
                                type="password"
                                id="confirmPassword"
                                label="Confirm Password"
                                value={this.state.confirmPassword}
                                onChange={this.handleChange}
                            />
                        </>
                        :null}
                        
                        {this.state.validPasswords ?
                        <>
                            <Button className={buttonClass} id="createNewPassword" onClick={this.createPassword}>Create new password</Button>
                        </>
                        :null}
                </div>

                <ComponentDisplayMessage
                    error={this.state.error}
                    requiredFields={this.state.requiredFields}
                    doNotMatch={this.state.doNotMatch}
                    incorrectCode={this.props.incorrectCode}
                    doNotMatchMessage="Passwords do not match"
                    requiredFieldsMessage="Please fill required fields"
                />

                {this.props.incorrectCode ?
                <>
                <ComponentDisplayMessage
                    error={true}
                    doNotMatch={this.state.doNotMatch}
                    incorrectCode={this.props.incorrectCode}
                />
                </>
                :null}
            </>
        )
    }
}

export default CreatePassword